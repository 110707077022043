import Page from '@/common/page/Index';
import PageContentTabs, { ContentTab } from '@/common/page/ContentTabs';
import PermissionModalButton from '@/common/button/PermissionModal';
import PaymentProviderIntegrationOptions from '../payment-provider/IntegrationOptions';
import { useMemo } from 'react';
import EmailProviderPage from '@/marketing-campaigns/EmailProviderPage';
import UserProfileDetailsPage from '@/users/ProfileDetailsPage';
import CreateOrDisplayApiKey from '@/api-key/CreateOrDisplay';
import ApiKeysGrid from '../api-key/Grid';
import OrganizationProfilePage from './OrganizationProfilePage';

const CreateApiKeyAction = () => {
  return (
    <PermissionModalButton
      modalPaperSxProps={{ width: '600px' }}
      modalTitle="Generate API Key"
      buttonLabel="Generate API Key"
      variant="create"
      requiredPermissions={['api_keys:create']}
    >
      {({ closeModal, open }) => (
        <CreateOrDisplayApiKey open={open} onSuccess={closeModal} onCancel={closeModal} />
      )}
    </PermissionModalButton>
  );
};

const SettingsDetailsPage = () => {
  const tabs = useMemo(() => {
    const tabs: Array<ContentTab> = [
      {
        label: 'Profile',
        value: 'profile',
        content: <UserProfileDetailsPage />,
      },
      {
        label: 'Organization',
        value: 'organization',
        content: <OrganizationProfilePage />,
        requiredPermissions: ['organizations:update'],
      },
      {
        label: 'API Keys',
        value: 'api-keys',
        gridContent: true,
        content: <ApiKeysGrid />,
        action: <CreateApiKeyAction />,
      },
      {
        label: 'Email Providers',
        value: 'email-providers',
        content: <EmailProviderPage />,
        requiredPermissions: ['organization_email_templates:read'],
      },
      {
        label: 'Payments',
        value: 'payments',
        content: <PaymentProviderIntegrationOptions />,
        requiredPermissions: ['payment_integrations:read'],
      },
    ];
    return tabs;
  }, []);

  return (
    <Page testIdPrefix="settings" helmet="Settings" title="Settings">
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default SettingsDetailsPage;
