import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { EmailClaimsPaginatedResultResultsInner } from '@/api-client';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { ROUTE_NAME } from '@/utils/constants';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { proxy, useSnapshot } from 'valtio';
import PrettyDate from '@/common/data/PrettyDate';
import CollectionDetailsOverview, {
  COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE,
} from '@/collections/DetailsOverview';
import ItemDetailsOverview, { ITEM_DETAILS_OVERVIEW_POPOVER_SIZE } from '@/items/DetailsOverview';
import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import { useEmailClaims } from '@/utils/hooks/emailClaim';
import PopoverButton from '@/common/button/Popover';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { BaseRow } from '@/common/data/grid/constants';
import MarketingCampaignDetailsOverview, {
  CAMPAIGN_OVERVIEW_POPOVER_SIZE,
} from '@/marketing-campaigns/DetailsOverview';
import useFeatureFlags from '@/utils/hooks/useFeatureFlags';
import { getColOrItemHierarchyRoutePath } from '@/utils/route';

export enum EmailClaimGridColumnField {
  ID = 'id',
  CREATED_ON = 'createdOn',
  CLAIMED_ON = 'claimedOn',
  EXPIRES_ON = 'expiresOn',
  LAST_PIN_CODE_EMAIL_SENT_AT = 'lastPinCodeEmailSentAt',
  ACTIVE = 'active',
  CLAIMED = 'claimed',
  QUANTITY = 'quantity',
  EMAIL = 'email',
  COLLECTION_ID = 'collectionId',
  TOKEN_ID = 'tokenId',
  ITEM_ID = 'itemId',
  TX_HASH = 'txHash',
  TX_PAYER = 'txPayer',
  VOUCHER_ID = 'voucherId',
  CAMPAIGN_ID = 'campaignId',
}

// Row specific to this grid.
type EmailClaimGridRow = { [key in EmailClaimGridColumnField]: any } & BaseRow;

// Columns specific to this grid.
const columns: Array<GridColDef<EmailClaimGridRow>> = [
  {
    field: EmailClaimGridColumnField.ID,
    headerName: 'ID',
    width: 100,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) => (
      <PlatformEntityIdentifier tooltipContext="this email claim's ID" entityId={params.row.id} />
    ),
  },
  {
    field: EmailClaimGridColumnField.CREATED_ON,
    headerName: 'Created On',
    width: 160,
    sortable: true,
    filterable: false,
    renderCell: (params: { row: EmailClaimGridRow }) => {
      return params.row.createdOn ? <PrettyDate date={params.row.createdOn} /> : <></>;
    },
  },
  {
    field: EmailClaimGridColumnField.EXPIRES_ON,
    headerName: 'Expires On',
    width: 160,
    sortable: true,
    filterable: false,
    renderCell: (params: { row: EmailClaimGridRow }) => {
      return params.row.expiresOn ? <PrettyDate date={params.row.expiresOn} /> : <></>;
    },
  },
  {
    field: EmailClaimGridColumnField.LAST_PIN_CODE_EMAIL_SENT_AT,
    headerName: 'Pin Code Last Sent',
    width: 160,
    sortable: false,
    filterable: false,
    renderCell: (params: { row: EmailClaimGridRow }) => {
      return params.row.lastPinCodeEmailSentAt ? (
        <PrettyDate date={params.row.lastPinCodeEmailSentAt} />
      ) : (
        <></>
      );
    },
  },
  {
    field: EmailClaimGridColumnField.CLAIMED_ON,
    headerName: 'Claimed On',
    width: 160,
    sortable: true,
    filterable: false,
    renderCell: (params: { row: EmailClaimGridRow }) => {
      return params.row.claimedOn ? <PrettyDate date={params.row.claimedOn} /> : <></>;
    },
  },
  {
    field: EmailClaimGridColumnField.ACTIVE,
    headerName: 'Active',
    width: 60,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) => (
      <Checkbox onChange={() => params.row.active} checked={params.row.active} />
    ),
  },
  {
    field: EmailClaimGridColumnField.CLAIMED,
    headerName: 'Claimed',
    width: 75,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) => (
      <Checkbox onChange={() => params.row.claimed} checked={params.row.claimed} />
    ),
  },
  {
    field: EmailClaimGridColumnField.QUANTITY,
    headerName: 'Quantity',
    width: 100,
    sortable: true,
    filterable: false,
  },
  {
    field: EmailClaimGridColumnField.EMAIL,
    headerName: 'Email',
    width: 125,
    sortable: false,
    filterable: true,
  },
  {
    field: EmailClaimGridColumnField.COLLECTION_ID,
    headerName: 'Collection ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) =>
      params.row.collectionId ? (
        <PlatformEntityIdentifier
          tooltipContext="this collection ID"
          entityId={params.row.collectionId}
          popoverPreviewContent={
            <CollectionDetailsOverview explicitCollectionId={params.row.collectionId} />
          }
          popoverPreviewSx={{ ...COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: EmailClaimGridColumnField.TOKEN_ID,
    headerName: 'Token ID',
    width: 100,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) =>
      params.row.tokenId && params.row.itemId ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            buttonIcon={<InfoIcon />}
            popoverContent={<ItemDetailsOverview explicitItemId={params.row.itemId} />}
            popoverContainerSx={{ ...ITEM_DETAILS_OVERVIEW_POPOVER_SIZE }}
          />
          <Box sx={{ ml: 1 }}>{params.row.tokenId}</Box>
        </Box>
      ) : (
        <></>
      ),
  },
  {
    field: EmailClaimGridColumnField.ITEM_ID,
    headerName: 'Item ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) =>
      params.row.itemId && params.row.collectionId ? (
        <PlatformEntityIdentifier
          tooltipContext="this claim's item ID"
          entityId={params.row.itemId}
          popoverPreviewContent={<ItemDetailsOverview explicitItemId={params.row.itemId} />}
          popoverPreviewSx={{ ...ITEM_DETAILS_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <>{params.row.itemId}</>
      ),
  },
  {
    field: EmailClaimGridColumnField.CAMPAIGN_ID,
    headerName: 'Campaign ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: EmailClaimGridRow }) =>
      params.row.campaignId ? (
        <PlatformEntityIdentifier
          tooltipContext="this claim's campaign ID"
          entityId={params.row.campaignId}
          popoverPreviewContent={
            <MarketingCampaignDetailsOverview explicitCampaignId={params.row.campaignId} />
          }
          popoverPreviewSx={{ ...CAMPAIGN_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: EmailClaimGridColumnField.TX_HASH,
    headerName: 'Tx Hash',
    width: 125,
    filterable: false,
    renderCell: (params: { row: EmailClaimGridRow }) =>
      params.row.txHash ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.TX_HASH}
          filterValue={params.row.txHash}
          charLengthOnEitherSide={3}
          showBlockExplorerIcon={false}
        />
      ) : (
        <></>
      ),
  },
  {
    field: EmailClaimGridColumnField.TX_PAYER,
    headerName: 'Tx Payer',
    width: 125,
    sortable: false,
    filterable: false,
  },
  {
    field: EmailClaimGridColumnField.VOUCHER_ID,
    headerName: 'Voucher ID',
    width: 150,
    filterable: false,
    renderCell: (params: { row: EmailClaimGridRow }) =>
      params.row.voucherId ? (
        <PlatformEntityIdentifier
          tooltipContext="this voucher ID"
          entityId={params.row.voucherId}
        />
      ) : (
        <></>
      ),
  },
];

export const generateDefaultColumnVisibilityModel = () => {
  return {
    [EmailClaimGridColumnField.ID]: false,
    [EmailClaimGridColumnField.VOUCHER_ID]: false,
    [EmailClaimGridColumnField.ITEM_ID]: false,
  };
};

// Initial state specific to this grid.
const getInitialGridState = (
  customTransactionColumnVisibility?: GridColumnVisibilityModel
): GridInitialStateCommunity => {
  return {
    columns: {
      columnVisibilityModel:
        customTransactionColumnVisibility ?? generateDefaultColumnVisibilityModel(),
    },
  };
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (emailClaim: EmailClaimsPaginatedResultResultsInner): EmailClaimGridRow => {
  return {
    id: emailClaim.id,
    active: emailClaim.active,
    claimed: emailClaim.claimed,
    claimedOn: emailClaim.claimed_at,
    collectionId: emailClaim.collection_id,
    createdOn: emailClaim.created_at,
    email: emailClaim.email,
    tokenId: emailClaim.token_id,
    itemId: emailClaim.item_id,
    expiresOn: emailClaim.expiration,
    quantity: emailClaim.quantity,
    lastPinCodeEmailSentAt: emailClaim.last_pin_code_email_sent_at,
    txHash: emailClaim.tx_hash,
    txPayer: emailClaim.tx_payer,
    voucherId: emailClaim.voucher_id,
    campaignId: emailClaim.campaign_id,
    original: { emailClaim },
  };
};

const pageState: PaginatedGridPage<EmailClaimGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
});

export interface EmailClaimsGridProps {
  collectionId?: string;
  itemId?: string;
  customVisibility?: GridColumnVisibilityModel;
}

const EmailClaimsGrid = ({ collectionId, itemId, customVisibility }: EmailClaimsGridProps) => {
  const [initialState] = useState<GridInitialStateCommunity>(getInitialGridState(customVisibility));

  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current render.
  const { data: emailClaimsResponse, isLoading } = useEmailClaims(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    {
      ...filterBy,
      collectionId: collectionId ? collectionId : (filterBy as { [key: string]: any }).collectionId,
      itemId: itemId ? itemId : (filterBy as { [key: string]: any }).itemId,
    }
  );

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      emailClaimsResponse?.results?.map(emailClaim => {
        return mapToRow(emailClaim);
      }) ?? [];
    pageState.nextCursor = emailClaimsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [emailClaimsResponse?.results, emailClaimsResponse?.cursor]);

  const getRowDoubleClickUrl = useCallback(
    (row: EmailClaimGridRow) =>
      getColOrItemHierarchyRoutePath({
        id: row.id,
        idPropName: 'emailClaimId',
        detailsRouteName: ROUTE_NAME.EMAIL_CLAIM_DETAILS,
        collectionId,
        collectionDetailsRouteName: ROUTE_NAME.COLLECTION_EMAIL_CLAIM_DETAILS,
        itemId,
        itemDetailsRouteName: ROUTE_NAME.ITEM_EMAIL_CLAIM_DETAILS,
      }) || '',
    [collectionId, itemId]
  );

  const featureFlags = useFeatureFlags();

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex' }}>
        <ServerPaginatedDataGrid
          columns={columns.filter(column =>
            column.field === EmailClaimGridColumnField.CAMPAIGN_ID
              ? featureFlags.marketingCampaigns
              : column
          )}
          initialState={initialState}
          // eslint-disable-next-line valtio/state-snapshot-rule
          activePageProxy={pageState}
          pageLoading={isLoading}
          totalRowCount={emailClaimsResponse?.total_results ?? 0}
          getRowDoubleClickUrl={getRowDoubleClickUrl}
        />
      </Box>
    </>
  );
};

export default EmailClaimsGrid;
