import NotFoundPage from '@/common/page/NotFound';
import { authState, setAuthPlatformData } from '@/utils/states/authState';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { hasPermissions } from '@/utils/helpers';
import { useEffect, useState } from 'react';
import { HOT_REFETCH_INTERVAL_MILLISECONDS, adminApi } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ME_QUERY_KEY, ORG_QUERY_KEY, UNVERIFIED_EMAIL_ERROR } from '@/utils/constants';
import EmailVerificationPage from './RequireEmailVerificationPage';
import AuthErrorPage from './AuthErrorPage';

// Define this locally without exporting to prevent other components from using this
// That way they have to use `useSnapshot(authState)`.
const useCurrentPlatformUser = (accessToken?: string) => {
  const {
    data: axiosResponse,
    error,
    status,
    isPending,
    isFetched,
    isError,
  } = useQuery({
    queryKey: [ORG_QUERY_KEY, ME_QUERY_KEY, accessToken as string],
    queryFn: adminApi.auth.meGet,
    enabled: Boolean(accessToken),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS * 100, // 50 minutes
  });

  return {
    ...(axiosResponse?.data || {}),
    error,
    status,
    isPending,
    isFetched,
    isError,
  };
};

export const RequireAuth = ({
  children,
  requiredPermissions,
}: {
  children: JSX.Element;
  requiredPermissions?: string[];
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [wasLoggedIn, setWasLoggedIn] = useState(false);
  const [checksComplete, setChecksComplete] = useState(false);
  const [requirePostAuthAction, setRequirePostAuthAction] = useState('');
  const { accessToken, platformUser, platformOrg, platformPermissions } = useSnapshot(authState);
  const {
    permissions: permissionsForToken,
    user: userForToken,
    organization: orgForToken,
    isPending: currentPlatformUserLoading,
    isError: isUserError,
    error: userError,
  } = useCurrentPlatformUser(accessToken);
  const missingAuthPlatformState = !platformUser || !platformOrg;

  useEffect(() => {
    if (wasLoggedIn && isUserError) {
      enqueueSnackbar(
        'Failed to fully authenticate, it might be that your session expired. Redirecting to log in page...',
        { variant: 'info' }
      );
      setTimeout(() => {
        navigate('/auth0/logout');
      }, 2500);
    } else if (userForToken && !isUserError && !wasLoggedIn) {
      setWasLoggedIn(true);
    }
  }, [userForToken, isUserError, enqueueSnackbar, wasLoggedIn, navigate]);

  useEffect(() => {
    // Missing platform auth state, so if we can we need to set it.
    if (userForToken && orgForToken && missingAuthPlatformState && !currentPlatformUserLoading) {
      setAuthPlatformData({
        user: userForToken,
        org: orgForToken,
        permissions: permissionsForToken as string[],
      });
    }
  }, [
    userForToken,
    missingAuthPlatformState,
    orgForToken,
    permissionsForToken,
    currentPlatformUserLoading,
  ]);

  useEffect(() => {
    if (accessToken) return;

    if (
      window.location.pathname &&
      !window.location.pathname.toLowerCase().includes('/auth0/login')
    ) {
      // If we don't have an access token, then redirect to the auth landing page.
      navigate('/auth0/login', { state: { from: { pathname: window.location.pathname } } });
    }
  }, [navigate, accessToken]);

  useEffect(() => {
    if (isUserError && userError) {
      // Failed to validate authentication for this user (via /me)
      // check if due to unverified email
      const err = userError as any;

      if (!err?.response) return;

      if (
        err.response.status === UNVERIFIED_EMAIL_ERROR.status &&
        err.response.data.error.code === UNVERIFIED_EMAIL_ERROR.code
      ) {
        setRequirePostAuthAction(UNVERIFIED_EMAIL_ERROR.code);
        setChecksComplete(true);
      }

      if (
        err.response.status === 401 &&
        (err.response.data.error.detail as string).includes('not active')
      ) {
        setRequirePostAuthAction(err.response.data.error.detail as string);
        setChecksComplete(true);
      }

      // redirect to auth error page
      if (checksComplete && !requirePostAuthAction) navigate(`/auth0/error`);
    }
  }, [checksComplete, isUserError, navigate, requirePostAuthAction, userError]);

  if (checksComplete && requirePostAuthAction) {
    // post actions pages
    if (requirePostAuthAction === UNVERIFIED_EMAIL_ERROR.code)
      return <EmailVerificationPage accessToken={accessToken} />;
    if (requirePostAuthAction) return <AuthErrorPage reason={requirePostAuthAction} />;
  } else if (checksComplete || missingAuthPlatformState) {
    // We don't know everything about this authenticated user yet -> show loading
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={65} />
      </Box>
    );
  } else if (
    platformUser &&
    requiredPermissions &&
    !hasPermissions(requiredPermissions, (platformPermissions as string[]) ?? [])
  ) {
    // Authenticated user is missing required permissions for this content
    return <NotFoundPage sx={{ width: '100%', height: '100%' }} />;
  } else {
    // Show content for authenticated user
    return children;
  }
};
