/* tslint:disable */
/* eslint-disable */
/**
 * Phosphor Admin API
 * @generated by <a href=\"https://phosphor.xyz\">Phosphor</a>.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddPaymentTokenInput
 */
export interface AddPaymentTokenInput {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof AddPaymentTokenInput
     */
    'address': string;
    /**
     * 
     * @type {boolean}
     * @memberof AddPaymentTokenInput
     */
    'eth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddPaymentTokenInput
     */
    'eur_stablecoin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddPaymentTokenInput
     */
    'usd_stablecoin'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiKeyCreatedOutput
 */
export interface ApiKeyCreatedOutput {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyCreatedOutput
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyCreatedOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyCreatedOutput
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyCreatedOutput
     */
    'last_used'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyCreatedOutput
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiKeyCreatedOutput
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface ApiKeyOutput
 */
export interface ApiKeyOutput {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyOutput
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyOutput
     */
    'last_used'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyOutput
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiKeyOutput
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface ApiKeyPaginatedOutput
 */
export interface ApiKeyPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiKeyPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<ApiKeyPaginatedOutputResultsInner>}
     * @memberof ApiKeyPaginatedOutput
     */
    'results': Array<ApiKeyPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof ApiKeyPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface ApiKeyPaginatedOutputResultsInner
 */
export interface ApiKeyPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyPaginatedOutputResultsInner
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyPaginatedOutputResultsInner
     */
    'last_used'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiKeyPaginatedOutputResultsInner
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface AppApiapiKeysDeleteApiKeyPath
 */
export interface AppApiapiKeysDeleteApiKeyPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiapiKeysDeleteApiKeyPath
     */
    'api_key_id': string;
}
/**
 * 
 * @export
 * @interface AppApiapiKeysGetApiKeyByIdPath
 */
export interface AppApiapiKeysGetApiKeyByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiapiKeysGetApiKeyByIdPath
     */
    'api_key_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsCallContractFunctionPath
 */
export interface AppApicollectionsCallContractFunctionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsCallContractFunctionPath
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsCallContractFunctionPath
     */
    'function_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsDeleteCollectionPath
 */
export interface AppApicollectionsDeleteCollectionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsDeleteCollectionPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsDeployContractPath
 */
export interface AppApicollectionsDeployContractPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsDeployContractPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsEditCollectionPath
 */
export interface AppApicollectionsEditCollectionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsEditCollectionPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsExecuteContractFunctionPath
 */
export interface AppApicollectionsExecuteContractFunctionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsExecuteContractFunctionPath
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsExecuteContractFunctionPath
     */
    'function_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsGetCollectionByIdPath
 */
export interface AppApicollectionsGetCollectionByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsGetCollectionByIdPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsGetCollectionDeploymentPath
 */
export interface AppApicollectionsGetCollectionDeploymentPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsGetCollectionDeploymentPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsPinCollectionPath
 */
export interface AppApicollectionsPinCollectionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsPinCollectionPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsRequestCollectionMetadataRefreshPath
 */
export interface AppApicollectionsRequestCollectionMetadataRefreshPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsRequestCollectionMetadataRefreshPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsRequestEnableCollectionContractFunctionPath
 */
export interface AppApicollectionsRequestEnableCollectionContractFunctionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsRequestEnableCollectionContractFunctionPath
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsRequestEnableCollectionContractFunctionPath
     */
    'function_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsRequestGetCollectionContractFunctionsPath
 */
export interface AppApicollectionsRequestGetCollectionContractFunctionsPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsRequestGetCollectionContractFunctionsPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApicollectionsRequestGetContractFunctionByIdPath
 */
export interface AppApicollectionsRequestGetContractFunctionByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsRequestGetContractFunctionByIdPath
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppApicollectionsRequestGetContractFunctionByIdPath
     */
    'function_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailClaimsGetEmailClaimByIdPath
 */
export interface AppApiemailClaimsGetEmailClaimByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailClaimsGetEmailClaimByIdPath
     */
    'claim_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailClaimsResendEmailClaimPath
 */
export interface AppApiemailClaimsResendEmailClaimPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailClaimsResendEmailClaimPath
     */
    'claim_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailClaimsRevokeEmailClaimPath
 */
export interface AppApiemailClaimsRevokeEmailClaimPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailClaimsRevokeEmailClaimPath
     */
    'claim_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailIntegrationsDeleteEmailIntegrationPath
 */
export interface AppApiemailIntegrationsDeleteEmailIntegrationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailIntegrationsDeleteEmailIntegrationPath
     */
    'integration_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailIntegrationsEditEmailIntegrationPath
 */
export interface AppApiemailIntegrationsEditEmailIntegrationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailIntegrationsEditEmailIntegrationPath
     */
    'integration_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailIntegrationsGetEmailIntegrationByIdPath
 */
export interface AppApiemailIntegrationsGetEmailIntegrationByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailIntegrationsGetEmailIntegrationByIdPath
     */
    'integration_id': string;
}
/**
 * 
 * @export
 * @interface AppApiemailTemplatesDeleteEmailTemplatePath
 */
export interface AppApiemailTemplatesDeleteEmailTemplatePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailTemplatesDeleteEmailTemplatePath
     */
    'template_type': AppApiemailTemplatesDeleteEmailTemplatePathTemplateTypeEnum;
}

export const AppApiemailTemplatesDeleteEmailTemplatePathTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type AppApiemailTemplatesDeleteEmailTemplatePathTemplateTypeEnum = typeof AppApiemailTemplatesDeleteEmailTemplatePathTemplateTypeEnum[keyof typeof AppApiemailTemplatesDeleteEmailTemplatePathTemplateTypeEnum];

/**
 * 
 * @export
 * @interface AppApiemailTemplatesGetEmailTemplatePath
 */
export interface AppApiemailTemplatesGetEmailTemplatePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailTemplatesGetEmailTemplatePath
     */
    'template_type': AppApiemailTemplatesGetEmailTemplatePathTemplateTypeEnum;
}

export const AppApiemailTemplatesGetEmailTemplatePathTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type AppApiemailTemplatesGetEmailTemplatePathTemplateTypeEnum = typeof AppApiemailTemplatesGetEmailTemplatePathTemplateTypeEnum[keyof typeof AppApiemailTemplatesGetEmailTemplatePathTemplateTypeEnum];

/**
 * 
 * @export
 * @interface AppApiemailTemplatesGetEmailTemplateVariablesPath
 */
export interface AppApiemailTemplatesGetEmailTemplateVariablesPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailTemplatesGetEmailTemplateVariablesPath
     */
    'template_type': AppApiemailTemplatesGetEmailTemplateVariablesPathTemplateTypeEnum;
}

export const AppApiemailTemplatesGetEmailTemplateVariablesPathTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type AppApiemailTemplatesGetEmailTemplateVariablesPathTemplateTypeEnum = typeof AppApiemailTemplatesGetEmailTemplateVariablesPathTemplateTypeEnum[keyof typeof AppApiemailTemplatesGetEmailTemplateVariablesPathTemplateTypeEnum];

/**
 * 
 * @export
 * @interface AppApiemailTemplatesPatchEmailTemplatePath
 */
export interface AppApiemailTemplatesPatchEmailTemplatePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailTemplatesPatchEmailTemplatePath
     */
    'template_type': AppApiemailTemplatesPatchEmailTemplatePathTemplateTypeEnum;
}

export const AppApiemailTemplatesPatchEmailTemplatePathTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type AppApiemailTemplatesPatchEmailTemplatePathTemplateTypeEnum = typeof AppApiemailTemplatesPatchEmailTemplatePathTemplateTypeEnum[keyof typeof AppApiemailTemplatesPatchEmailTemplatePathTemplateTypeEnum];

/**
 * 
 * @export
 * @interface AppApiemailTemplatesPostEmailTemplatePath
 */
export interface AppApiemailTemplatesPostEmailTemplatePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailTemplatesPostEmailTemplatePath
     */
    'template_type': AppApiemailTemplatesPostEmailTemplatePathTemplateTypeEnum;
}

export const AppApiemailTemplatesPostEmailTemplatePathTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type AppApiemailTemplatesPostEmailTemplatePathTemplateTypeEnum = typeof AppApiemailTemplatesPostEmailTemplatePathTemplateTypeEnum[keyof typeof AppApiemailTemplatesPostEmailTemplatePathTemplateTypeEnum];

/**
 * 
 * @export
 * @interface AppApiemailTemplatesPostEmailTemplateSendTestPath
 */
export interface AppApiemailTemplatesPostEmailTemplateSendTestPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiemailTemplatesPostEmailTemplateSendTestPath
     */
    'template_type': AppApiemailTemplatesPostEmailTemplateSendTestPathTemplateTypeEnum;
}

export const AppApiemailTemplatesPostEmailTemplateSendTestPathTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type AppApiemailTemplatesPostEmailTemplateSendTestPathTemplateTypeEnum = typeof AppApiemailTemplatesPostEmailTemplateSendTestPathTemplateTypeEnum[keyof typeof AppApiemailTemplatesPostEmailTemplateSendTestPathTemplateTypeEnum];

/**
 * 
 * @export
 * @interface AppApiitemTypesDeleteItemTypePath
 */
export interface AppApiitemTypesDeleteItemTypePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemTypesDeleteItemTypePath
     */
    'item_type_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemTypesEditItemTypePath
 */
export interface AppApiitemTypesEditItemTypePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemTypesEditItemTypePath
     */
    'item_type_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemTypesGetItemTypePath
 */
export interface AppApiitemTypesGetItemTypePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemTypesGetItemTypePath
     */
    'item_type_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemsDeleteItemPath
 */
export interface AppApiitemsDeleteItemPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemsDeleteItemPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemsEditItemPath
 */
export interface AppApiitemsEditItemPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemsEditItemPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemsGetItemPath
 */
export interface AppApiitemsGetItemPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemsGetItemPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemsGetItemStatusPath
 */
export interface AppApiitemsGetItemStatusPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemsGetItemStatusPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiitemsRequestMetadataRefreshPath
 */
export interface AppApiitemsRequestMetadataRefreshPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiitemsRequestMetadataRefreshPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApilistingsDeleteListingPath
 */
export interface AppApilistingsDeleteListingPath {
    /**
     * 
     * @type {string}
     * @memberof AppApilistingsDeleteListingPath
     */
    'listing_id': string;
}
/**
 * 
 * @export
 * @interface AppApilistingsEditListingPath
 */
export interface AppApilistingsEditListingPath {
    /**
     * 
     * @type {string}
     * @memberof AppApilistingsEditListingPath
     */
    'listing_id': string;
}
/**
 * 
 * @export
 * @interface AppApilistingsGetListingPath
 */
export interface AppApilistingsGetListingPath {
    /**
     * 
     * @type {string}
     * @memberof AppApilistingsGetListingPath
     */
    'listing_id': string;
}
/**
 * 
 * @export
 * @interface AppApimarketingCampaignsDeleteMarketingCampaignPath
 */
export interface AppApimarketingCampaignsDeleteMarketingCampaignPath {
    /**
     * 
     * @type {string}
     * @memberof AppApimarketingCampaignsDeleteMarketingCampaignPath
     */
    'campaign_id': string;
}
/**
 * 
 * @export
 * @interface AppApimarketingCampaignsEditMarketingCampaignPath
 */
export interface AppApimarketingCampaignsEditMarketingCampaignPath {
    /**
     * 
     * @type {string}
     * @memberof AppApimarketingCampaignsEditMarketingCampaignPath
     */
    'campaign_id': string;
}
/**
 * 
 * @export
 * @interface AppApimarketingCampaignsGetMarketingCampaignByIdPath
 */
export interface AppApimarketingCampaignsGetMarketingCampaignByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApimarketingCampaignsGetMarketingCampaignByIdPath
     */
    'campaign_id': string;
}
/**
 * 
 * @export
 * @interface AppApimintRequestsGetMintRequestByTransactionIdPath
 */
export interface AppApimintRequestsGetMintRequestByTransactionIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApimintRequestsGetMintRequestByTransactionIdPath
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AppApinetworksAddPaymentTokenToNetworkPath
 */
export interface AppApinetworksAddPaymentTokenToNetworkPath {
    /**
     * 
     * @type {number}
     * @memberof AppApinetworksAddPaymentTokenToNetworkPath
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface AppApinetworksDeletePaymentTokenPath
 */
export interface AppApinetworksDeletePaymentTokenPath {
    /**
     * 
     * @type {string}
     * @memberof AppApinetworksDeletePaymentTokenPath
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof AppApinetworksDeletePaymentTokenPath
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface AppApinetworksEditNetworkPath
 */
export interface AppApinetworksEditNetworkPath {
    /**
     * 
     * @type {number}
     * @memberof AppApinetworksEditNetworkPath
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface AppApinetworksGetNetworkPath
 */
export interface AppApinetworksGetNetworkPath {
    /**
     * 
     * @type {number}
     * @memberof AppApinetworksGetNetworkPath
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface AppApinetworksGetNetworkPaymentTokensPath
 */
export interface AppApinetworksGetNetworkPaymentTokensPath {
    /**
     * 
     * @type {number}
     * @memberof AppApinetworksGetNetworkPaymentTokensPath
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface AppApiorganizationInvitationsDeleteOrgInvitationPath
 */
export interface AppApiorganizationInvitationsDeleteOrgInvitationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationInvitationsDeleteOrgInvitationPath
     */
    'invitation_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationInvitationsGetOrgInvitationByTokenPath
 */
export interface AppApiorganizationInvitationsGetOrgInvitationByTokenPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationInvitationsGetOrgInvitationByTokenPath
     */
    'invitation_token': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationInvitationsResendInvitationPath
 */
export interface AppApiorganizationInvitationsResendInvitationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationInvitationsResendInvitationPath
     */
    'invitation_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationInvitationsVerifyOrgInvitationPath
 */
export interface AppApiorganizationInvitationsVerifyOrgInvitationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationInvitationsVerifyOrgInvitationPath
     */
    'invitation_token': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationWalletsGetOrganizationSigningWalletPath
 */
export interface AppApiorganizationWalletsGetOrganizationSigningWalletPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationWalletsGetOrganizationSigningWalletPath
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationWalletsGetOrganizationSigningWalletPath
     */
    'organization_wallet_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationWalletsGetOrganizationWalletBalancePath
 */
export interface AppApiorganizationWalletsGetOrganizationWalletBalancePath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationWalletsGetOrganizationWalletBalancePath
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationWalletsGetOrganizationWalletBalancePath
     */
    'organization_wallet_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationWalletsGetOrganizationWalletsPath
 */
export interface AppApiorganizationWalletsGetOrganizationWalletsPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationWalletsGetOrganizationWalletsPath
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationsEditOrganizationPath
 */
export interface AppApiorganizationsEditOrganizationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationsEditOrganizationPath
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationsEditOrganizationPayoutConfigurationPath
 */
export interface AppApiorganizationsEditOrganizationPayoutConfigurationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationsEditOrganizationPayoutConfigurationPath
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationsGetOrganizationPath
 */
export interface AppApiorganizationsGetOrganizationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationsGetOrganizationPath
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface AppApiorganizationsGetOrganizationPayoutConfigurationPath
 */
export interface AppApiorganizationsGetOrganizationPayoutConfigurationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiorganizationsGetOrganizationPayoutConfigurationPath
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripeDeletePlatformStripeAccountPath
 */
export interface AppApipaymentIntegrationsStripeDeletePlatformStripeAccountPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripeDeletePlatformStripeAccountPath
     */
    'platform_stripe_account_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripeGetPlatformStripeAccountPath
 */
export interface AppApipaymentIntegrationsStripeGetPlatformStripeAccountPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripeGetPlatformStripeAccountPath
     */
    'platform_stripe_account_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripeGetPlatformStripeAccountsByOrganizationPath
 */
export interface AppApipaymentIntegrationsStripeGetPlatformStripeAccountsByOrganizationPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripeGetPlatformStripeAccountsByOrganizationPath
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripePublicSetupStripePaymentsRefreshPath
 */
export interface AppApipaymentIntegrationsStripePublicSetupStripePaymentsRefreshPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripePublicSetupStripePaymentsRefreshPath
     */
    'integration_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripePublicSetupStripePaymentsResultPath
 */
export interface AppApipaymentIntegrationsStripePublicSetupStripePaymentsResultPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripePublicSetupStripePaymentsResultPath
     */
    'integration_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripeStripeIncomingAccountWebhooksPath
 */
export interface AppApipaymentIntegrationsStripeStripeIncomingAccountWebhooksPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripeStripeIncomingAccountWebhooksPath
     */
    'platform_stripe_account_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripeStripeIncomingConnectWebhooksPath
 */
export interface AppApipaymentIntegrationsStripeStripeIncomingConnectWebhooksPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripeStripeIncomingConnectWebhooksPath
     */
    'platform_stripe_account_id': string;
}
/**
 * 
 * @export
 * @interface AppApipaymentIntegrationsStripeUpdatePlatformStripeAccountPath
 */
export interface AppApipaymentIntegrationsStripeUpdatePlatformStripeAccountPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipaymentIntegrationsStripeUpdatePlatformStripeAccountPath
     */
    'platform_stripe_account_id': string;
}
/**
 * 
 * @export
 * @interface AppApipurchaseIntentsGetPurchaseIntentByIdPath
 */
export interface AppApipurchaseIntentsGetPurchaseIntentByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipurchaseIntentsGetPurchaseIntentByIdPath
     */
    'purchase_intent_id': string;
}
/**
 * 
 * @export
 * @interface AppApipurchaseIntentsGetPurchaseIntentItemsPath
 */
export interface AppApipurchaseIntentsGetPurchaseIntentItemsPath {
    /**
     * 
     * @type {string}
     * @memberof AppApipurchaseIntentsGetPurchaseIntentItemsPath
     */
    'purchase_intent_id': string;
}
/**
 * 
 * @export
 * @interface AppApisnapshotsDeleteSnapshotPath
 */
export interface AppApisnapshotsDeleteSnapshotPath {
    /**
     * 
     * @type {string}
     * @memberof AppApisnapshotsDeleteSnapshotPath
     */
    'snapshot_id': string;
}
/**
 * 
 * @export
 * @interface AppApisnapshotsEditSnapshotPath
 */
export interface AppApisnapshotsEditSnapshotPath {
    /**
     * 
     * @type {string}
     * @memberof AppApisnapshotsEditSnapshotPath
     */
    'snapshot_id': string;
}
/**
 * 
 * @export
 * @interface AppApisnapshotsGetSnapshotHoldersCsvPath
 */
export interface AppApisnapshotsGetSnapshotHoldersCsvPath {
    /**
     * 
     * @type {string}
     * @memberof AppApisnapshotsGetSnapshotHoldersCsvPath
     */
    'snapshot_id': string;
}
/**
 * 
 * @export
 * @interface AppApisnapshotsGetSnapshotHoldersPath
 */
export interface AppApisnapshotsGetSnapshotHoldersPath {
    /**
     * 
     * @type {string}
     * @memberof AppApisnapshotsGetSnapshotHoldersPath
     */
    'snapshot_id': string;
}
/**
 * 
 * @export
 * @interface AppApisnapshotsGetSnapshotPath
 */
export interface AppApisnapshotsGetSnapshotPath {
    /**
     * 
     * @type {string}
     * @memberof AppApisnapshotsGetSnapshotPath
     */
    'snapshot_id': string;
}
/**
 * 
 * @export
 * @interface AppApitransactionsEditTransactionPath
 */
export interface AppApitransactionsEditTransactionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApitransactionsEditTransactionPath
     */
    'transaction_id': string;
}
/**
 * 
 * @export
 * @interface AppApitransactionsGetTransactionPath
 */
export interface AppApitransactionsGetTransactionPath {
    /**
     * 
     * @type {string}
     * @memberof AppApitransactionsGetTransactionPath
     */
    'transaction_id': string;
}
/**
 * 
 * @export
 * @interface AppApitransferRequestsGetTransferRequestByTransactionIdPath
 */
export interface AppApitransferRequestsGetTransferRequestByTransactionIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApitransferRequestsGetTransferRequestByTransactionIdPath
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AppApiusersDeleteUserPath
 */
export interface AppApiusersDeleteUserPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiusersDeleteUserPath
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface AppApiusersEditUserPath
 */
export interface AppApiusersEditUserPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiusersEditUserPath
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface AppApiusersGetUserByIdPath
 */
export interface AppApiusersGetUserByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiusersGetUserByIdPath
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface AppApiwebhooksDeleteWebhookPath
 */
export interface AppApiwebhooksDeleteWebhookPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiwebhooksDeleteWebhookPath
     */
    'webhook_id': string;
}
/**
 * 
 * @export
 * @interface AppApiwebhooksEditWebhookPath
 */
export interface AppApiwebhooksEditWebhookPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiwebhooksEditWebhookPath
     */
    'webhook_id': string;
}
/**
 * 
 * @export
 * @interface BulkUpdateItemOutput
 */
export interface BulkUpdateItemOutput {
    /**
     * 
     * @type {Array<BulkUpdateItemOutputResultsInner>}
     * @memberof BulkUpdateItemOutput
     */
    'results': Array<BulkUpdateItemOutputResultsInner>;
}
/**
 * 
 * @export
 * @interface BulkUpdateItemOutputResultsInner
 */
export interface BulkUpdateItemOutputResultsInner {
    /**
     * 
     * @type {object}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'item_type_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'locked': boolean;
    /**
     * Uint256
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'max_supply'?: string | null;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerMedia}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'media': BulkUpdateItemOutputResultsInnerMedia;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
    /**
     * Uint256
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'token_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInner
     */
    'token_status': BulkUpdateItemOutputResultsInnerTokenStatusEnum;
}

export const BulkUpdateItemOutputResultsInnerTokenStatusEnum = {
    Minted: 'MINTED',
    NotMinted: 'NOT_MINTED'
} as const;

export type BulkUpdateItemOutputResultsInnerTokenStatusEnum = typeof BulkUpdateItemOutputResultsInnerTokenStatusEnum[keyof typeof BulkUpdateItemOutputResultsInnerTokenStatusEnum];

/**
 * 
 * @export
 * @interface BulkUpdateItemOutputResultsInnerMedia
 */
export interface BulkUpdateItemOutputResultsInnerMedia {
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerMediaImage}
     * @memberof BulkUpdateItemOutputResultsInnerMedia
     */
    'image'?: BulkUpdateItemOutputResultsInnerMediaImage | null;
}
/**
 * 
 * @export
 * @interface BulkUpdateItemOutputResultsInnerMediaImage
 */
export interface BulkUpdateItemOutputResultsInnerMediaImage {
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInnerMediaImage
     */
    'cid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInnerMediaImage
     */
    'full'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInnerMediaImage
     */
    'original'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInnerMediaImage
     */
    'thumb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInnerMediaImage
     */
    'tiny'?: string | null;
}
/**
 * 
 * @export
 * @interface BulkUpdateItemOutputResultsInnerRoyaltyInfo
 */
export interface BulkUpdateItemOutputResultsInnerRoyaltyInfo {
    /**
     * BasisPoints
     * @type {number}
     * @memberof BulkUpdateItemOutputResultsInnerRoyaltyInfo
     */
    'amount_bps': number;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof BulkUpdateItemOutputResultsInnerRoyaltyInfo
     */
    'receiver': string;
}
/**
 * 
 * @export
 * @interface CallContractFunctionInput
 */
export interface CallContractFunctionInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof CallContractFunctionInput
     */
    'arguments': Array<string>;
}
/**
 * 
 * @export
 * @interface CapabilitiesOutput
 */
export interface CapabilitiesOutput {
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilitiesOutput
     */
    'capabilities': Array<CapabilitiesOutputCapabilitiesEnum>;
}

export const CapabilitiesOutputCapabilitiesEnum = {
    AdminMintMultiple: 'ADMIN_MINT_MULTIPLE',
    AdminMintMultipleNoId: 'ADMIN_MINT_MULTIPLE_NO_ID',
    AdminMintSingle: 'ADMIN_MINT_SINGLE',
    AdminMintSingleUri: 'ADMIN_MINT_SINGLE_URI',
    AuctionV1: 'AUCTION_V1',
    BurnAmount: 'BURN_AMOUNT',
    BurnSingle: 'BURN_SINGLE',
    ContractNameSymbol: 'CONTRACT_NAME_SYMBOL',
    ContractUri: 'CONTRACT_URI',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    CreateForAdminMintWithUri: 'CREATE_FOR_ADMIN_MINT_WITH_URI',
    DeprecatedAdminMint: 'DEPRECATED_ADMIN_MINT',
    DeprecatedManageSupply: 'DEPRECATED_MANAGE_SUPPLY',
    DeprecatedMintToAddressWithTokenUri: 'DEPRECATED_MINT_TO_ADDRESS_WITH_TOKEN_URI',
    DeprecatedDisperse: 'DEPRECATED_DISPERSE',
    Eip165: 'EIP165',
    Erc20: 'ERC20',
    Erc20Metadata: 'ERC20_METADATA',
    Erc721: 'ERC721',
    Erc721Metadata: 'ERC721_METADATA',
    Erc1155: 'ERC1155',
    Erc1155MetadataUri: 'ERC1155_METADATA_URI',
    Erc2981: 'ERC2981',
    ExchangeV1: 'EXCHANGE_V1',
    GrantRevokeMany: 'GRANT_REVOKE_MANY',
    IsMinter: 'IS_MINTER',
    MintMultipleTo: 'MINT_MULTIPLE_TO',
    MintToAddress: 'MINT_TO_ADDRESS',
    MintVoucher: 'MINT_VOUCHER',
    MintVoucherV2: 'MINT_VOUCHER_V2',
    OsRegistryFilter: 'OS_REGISTRY_FILTER',
    OsRegistryFilterToggle: 'OS_REGISTRY_FILTER_TOGGLE',
    OzAccessControl: 'OZ_ACCESS_CONTROL',
    OzOwnable: 'OZ_OWNABLE',
    OzOwnable2Step: 'OZ_OWNABLE_2_STEP',
    ProxyExecute: 'PROXY_EXECUTE',
    RestrictedAuctionV1: 'RESTRICTED_AUCTION_V1',
    SetBaseUri: 'SET_BASE_URI',
    SetBaseUriPermanent: 'SET_BASE_URI_PERMANENT',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetMaxSupply: 'SET_MAX_SUPPLY',
    SetMaxSupplyPerToken: 'SET_MAX_SUPPLY_PER_TOKEN',
    SetTokenRoyalty: 'SET_TOKEN_ROYALTY',
    Soulbound: 'SOULBOUND',
    TokenMintPrices: 'TOKEN_MINT_PRICES',
    WithdrawTokensTo: 'WITHDRAW_TOKENS_TO',
    TokenSupplyView: 'TOKEN_SUPPLY_VIEW'
} as const;

export type CapabilitiesOutputCapabilitiesEnum = typeof CapabilitiesOutputCapabilitiesEnum[keyof typeof CapabilitiesOutputCapabilitiesEnum];

/**
 * 
 * @export
 * @interface ChangePasswordInput
 */
export interface ChangePasswordInput {
    /**
     * SafeString
     * @type {string}
     * @memberof ChangePasswordInput
     */
    'new_password': string;
    /**
     * SafeString
     * @type {string}
     * @memberof ChangePasswordInput
     */
    'old_password': string;
}
/**
 * 
 * @export
 * @interface CollectionOutput
 */
export interface CollectionOutput {
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'base_uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'default_item_type_id'?: string | null;
    /**
     * 
     * @type {CollectionOutputDeployment}
     * @memberof CollectionOutput
     */
    'deployment'?: CollectionOutputDeployment | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionOutput
     */
    'editable_metadata': boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'external_link'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionOutput
     */
    'functions_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionOutput
     */
    'is_public': boolean;
    /**
     * 
     * @type {CollectionOutputMedia}
     * @memberof CollectionOutput
     */
    'media'?: CollectionOutputMedia | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof CollectionOutput
     */
    'preview_metadata'?: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionOutput
     */
    'reveal_hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutput
     */
    'reveal_strategy'?: CollectionOutputRevealStrategyEnum;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof CollectionOutput
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
}

export const CollectionOutputRevealStrategyEnum = {
    Instant: 'INSTANT',
    Delayed: 'DELAYED'
} as const;

export type CollectionOutputRevealStrategyEnum = typeof CollectionOutputRevealStrategyEnum[keyof typeof CollectionOutputRevealStrategyEnum];

/**
 * 
 * @export
 * @interface CollectionOutputDeployment
 */
export interface CollectionOutputDeployment {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'address'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionOutputDeployment
     */
    'capabilities'?: Array<CollectionOutputDeploymentCapabilitiesEnum>;
    /**
     * Uint256
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'max_supply'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionOutputDeployment
     */
    'network_id'?: number | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'owner'?: string | null;
    /**
     * HexString
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'salt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionOutputDeployment
     */
    'soulbound'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'token_id_assignment_strategy'?: CollectionOutputDeploymentTokenIdAssignmentStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'token_type'?: CollectionOutputDeploymentTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutputDeployment
     */
    'transaction_id'?: string | null;
}

export const CollectionOutputDeploymentCapabilitiesEnum = {
    AdminMintMultiple: 'ADMIN_MINT_MULTIPLE',
    AdminMintMultipleNoId: 'ADMIN_MINT_MULTIPLE_NO_ID',
    AdminMintSingle: 'ADMIN_MINT_SINGLE',
    AdminMintSingleUri: 'ADMIN_MINT_SINGLE_URI',
    AuctionV1: 'AUCTION_V1',
    BurnAmount: 'BURN_AMOUNT',
    BurnSingle: 'BURN_SINGLE',
    ContractNameSymbol: 'CONTRACT_NAME_SYMBOL',
    ContractUri: 'CONTRACT_URI',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    CreateForAdminMintWithUri: 'CREATE_FOR_ADMIN_MINT_WITH_URI',
    DeprecatedAdminMint: 'DEPRECATED_ADMIN_MINT',
    DeprecatedManageSupply: 'DEPRECATED_MANAGE_SUPPLY',
    DeprecatedMintToAddressWithTokenUri: 'DEPRECATED_MINT_TO_ADDRESS_WITH_TOKEN_URI',
    DeprecatedDisperse: 'DEPRECATED_DISPERSE',
    Eip165: 'EIP165',
    Erc20: 'ERC20',
    Erc20Metadata: 'ERC20_METADATA',
    Erc721: 'ERC721',
    Erc721Metadata: 'ERC721_METADATA',
    Erc1155: 'ERC1155',
    Erc1155MetadataUri: 'ERC1155_METADATA_URI',
    Erc2981: 'ERC2981',
    ExchangeV1: 'EXCHANGE_V1',
    GrantRevokeMany: 'GRANT_REVOKE_MANY',
    IsMinter: 'IS_MINTER',
    MintMultipleTo: 'MINT_MULTIPLE_TO',
    MintToAddress: 'MINT_TO_ADDRESS',
    MintVoucher: 'MINT_VOUCHER',
    MintVoucherV2: 'MINT_VOUCHER_V2',
    OsRegistryFilter: 'OS_REGISTRY_FILTER',
    OsRegistryFilterToggle: 'OS_REGISTRY_FILTER_TOGGLE',
    OzAccessControl: 'OZ_ACCESS_CONTROL',
    OzOwnable: 'OZ_OWNABLE',
    OzOwnable2Step: 'OZ_OWNABLE_2_STEP',
    ProxyExecute: 'PROXY_EXECUTE',
    RestrictedAuctionV1: 'RESTRICTED_AUCTION_V1',
    SetBaseUri: 'SET_BASE_URI',
    SetBaseUriPermanent: 'SET_BASE_URI_PERMANENT',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetMaxSupply: 'SET_MAX_SUPPLY',
    SetMaxSupplyPerToken: 'SET_MAX_SUPPLY_PER_TOKEN',
    SetTokenRoyalty: 'SET_TOKEN_ROYALTY',
    Soulbound: 'SOULBOUND',
    TokenMintPrices: 'TOKEN_MINT_PRICES',
    WithdrawTokensTo: 'WITHDRAW_TOKENS_TO',
    TokenSupplyView: 'TOKEN_SUPPLY_VIEW'
} as const;

export type CollectionOutputDeploymentCapabilitiesEnum = typeof CollectionOutputDeploymentCapabilitiesEnum[keyof typeof CollectionOutputDeploymentCapabilitiesEnum];
export const CollectionOutputDeploymentTokenIdAssignmentStrategyEnum = {
    Automatic: 'AUTOMATIC',
    Manual: 'MANUAL',
    External: 'EXTERNAL'
} as const;

export type CollectionOutputDeploymentTokenIdAssignmentStrategyEnum = typeof CollectionOutputDeploymentTokenIdAssignmentStrategyEnum[keyof typeof CollectionOutputDeploymentTokenIdAssignmentStrategyEnum];
export const CollectionOutputDeploymentTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type CollectionOutputDeploymentTokenTypeEnum = typeof CollectionOutputDeploymentTokenTypeEnum[keyof typeof CollectionOutputDeploymentTokenTypeEnum];

/**
 * 
 * @export
 * @interface CollectionOutputMedia
 */
export interface CollectionOutputMedia {
    /**
     * 
     * @type {string}
     * @memberof CollectionOutputMedia
     */
    'header_image_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionOutputMedia
     */
    'thumbnail_image_url'?: string | null;
}
/**
 * 
 * @export
 * @interface CollectionsPaginatedOutput
 */
export interface CollectionsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<CollectionsPaginatedOutputResultsInner>}
     * @memberof CollectionsPaginatedOutput
     */
    'results': Array<CollectionsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof CollectionsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface CollectionsPaginatedOutputResultsInner
 */
export interface CollectionsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'base_uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'default_item_type_id'?: string | null;
    /**
     * 
     * @type {CollectionOutputDeployment}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'deployment'?: CollectionOutputDeployment | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'editable_metadata': boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'external_link'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'functions_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'is_public': boolean;
    /**
     * 
     * @type {CollectionOutputMedia}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'media'?: CollectionOutputMedia | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'preview_metadata'?: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'reveal_hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'reveal_strategy'?: CollectionsPaginatedOutputResultsInnerRevealStrategyEnum;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof CollectionsPaginatedOutputResultsInner
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
}

export const CollectionsPaginatedOutputResultsInnerRevealStrategyEnum = {
    Instant: 'INSTANT',
    Delayed: 'DELAYED'
} as const;

export type CollectionsPaginatedOutputResultsInnerRevealStrategyEnum = typeof CollectionsPaginatedOutputResultsInnerRevealStrategyEnum[keyof typeof CollectionsPaginatedOutputResultsInnerRevealStrategyEnum];

/**
 * 
 * @export
 * @interface CreateApiKeyInput
 */
export interface CreateApiKeyInput {
    /**
     * SafeString
     * @type {string}
     * @memberof CreateApiKeyInput
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateApiKeyInput
     */
    'permissions'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateBulkItemApiOutput
 */
export interface CreateBulkItemApiOutput {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBulkItemApiOutput
     */
    'item_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateCollectionInput
 */
export interface CreateCollectionInput {
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionInput
     */
    'default_item_type_id'?: string | null;
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequest}
     * @memberof CreateCollectionInput
     */
    'deployment_request'?: CreateCollectionInputdeploymentRequest | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInput
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCollectionInput
     */
    'editable_metadata'?: boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInput
     */
    'external_link'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInput
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCollectionInput
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {CreateCollectionInputmedia}
     * @memberof CreateCollectionInput
     */
    'media'?: CreateCollectionInputmedia | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInput
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof CreateCollectionInput
     */
    'preview_metadata'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionInput
     */
    'reveal_strategy'?: CreateCollectionInputrevealStrategyEnum;
}

export const CreateCollectionInputrevealStrategyEnum = {
    Instant: 'INSTANT',
    Delayed: 'DELAYED'
} as const;

export type CreateCollectionInputrevealStrategyEnum = typeof CreateCollectionInputrevealStrategyEnum[keyof typeof CreateCollectionInputrevealStrategyEnum];

/**
 * 
 * @export
 * @interface CreateCollectionInputdeploymentRequest
 */
export interface CreateCollectionInputdeploymentRequest {
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequestCustom}
     * @memberof CreateCollectionInputdeploymentRequest
     */
    'custom'?: CreateCollectionInputdeploymentRequestCustom | null;
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequestExternal}
     * @memberof CreateCollectionInputdeploymentRequest
     */
    'external'?: CreateCollectionInputdeploymentRequestExternal | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCollectionInputdeploymentRequest
     */
    'network_id': number;
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequestPlatform}
     * @memberof CreateCollectionInputdeploymentRequest
     */
    'platform'?: CreateCollectionInputdeploymentRequestPlatform | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequest
     */
    'token_id_assignment_strategy'?: CreateCollectionInputdeploymentRequestTokenIdAssignmentStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequest
     */
    'type': CreateCollectionInputdeploymentRequestTypeEnum;
}

export const CreateCollectionInputdeploymentRequestTokenIdAssignmentStrategyEnum = {
    Automatic: 'AUTOMATIC',
    Manual: 'MANUAL',
    External: 'EXTERNAL'
} as const;

export type CreateCollectionInputdeploymentRequestTokenIdAssignmentStrategyEnum = typeof CreateCollectionInputdeploymentRequestTokenIdAssignmentStrategyEnum[keyof typeof CreateCollectionInputdeploymentRequestTokenIdAssignmentStrategyEnum];
export const CreateCollectionInputdeploymentRequestTypeEnum = {
    Platform: 'PLATFORM',
    Custom: 'CUSTOM',
    External: 'EXTERNAL'
} as const;

export type CreateCollectionInputdeploymentRequestTypeEnum = typeof CreateCollectionInputdeploymentRequestTypeEnum[keyof typeof CreateCollectionInputdeploymentRequestTypeEnum];

/**
 * 
 * @export
 * @interface CreateCollectionInputdeploymentRequestCustom
 */
export interface CreateCollectionInputdeploymentRequestCustom {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCollectionInputdeploymentRequestCustom
     */
    'abi': Array<string>;
    /**
     * HexString
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestCustom
     */
    'bytecode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCollectionInputdeploymentRequestCustom
     */
    'constructor_args': Array<string>;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestCustom
     */
    'salt'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCollectionInputdeploymentRequestExternal
 */
export interface CreateCollectionInputdeploymentRequestExternal {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestExternal
     */
    'address': string;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestExternal
     */
    'salt'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof CreateCollectionInputdeploymentRequestExternal
     */
    'token_filter'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestExternal
     */
    'token_type': CreateCollectionInputdeploymentRequestExternalTokenTypeEnum;
}

export const CreateCollectionInputdeploymentRequestExternalTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type CreateCollectionInputdeploymentRequestExternalTokenTypeEnum = typeof CreateCollectionInputdeploymentRequestExternalTokenTypeEnum[keyof typeof CreateCollectionInputdeploymentRequestExternalTokenTypeEnum];

/**
 * 
 * @export
 * @interface CreateCollectionInputdeploymentRequestPlatform
 */
export interface CreateCollectionInputdeploymentRequestPlatform {
    /**
     * Uint256
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestPlatform
     */
    'max_supply'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestPlatform
     */
    'owner'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestPlatform
     */
    'salt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCollectionInputdeploymentRequestPlatform
     */
    'soulbound'?: boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestPlatform
     */
    'symbol': string;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputdeploymentRequestPlatform
     */
    'variant': string;
}
/**
 * 
 * @export
 * @interface CreateCollectionInputmedia
 */
export interface CreateCollectionInputmedia {
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputmedia
     */
    'header_image_url'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateCollectionInputmedia
     */
    'thumbnail_image_url'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateEmailClaimInput
 */
export interface CreateEmailClaimInput {
    /**
     * 
     * @type {string}
     * @memberof CreateEmailClaimInput
     */
    'campaign_id'?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof CreateEmailClaimInput
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmailClaimInput
     */
    'item_id': string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmailClaimInput
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmailClaimInput
     */
    'tx_payer'?: CreateEmailClaimInputTxPayerEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEmailClaimInput
     */
    'type'?: CreateEmailClaimInputTypeEnum;
}

export const CreateEmailClaimInputTxPayerEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type CreateEmailClaimInputTxPayerEnum = typeof CreateEmailClaimInputTxPayerEnum[keyof typeof CreateEmailClaimInputTxPayerEnum];
export const CreateEmailClaimInputTypeEnum = {
    Mint: 'MINT',
    Transfer: 'TRANSFER'
} as const;

export type CreateEmailClaimInputTypeEnum = typeof CreateEmailClaimInputTypeEnum[keyof typeof CreateEmailClaimInputTypeEnum];

/**
 * 
 * @export
 * @interface CreateItemInput
 */
export interface CreateItemInput {
    /**
     * 
     * @type {object}
     * @memberof CreateItemInput
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof CreateItemInput
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemInput
     */
    'item_type_id'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateItemTypeInput
 */
export interface CreateItemTypeInput {
    /**
     * 
     * @type {object}
     * @memberof CreateItemTypeInput
     */
    'json_schema': object;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateItemTypeInput
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemTypeInput
     */
    'organization_id'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateItemsInBulkBody
 */
export interface CreateItemsInBulkBody {
    /**
     * 
     * @type {Array<CreateItemsInBulkBodyItemsInner>}
     * @memberof CreateItemsInBulkBody
     */
    'items': Array<CreateItemsInBulkBodyItemsInner>;
}
/**
 * 
 * @export
 * @interface CreateItemsInBulkBodyItemsInner
 */
export interface CreateItemsInBulkBodyItemsInner {
    /**
     * 
     * @type {object}
     * @memberof CreateItemsInBulkBodyItemsInner
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof CreateItemsInBulkBodyItemsInner
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface CreateMarketingCampaignInput
 */
export interface CreateMarketingCampaignInput {
    /**
     * SafeString
     * @type {string}
     * @memberof CreateMarketingCampaignInput
     */
    'email_from'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateMarketingCampaignInput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateOrganizationEmailTemplateInput
 */
export interface CreateOrganizationEmailTemplateInput {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateInput
     */
    'campaign_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateInput
     */
    'email_from'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateInput
     */
    'html': string;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateInput
     */
    'subject': string;
}
/**
 * 
 * @export
 * @interface CreateOrganizationEmailTemplateTestMailInput
 */
export interface CreateOrganizationEmailTemplateTestMailInput {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateTestMailInput
     */
    'campaign_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateTestMailInput
     */
    'html': string;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateTestMailInput
     */
    'subject': string;
    /**
     * 
     * @type {Array<CreateOrganizationEmailTemplateTestMailInputVariablesInner>}
     * @memberof CreateOrganizationEmailTemplateTestMailInput
     */
    'variables': Array<CreateOrganizationEmailTemplateTestMailInputVariablesInner>;
}
/**
 * 
 * @export
 * @interface CreateOrganizationEmailTemplateTestMailInputVariablesInner
 */
export interface CreateOrganizationEmailTemplateTestMailInputVariablesInner {
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateTestMailInputVariablesInner
     */
    'default_value'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateTestMailInputVariablesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationEmailTemplateTestMailInputVariablesInner
     */
    'template_type': CreateOrganizationEmailTemplateTestMailInputVariablesInnerTemplateTypeEnum;
}

export const CreateOrganizationEmailTemplateTestMailInputVariablesInnerTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type CreateOrganizationEmailTemplateTestMailInputVariablesInnerTemplateTypeEnum = typeof CreateOrganizationEmailTemplateTestMailInputVariablesInnerTemplateTypeEnum[keyof typeof CreateOrganizationEmailTemplateTestMailInputVariablesInnerTemplateTypeEnum];

/**
 * 
 * @export
 * @interface CreateOrganizationInput
 */
export interface CreateOrganizationInput {
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationInput
     */
    'auto_fund'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationInput
     */
    'billing_enabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInput
     */
    'default_payment_method'?: CreateOrganizationInputDefaultPaymentMethodEnum;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationInput
     */
    'domain'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationInput
     */
    'hardware_wallet'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationInput
     */
    'mfa_required'?: boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof CreateOrganizationInput
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrganizationInput
     */
    'platform_sale_fee'?: number;
}

export const CreateOrganizationInputDefaultPaymentMethodEnum = {
    Crypto: 'CRYPTO',
    Fiat: 'FIAT',
    Credit: 'CREDIT'
} as const;

export type CreateOrganizationInputDefaultPaymentMethodEnum = typeof CreateOrganizationInputDefaultPaymentMethodEnum[keyof typeof CreateOrganizationInputDefaultPaymentMethodEnum];

/**
 * 
 * @export
 * @interface CurrentUserOutput
 */
export interface CurrentUserOutput {
    /**
     * 
     * @type {CurrentUserOutputOrganization}
     * @memberof CurrentUserOutput
     */
    'organization': CurrentUserOutputOrganization;
    /**
     * 
     * @type {Array<string>}
     * @memberof CurrentUserOutput
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {CurrentUserOutputUser}
     * @memberof CurrentUserOutput
     */
    'user': CurrentUserOutputUser;
}
/**
 * 
 * @export
 * @interface CurrentUserOutputOrganization
 */
export interface CurrentUserOutputOrganization {
    /**
     * SafeString
     * @type {string}
     * @memberof CurrentUserOutputOrganization
     */
    'auth0_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserOutputOrganization
     */
    'billing_enabled': boolean;
    /**
     * 
     * @type {CurrentUserOutputOrganizationConfig}
     * @memberof CurrentUserOutputOrganization
     */
    'config'?: CurrentUserOutputOrganizationConfig | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputOrganization
     */
    'default_payment_method'?: CurrentUserOutputOrganizationDefaultPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputOrganization
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserOutputOrganization
     */
    'is_enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserOutputOrganization
     */
    'mfa_required': boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof CurrentUserOutputOrganization
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CurrentUserOutputOrganization
     */
    'network_ids': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CurrentUserOutputOrganization
     */
    'stripe_connect_types': Array<CurrentUserOutputOrganizationStripeConnectTypesEnum>;
}

export const CurrentUserOutputOrganizationDefaultPaymentMethodEnum = {
    Crypto: 'CRYPTO',
    Fiat: 'FIAT',
    Credit: 'CREDIT'
} as const;

export type CurrentUserOutputOrganizationDefaultPaymentMethodEnum = typeof CurrentUserOutputOrganizationDefaultPaymentMethodEnum[keyof typeof CurrentUserOutputOrganizationDefaultPaymentMethodEnum];
export const CurrentUserOutputOrganizationStripeConnectTypesEnum = {
    Standard: 'STANDARD',
    Express: 'EXPRESS',
    Custom: 'CUSTOM'
} as const;

export type CurrentUserOutputOrganizationStripeConnectTypesEnum = typeof CurrentUserOutputOrganizationStripeConnectTypesEnum[keyof typeof CurrentUserOutputOrganizationStripeConnectTypesEnum];

/**
 * 
 * @export
 * @interface CurrentUserOutputOrganizationConfig
 */
export interface CurrentUserOutputOrganizationConfig {
    /**
     * 
     * @type {number}
     * @memberof CurrentUserOutputOrganizationConfig
     */
    'concurrent_tx_count'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserOutputOrganizationConfig
     */
    'platform_EOA_enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserOutputOrganizationConfig
     */
    'rate_limit_enabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CurrentUserOutputUser
 */
export interface CurrentUserOutputUser {
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserOutputUser
     */
    'active': boolean;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof CurrentUserOutputUser
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputUser
     */
    'last_seen'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputUser
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOutputUser
     */
    'role_id': string;
}
/**
 * 
 * @export
 * @interface DeployContractInput
 */
export interface DeployContractInput {
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequestCustom}
     * @memberof DeployContractInput
     */
    'custom'?: CreateCollectionInputdeploymentRequestCustom | null;
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequestExternal}
     * @memberof DeployContractInput
     */
    'external'?: CreateCollectionInputdeploymentRequestExternal | null;
    /**
     * 
     * @type {number}
     * @memberof DeployContractInput
     */
    'network_id': number;
    /**
     * 
     * @type {CreateCollectionInputdeploymentRequestPlatform}
     * @memberof DeployContractInput
     */
    'platform'?: CreateCollectionInputdeploymentRequestPlatform | null;
    /**
     * 
     * @type {string}
     * @memberof DeployContractInput
     */
    'token_id_assignment_strategy'?: DeployContractInputTokenIdAssignmentStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof DeployContractInput
     */
    'type': DeployContractInputTypeEnum;
}

export const DeployContractInputTokenIdAssignmentStrategyEnum = {
    Automatic: 'AUTOMATIC',
    Manual: 'MANUAL',
    External: 'EXTERNAL'
} as const;

export type DeployContractInputTokenIdAssignmentStrategyEnum = typeof DeployContractInputTokenIdAssignmentStrategyEnum[keyof typeof DeployContractInputTokenIdAssignmentStrategyEnum];
export const DeployContractInputTypeEnum = {
    Platform: 'PLATFORM',
    Custom: 'CUSTOM',
    External: 'EXTERNAL'
} as const;

export type DeployContractInputTypeEnum = typeof DeployContractInputTypeEnum[keyof typeof DeployContractInputTypeEnum];

/**
 * 
 * @export
 * @interface DeploymentRequestOutput
 */
export interface DeploymentRequestOutput {
    /**
     * 
     * @type {string}
     * @memberof DeploymentRequestOutput
     */
    'status'?: DeploymentRequestOutputStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRequestOutput
     */
    'transaction_id'?: string | null;
}

export const DeploymentRequestOutputStatusEnum = {
    Pending: 'PENDING',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type DeploymentRequestOutputStatusEnum = typeof DeploymentRequestOutputStatusEnum[keyof typeof DeploymentRequestOutputStatusEnum];

/**
 * 
 * @export
 * @interface EditCollectionInput
 */
export interface EditCollectionInput {
    /**
     * 
     * @type {string}
     * @memberof EditCollectionInput
     */
    'default_item_type_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditCollectionInput
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditCollectionInput
     */
    'editable_metadata'?: boolean | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditCollectionInput
     */
    'external_link'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditCollectionInput
     */
    'functions_enabled'?: boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof EditCollectionInput
     */
    'image_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditCollectionInput
     */
    'is_public'?: boolean | null;
    /**
     * 
     * @type {CreateCollectionInputmedia}
     * @memberof EditCollectionInput
     */
    'media'?: CreateCollectionInputmedia | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditCollectionInput
     */
    'name'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof EditCollectionInput
     */
    'preview_metadata'?: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditCollectionInput
     */
    'reveal_hidden'?: boolean | null;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof EditCollectionInput
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
}
/**
 * 
 * @export
 * @interface EditItemTypeInput
 */
export interface EditItemTypeInput {
    /**
     * 
     * @type {object}
     * @memberof EditItemTypeInput
     */
    'json_schema': object;
    /**
     * SafeString
     * @type {string}
     * @memberof EditItemTypeInput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EditItemsInBulkBody
 */
export interface EditItemsInBulkBody {
    /**
     * 
     * @type {Array<EditItemsInBulkBodyItemsInner>}
     * @memberof EditItemsInBulkBody
     */
    'items': Array<EditItemsInBulkBodyItemsInner>;
}
/**
 * 
 * @export
 * @interface EditItemsInBulkBodyItemsInner
 */
export interface EditItemsInBulkBodyItemsInner {
    /**
     * 
     * @type {object}
     * @memberof EditItemsInBulkBodyItemsInner
     */
    'attributes'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof EditItemsInBulkBodyItemsInner
     */
    'item_id': string;
    /**
     * 
     * @type {string}
     * @memberof EditItemsInBulkBodyItemsInner
     */
    'item_type_id'?: string | null;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof EditItemsInBulkBodyItemsInner
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
}
/**
 * 
 * @export
 * @interface EditListingInput
 */
export interface EditListingInput {
    /**
     * 
     * @type {string}
     * @memberof EditListingInput
     */
    'campaign_id'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof EditListingInput
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {EditListingInputPolicy}
     * @memberof EditListingInput
     */
    'policy'?: EditListingInputPolicy | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof EditListingInput
     */
    'start_time'?: string | null;
}
/**
 * 
 * @export
 * @interface EditListingInputPolicy
 */
export interface EditListingInputPolicy {
    /**
     * 
     * @type {Array<string>}
     * @memberof EditListingInputPolicy
     */
    'email_addresses'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof EditListingInputPolicy
     */
    'email_claim_duration'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditListingInputPolicy
     */
    'eth_addresses'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EditListingInputPolicy
     */
    'item_assignment_strategy'?: EditListingInputPolicyItemAssignmentStrategyEnum;
    /**
     * 
     * @type {number}
     * @memberof EditListingInputPolicy
     */
    'max_per_user'?: number | null;
    /**
     * 
     * @type {EditListingInputPolicyPaymentSessionDuration}
     * @memberof EditListingInputPolicy
     */
    'payment_session_duration'?: EditListingInputPolicyPaymentSessionDuration | null;
    /**
     * 
     * @type {string}
     * @memberof EditListingInputPolicy
     */
    'snapshot_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditListingInputPolicy
     */
    'tx_payer'?: EditListingInputPolicyTxPayerEnum;
    /**
     * 
     * @type {string}
     * @memberof EditListingInputPolicy
     */
    'type'?: EditListingInputPolicyTypeEnum;
}

export const EditListingInputPolicyItemAssignmentStrategyEnum = {
    Automatic: 'AUTOMATIC',
    Random: 'RANDOM'
} as const;

export type EditListingInputPolicyItemAssignmentStrategyEnum = typeof EditListingInputPolicyItemAssignmentStrategyEnum[keyof typeof EditListingInputPolicyItemAssignmentStrategyEnum];
export const EditListingInputPolicyTxPayerEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type EditListingInputPolicyTxPayerEnum = typeof EditListingInputPolicyTxPayerEnum[keyof typeof EditListingInputPolicyTxPayerEnum];
export const EditListingInputPolicyTypeEnum = {
    AllowList: 'ALLOW_LIST',
    Fcfs: 'FCFS',
    LargeAllowList: 'LARGE_ALLOW_LIST'
} as const;

export type EditListingInputPolicyTypeEnum = typeof EditListingInputPolicyTypeEnum[keyof typeof EditListingInputPolicyTypeEnum];

/**
 * 
 * @export
 * @interface EditListingInputPolicyPaymentSessionDuration
 */
export interface EditListingInputPolicyPaymentSessionDuration {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EditListingInputPolicyPaymentSessionDuration
     */
    'provider_override'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {number}
     * @memberof EditListingInputPolicyPaymentSessionDuration
     */
    'timeout_seconds'?: number | null;
}
/**
 * 
 * @export
 * @interface EditMarketingCampaignInput
 */
export interface EditMarketingCampaignInput {
    /**
     * SafeString
     * @type {string}
     * @memberof EditMarketingCampaignInput
     */
    'email_from'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditMarketingCampaignInput
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface EditNetworkInput
 */
export interface EditNetworkInput {
    /**
     * 
     * @type {EditNetworkInputConfig}
     * @memberof EditNetworkInput
     */
    'config'?: EditNetworkInputConfig | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditNetworkInput
     */
    'enabled'?: boolean | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditNetworkInput
     */
    'gas_policy_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditNetworkInput
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditNetworkInput
     */
    'testnet'?: boolean | null;
}
/**
 * 
 * @export
 * @interface EditNetworkInputConfig
 */
export interface EditNetworkInputConfig {
    /**
     * 
     * @type {boolean}
     * @memberof EditNetworkInputConfig
     */
    'autoDeploy': boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof EditNetworkInputConfig
     */
    'autoFundAmount': string;
    /**
     * Uint
     * @type {number}
     * @memberof EditNetworkInputConfig
     */
    'confirmations': number;
    /**
     * 
     * @type {boolean}
     * @memberof EditNetworkInputConfig
     */
    'eip155': boolean;
    /**
     * Uint
     * @type {number}
     * @memberof EditNetworkInputConfig
     */
    'fee_limit_gwei'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof EditNetworkInputConfig
     */
    'gasStrategy': object;
    /**
     * Uint
     * @type {number}
     * @memberof EditNetworkInputConfig
     */
    'maxConcurrentTxs': number;
    /**
     * 
     * @type {boolean}
     * @memberof EditNetworkInputConfig
     */
    'poa': boolean;
    /**
     * Uint
     * @type {number}
     * @memberof EditNetworkInputConfig
     */
    'txResubmitBlockCount'?: number | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditNetworkInputConfig
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface EditOrganizationEmailTemplateInput
 */
export interface EditOrganizationEmailTemplateInput {
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationEmailTemplateInput
     */
    'campaign_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditOrganizationEmailTemplateInput
     */
    'email_from'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditOrganizationEmailTemplateInput
     */
    'html': string;
    /**
     * SafeString
     * @type {string}
     * @memberof EditOrganizationEmailTemplateInput
     */
    'subject': string;
}
/**
 * 
 * @export
 * @interface EditOrganizationInput
 */
export interface EditOrganizationInput {
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationInput
     */
    'billing_enabled'?: boolean | null;
    /**
     * 
     * @type {CurrentUserOutputOrganizationConfig}
     * @memberof EditOrganizationInput
     */
    'config'?: CurrentUserOutputOrganizationConfig | null;
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationInput
     */
    'default_payment_method'?: EditOrganizationInputDefaultPaymentMethodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationInput
     */
    'is_enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationInput
     */
    'mfa_required'?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditOrganizationInput
     */
    'network_ids'?: Array<number> | null;
}

export const EditOrganizationInputDefaultPaymentMethodEnum = {
    Crypto: 'CRYPTO',
    Fiat: 'FIAT',
    Credit: 'CREDIT'
} as const;

export type EditOrganizationInputDefaultPaymentMethodEnum = typeof EditOrganizationInputDefaultPaymentMethodEnum[keyof typeof EditOrganizationInputDefaultPaymentMethodEnum];

/**
 * 
 * @export
 * @interface EditOrganizationPayoutConfigurationInput
 */
export interface EditOrganizationPayoutConfigurationInput {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof EditOrganizationPayoutConfigurationInput
     */
    'payout_address'?: string | null;
    /**
     * BasisPoints
     * @type {number}
     * @memberof EditOrganizationPayoutConfigurationInput
     */
    'platform_sale_fee'?: number | null;
}
/**
 * 
 * @export
 * @interface EditPlatformStripeAccountInput
 */
export interface EditPlatformStripeAccountInput {
    /**
     * SafeString
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'account_webhook_endpoint_secret'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof EditPlatformStripeAccountInput
     */
    'config'?: object | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'connect_webhook_endpoint_secret'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EditPlatformStripeAccountInput
     */
    'fee_percentage_bps'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'mode'?: EditPlatformStripeAccountInputModeEnum;
    /**
     * SafeString
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'name'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'publishable_key'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'secret_key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EditPlatformStripeAccountInput
     */
    'session_timeout_minutes'?: number | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditPlatformStripeAccountInput
     */
    'stripe_account_id'?: string | null;
}

export const EditPlatformStripeAccountInputModeEnum = {
    Live: 'LIVE',
    Test: 'TEST'
} as const;

export type EditPlatformStripeAccountInputModeEnum = typeof EditPlatformStripeAccountInputModeEnum[keyof typeof EditPlatformStripeAccountInputModeEnum];

/**
 * 
 * @export
 * @interface EditSnapshotInput
 */
export interface EditSnapshotInput {
    /**
     * SafeString
     * @type {string}
     * @memberof EditSnapshotInput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EditTransactionInput
 */
export interface EditTransactionInput {
    /**
     * 
     * @type {string}
     * @memberof EditTransactionInput
     */
    'state': EditTransactionInputStateEnum;
}

export const EditTransactionInputStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type EditTransactionInputStateEnum = typeof EditTransactionInputStateEnum[keyof typeof EditTransactionInputStateEnum];

/**
 * 
 * @export
 * @interface EditUserInput
 */
export interface EditUserInput {
    /**
     * 
     * @type {string}
     * @memberof EditUserInput
     */
    'role_id'?: string | null;
}
/**
 * 
 * @export
 * @interface EditWebhookInput
 */
export interface EditWebhookInput {
    /**
     * 
     * @type {boolean}
     * @memberof EditWebhookInput
     */
    'enabled'?: boolean | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditWebhookInput
     */
    'name'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditWebhookInput
     */
    'secret'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof EditWebhookInput
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailClaim
 */
export interface EmailClaim {
    /**
     * 
     * @type {boolean}
     * @memberof EmailClaim
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailClaim
     */
    'claimed': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'claimed_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'item_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'last_pin_code_email_sent_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmailClaim
     */
    'quantity': number;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof EmailClaim
     */
    'to_address'?: string | null;
    /**
     * Uint256
     * @type {string}
     * @memberof EmailClaim
     */
    'token_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'transaction_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'tx_hash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'tx_payer': EmailClaimTxPayerEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailClaim
     */
    'voucher_id': string;
}

export const EmailClaimTxPayerEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type EmailClaimTxPayerEnum = typeof EmailClaimTxPayerEnum[keyof typeof EmailClaimTxPayerEnum];

/**
 * 
 * @export
 * @interface EmailClaimsPaginatedResult
 */
export interface EmailClaimsPaginatedResult {
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResult
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailClaimsPaginatedResult
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<EmailClaimsPaginatedResultResultsInner>}
     * @memberof EmailClaimsPaginatedResult
     */
    'results': Array<EmailClaimsPaginatedResultResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof EmailClaimsPaginatedResult
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface EmailClaimsPaginatedResultResultsInner
 */
export interface EmailClaimsPaginatedResultResultsInner {
    /**
     * 
     * @type {boolean}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'claimed': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'claimed_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'item_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'last_pin_code_email_sent_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'quantity': number;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'to_address'?: string | null;
    /**
     * Uint256
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'token_id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'transaction_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'tx_hash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'tx_payer': EmailClaimsPaginatedResultResultsInnerTxPayerEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailClaimsPaginatedResultResultsInner
     */
    'voucher_id': string;
}

export const EmailClaimsPaginatedResultResultsInnerTxPayerEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type EmailClaimsPaginatedResultResultsInnerTxPayerEnum = typeof EmailClaimsPaginatedResultResultsInnerTxPayerEnum[keyof typeof EmailClaimsPaginatedResultResultsInnerTxPayerEnum];

/**
 * 
 * @export
 * @interface EmailIntegrationInput
 */
export interface EmailIntegrationInput {
    /**
     * 
     * @type {object}
     * @memberof EmailIntegrationInput
     */
    'config': object;
    /**
     * 
     * @type {string}
     * @memberof EmailIntegrationInput
     */
    'provider': EmailIntegrationInputProviderEnum;
}

export const EmailIntegrationInputProviderEnum = {
    Mailgun: 'MAILGUN',
    AmazonSes: 'AMAZON_SES',
    Sendgrid: 'SENDGRID',
    CustomSmtp: 'CUSTOM_SMTP'
} as const;

export type EmailIntegrationInputProviderEnum = typeof EmailIntegrationInputProviderEnum[keyof typeof EmailIntegrationInputProviderEnum];

/**
 * 
 * @export
 * @interface EmailIntegrationOutput
 */
export interface EmailIntegrationOutput {
    /**
     * 
     * @type {object}
     * @memberof EmailIntegrationOutput
     */
    'config': object;
    /**
     * 
     * @type {string}
     * @memberof EmailIntegrationOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailIntegrationOutput
     */
    'provider': EmailIntegrationOutputProviderEnum;
}

export const EmailIntegrationOutputProviderEnum = {
    Mailgun: 'MAILGUN',
    AmazonSes: 'AMAZON_SES',
    Sendgrid: 'SENDGRID',
    CustomSmtp: 'CUSTOM_SMTP'
} as const;

export type EmailIntegrationOutputProviderEnum = typeof EmailIntegrationOutputProviderEnum[keyof typeof EmailIntegrationOutputProviderEnum];

/**
 * 
 * @export
 * @interface EmailIntegrationsPaginatedOutput
 */
export interface EmailIntegrationsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof EmailIntegrationsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailIntegrationsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<EmailIntegrationsPaginatedOutputResultsInner>}
     * @memberof EmailIntegrationsPaginatedOutput
     */
    'results': Array<EmailIntegrationsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof EmailIntegrationsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface EmailIntegrationsPaginatedOutputResultsInner
 */
export interface EmailIntegrationsPaginatedOutputResultsInner {
    /**
     * 
     * @type {object}
     * @memberof EmailIntegrationsPaginatedOutputResultsInner
     */
    'config': object;
    /**
     * 
     * @type {string}
     * @memberof EmailIntegrationsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EmailIntegrationsPaginatedOutputResultsInner
     */
    'provider': EmailIntegrationsPaginatedOutputResultsInnerProviderEnum;
}

export const EmailIntegrationsPaginatedOutputResultsInnerProviderEnum = {
    Mailgun: 'MAILGUN',
    AmazonSes: 'AMAZON_SES',
    Sendgrid: 'SENDGRID',
    CustomSmtp: 'CUSTOM_SMTP'
} as const;

export type EmailIntegrationsPaginatedOutputResultsInnerProviderEnum = typeof EmailIntegrationsPaginatedOutputResultsInnerProviderEnum[keyof typeof EmailIntegrationsPaginatedOutputResultsInnerProviderEnum];

/**
 * 
 * @export
 * @interface EmailTemplateTypesPaginatedOutput
 */
export interface EmailTemplateTypesPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateTypesPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateTypesPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailTemplateTypesPaginatedOutput
     */
    'results': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateTypesPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface EmailTemplateVariablesPaginatedOutput
 */
export interface EmailTemplateVariablesPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateVariablesPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateVariablesPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<CreateOrganizationEmailTemplateTestMailInputVariablesInner>}
     * @memberof EmailTemplateVariablesPaginatedOutput
     */
    'results': Array<CreateOrganizationEmailTemplateTestMailInputVariablesInner>;
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateVariablesPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface EmailTemplatesPaginatedOutput
 */
export interface EmailTemplatesPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplatesPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<EmailTemplatesPaginatedOutputResultsInner>}
     * @memberof EmailTemplatesPaginatedOutput
     */
    'results': Array<EmailTemplatesPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof EmailTemplatesPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface EmailTemplatesPaginatedOutputResultsInner
 */
export interface EmailTemplatesPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutputResultsInner
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutputResultsInner
     */
    'email_from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutputResultsInner
     */
    'html': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutputResultsInner
     */
    'origin_type': EmailTemplatesPaginatedOutputResultsInnerOriginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutputResultsInner
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplatesPaginatedOutputResultsInner
     */
    'template_type': EmailTemplatesPaginatedOutputResultsInnerTemplateTypeEnum;
}

export const EmailTemplatesPaginatedOutputResultsInnerOriginTypeEnum = {
    PlatformDefault: 'PLATFORM_DEFAULT',
    OrganizationCustom: 'ORGANIZATION_CUSTOM'
} as const;

export type EmailTemplatesPaginatedOutputResultsInnerOriginTypeEnum = typeof EmailTemplatesPaginatedOutputResultsInnerOriginTypeEnum[keyof typeof EmailTemplatesPaginatedOutputResultsInnerOriginTypeEnum];
export const EmailTemplatesPaginatedOutputResultsInnerTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type EmailTemplatesPaginatedOutputResultsInnerTemplateTypeEnum = typeof EmailTemplatesPaginatedOutputResultsInnerTemplateTypeEnum[keyof typeof EmailTemplatesPaginatedOutputResultsInnerTemplateTypeEnum];

/**
 * 
 * @export
 * @interface EnableCollectionContractFunctionInput
 */
export interface EnableCollectionContractFunctionInput {
    /**
     * 
     * @type {boolean}
     * @memberof EnableCollectionContractFunctionInput
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface EnableCollectionContractFunctionOutput
 */
export interface EnableCollectionContractFunctionOutput {
    /**
     * 
     * @type {EnableCollectionContractFunctionOutputContractFunc}
     * @memberof EnableCollectionContractFunctionOutput
     */
    'contract_func': EnableCollectionContractFunctionOutputContractFunc;
}
/**
 * 
 * @export
 * @interface EnableCollectionContractFunctionOutputContractFunc
 */
export interface EnableCollectionContractFunctionOutputContractFunc {
    /**
     * 
     * @type {object}
     * @memberof EnableCollectionContractFunctionOutputContractFunc
     */
    'abi': object;
    /**
     * 
     * @type {boolean}
     * @memberof EnableCollectionContractFunctionOutputContractFunc
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof EnableCollectionContractFunctionOutputContractFunc
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ExecuteContractFunctionInput
 */
export interface ExecuteContractFunctionInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecuteContractFunctionInput
     */
    'arguments': Array<string>;
}
/**
 * 
 * @export
 * @interface ExecuteContractFunctionOutput
 */
export interface ExecuteContractFunctionOutput {
    /**
     * 
     * @type {string}
     * @memberof ExecuteContractFunctionOutput
     */
    'transaction_id': string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordInput
 */
export interface ForgotPasswordInput {
    /**
     * Email
     * @type {string}
     * @memberof ForgotPasswordInput
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GetApiKeysInput
 */
export interface GetApiKeysInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetApiKeysInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetApiKeysInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetApiKeysInput
     */
    'order_direction'?: GetApiKeysInputOrderDirectionEnum;
}

export const GetApiKeysInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetApiKeysInputOrderDirectionEnum = typeof GetApiKeysInputOrderDirectionEnum[keyof typeof GetApiKeysInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetCollectionContractFunctionByIdOutput
 */
export interface GetCollectionContractFunctionByIdOutput {
    /**
     * 
     * @type {object}
     * @memberof GetCollectionContractFunctionByIdOutput
     */
    'abi': object;
    /**
     * 
     * @type {boolean}
     * @memberof GetCollectionContractFunctionByIdOutput
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCollectionContractFunctionByIdOutput
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetCollectionContractFunctionsPaginatedInput
 */
export interface GetCollectionContractFunctionsPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetCollectionContractFunctionsPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetCollectionContractFunctionsPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCollectionContractFunctionsPaginatedInput
     */
    'order_direction'?: GetCollectionContractFunctionsPaginatedInputOrderDirectionEnum;
}

export const GetCollectionContractFunctionsPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetCollectionContractFunctionsPaginatedInputOrderDirectionEnum = typeof GetCollectionContractFunctionsPaginatedInputOrderDirectionEnum[keyof typeof GetCollectionContractFunctionsPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetCollectionContractFunctionsPaginatedOutput
 */
export interface GetCollectionContractFunctionsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof GetCollectionContractFunctionsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCollectionContractFunctionsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<EnableCollectionContractFunctionOutputContractFunc>}
     * @memberof GetCollectionContractFunctionsPaginatedOutput
     */
    'results': Array<EnableCollectionContractFunctionOutputContractFunc>;
    /**
     * 
     * @type {number}
     * @memberof GetCollectionContractFunctionsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface GetCustomizedEmailTemplateInput
 */
export interface GetCustomizedEmailTemplateInput {
    /**
     * 
     * @type {string}
     * @memberof GetCustomizedEmailTemplateInput
     */
    'campaign_id'?: string | null;
}
/**
 * 
 * @export
 * @interface GetEmailClaimsInput
 */
export interface GetEmailClaimsInput {
    /**
     * 
     * @type {boolean}
     * @memberof GetEmailClaimsInput
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmailClaimsInput
     */
    'claimed'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEmailClaimsInput
     */
    'collection_ids'?: Array<string> | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetEmailClaimsInput
     */
    'cursor'?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof GetEmailClaimsInput
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEmailClaimsInput
     */
    'item_ids'?: Array<string> | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetEmailClaimsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetEmailClaimsInput
     */
    'order_by'?: GetEmailClaimsInputOrderByEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEmailClaimsInput
     */
    'order_direction'?: GetEmailClaimsInputOrderDirectionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEmailClaimsInput
     */
    'token_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEmailClaimsInput
     */
    'transaction_id'?: Array<string> | null;
}

export const GetEmailClaimsInputOrderByEnum = {
    CreatedAt: 'CREATED_AT',
    Expiration: 'EXPIRATION',
    ClaimedAt: 'CLAIMED_AT'
} as const;

export type GetEmailClaimsInputOrderByEnum = typeof GetEmailClaimsInputOrderByEnum[keyof typeof GetEmailClaimsInputOrderByEnum];
export const GetEmailClaimsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetEmailClaimsInputOrderDirectionEnum = typeof GetEmailClaimsInputOrderDirectionEnum[keyof typeof GetEmailClaimsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetEmailTemplatesInput
 */
export interface GetEmailTemplatesInput {
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplatesInput
     */
    'campaign_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetEmailTemplatesInput
     */
    'cursor'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetEmailTemplatesInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplatesInput
     */
    'order_direction'?: GetEmailTemplatesInputOrderDirectionEnum;
}

export const GetEmailTemplatesInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetEmailTemplatesInputOrderDirectionEnum = typeof GetEmailTemplatesInputOrderDirectionEnum[keyof typeof GetEmailTemplatesInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetListingsInput
 */
export interface GetListingsInput {
    /**
     * 
     * @type {string}
     * @memberof GetListingsInput
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetListingsInput
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetListingsInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetListingsInput
     */
    'item_id'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetListingsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetListingsInput
     */
    'order_direction'?: GetListingsInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetListingsInput
     */
    'status'?: GetListingsInputStatusEnum;
}

export const GetListingsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetListingsInputOrderDirectionEnum = typeof GetListingsInputOrderDirectionEnum[keyof typeof GetListingsInputOrderDirectionEnum];
export const GetListingsInputStatusEnum = {
    Active: 'ACTIVE',
    Cancelled: 'CANCELLED',
    Complete: 'COMPLETE',
    PendingTx: 'PENDING_TX'
} as const;

export type GetListingsInputStatusEnum = typeof GetListingsInputStatusEnum[keyof typeof GetListingsInputStatusEnum];

/**
 * 
 * @export
 * @interface GetMarketingCampaignsInput
 */
export interface GetMarketingCampaignsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetMarketingCampaignsInput
     */
    'cursor'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetMarketingCampaignsInput
     */
    'limit'?: number;
    /**
     * SafeString
     * @type {string}
     * @memberof GetMarketingCampaignsInput
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMarketingCampaignsInput
     */
    'order_direction'?: GetMarketingCampaignsInputOrderDirectionEnum;
}

export const GetMarketingCampaignsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetMarketingCampaignsInputOrderDirectionEnum = typeof GetMarketingCampaignsInputOrderDirectionEnum[keyof typeof GetMarketingCampaignsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetOrgInvitationsPaginatedInput
 */
export interface GetOrgInvitationsPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetOrgInvitationsPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedInput
     */
    'order_direction'?: GetOrgInvitationsPaginatedInputOrderDirectionEnum;
}

export const GetOrgInvitationsPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetOrgInvitationsPaginatedInputOrderDirectionEnum = typeof GetOrgInvitationsPaginatedInputOrderDirectionEnum[keyof typeof GetOrgInvitationsPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetOrgInvitationsPaginatedOutput
 */
export interface GetOrgInvitationsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrgInvitationsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<GetOrgInvitationsPaginatedOutputResultsInner>}
     * @memberof GetOrgInvitationsPaginatedOutput
     */
    'results': Array<GetOrgInvitationsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOrgInvitationsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface GetOrgInvitationsPaginatedOutputResultsInner
 */
export interface GetOrgInvitationsPaginatedOutputResultsInner {
    /**
     * UtcDateTime
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'email'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'organization_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'role_id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'token': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'verified': boolean;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof GetOrgInvitationsPaginatedOutputResultsInner
     */
    'verified_at': string;
}
/**
 * 
 * @export
 * @interface GetOrgWalletsPaginatedOutput
 */
export interface GetOrgWalletsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof GetOrgWalletsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrgWalletsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<GetOrgWalletsPaginatedOutputResultsInner>}
     * @memberof GetOrgWalletsPaginatedOutput
     */
    'results': Array<GetOrgWalletsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOrgWalletsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface GetOrgWalletsPaginatedOutputResultsInner
 */
export interface GetOrgWalletsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof GetOrgWalletsPaginatedOutputResultsInner
     */
    'address': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrgWalletsPaginatedOutputResultsInner
     */
    'auto_fund': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOrgWalletsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrgWalletsPaginatedOutputResultsInner
     */
    'is_default': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOrgWalletsPaginatedOutputResultsInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetPaginatedCollectionsInput
 */
export interface GetPaginatedCollectionsInput {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetPaginatedCollectionsInput
     */
    'contract_address'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedCollectionsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedCollectionsInput
     */
    'limit'?: number;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedCollectionsInput
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedCollectionsInput
     */
    'network_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedCollectionsInput
     */
    'order_by'?: GetPaginatedCollectionsInputOrderByEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedCollectionsInput
     */
    'order_direction'?: GetPaginatedCollectionsInputOrderDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedCollectionsInput
     */
    'testnet'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedCollectionsInput
     */
    'token_type'?: GetPaginatedCollectionsInputTokenTypeEnum;
}

export const GetPaginatedCollectionsInputOrderByEnum = {
    Name: 'NAME'
} as const;

export type GetPaginatedCollectionsInputOrderByEnum = typeof GetPaginatedCollectionsInputOrderByEnum[keyof typeof GetPaginatedCollectionsInputOrderByEnum];
export const GetPaginatedCollectionsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedCollectionsInputOrderDirectionEnum = typeof GetPaginatedCollectionsInputOrderDirectionEnum[keyof typeof GetPaginatedCollectionsInputOrderDirectionEnum];
export const GetPaginatedCollectionsInputTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type GetPaginatedCollectionsInputTokenTypeEnum = typeof GetPaginatedCollectionsInputTokenTypeEnum[keyof typeof GetPaginatedCollectionsInputTokenTypeEnum];

/**
 * 
 * @export
 * @interface GetPaginatedEmailIntegrationsInput
 */
export interface GetPaginatedEmailIntegrationsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedEmailIntegrationsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedEmailIntegrationsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedEmailIntegrationsInput
     */
    'order_direction'?: GetPaginatedEmailIntegrationsInputOrderDirectionEnum;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedEmailIntegrationsInput
     */
    'provider'?: string | null;
}

export const GetPaginatedEmailIntegrationsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedEmailIntegrationsInputOrderDirectionEnum = typeof GetPaginatedEmailIntegrationsInputOrderDirectionEnum[keyof typeof GetPaginatedEmailIntegrationsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedItemTypesInput
 */
export interface GetPaginatedItemTypesInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedItemTypesInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedItemTypesInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedItemTypesInput
     */
    'order_direction'?: GetPaginatedItemTypesInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedItemTypesInput
     */
    'organization_id'?: string | null;
}

export const GetPaginatedItemTypesInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedItemTypesInputOrderDirectionEnum = typeof GetPaginatedItemTypesInputOrderDirectionEnum[keyof typeof GetPaginatedItemTypesInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedItemsInput
 */
export interface GetPaginatedItemsInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaginatedItemsInput
     */
    'attributes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedItemsInput
     */
    'collection_id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedItemsInput
     */
    'cursor'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPaginatedItemsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedItemsInput
     */
    'locked'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedItemsInput
     */
    'order_direction'?: GetPaginatedItemsInputOrderDirectionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaginatedItemsInput
     */
    'token_id'?: Array<string> | null;
}

export const GetPaginatedItemsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedItemsInputOrderDirectionEnum = typeof GetPaginatedItemsInputOrderDirectionEnum[keyof typeof GetPaginatedItemsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedMintRequestsInput
 */
export interface GetPaginatedMintRequestsInput {
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedMintRequestsInput
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedMintRequestsInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedMintRequestsInput
     */
    'item_id'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPaginatedMintRequestsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedMintRequestsInput
     */
    'order_direction'?: GetPaginatedMintRequestsInputOrderDirectionEnum;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetPaginatedMintRequestsInput
     */
    'to_address'?: string | null;
}

export const GetPaginatedMintRequestsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedMintRequestsInputOrderDirectionEnum = typeof GetPaginatedMintRequestsInputOrderDirectionEnum[keyof typeof GetPaginatedMintRequestsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedNetworksInput
 */
export interface GetPaginatedNetworksInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedNetworksInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedNetworksInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedNetworksInput
     */
    'order_direction'?: GetPaginatedNetworksInputOrderDirectionEnum;
}

export const GetPaginatedNetworksInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedNetworksInputOrderDirectionEnum = typeof GetPaginatedNetworksInputOrderDirectionEnum[keyof typeof GetPaginatedNetworksInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedOrgWalletsInput
 */
export interface GetPaginatedOrgWalletsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedOrgWalletsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedOrgWalletsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedOrgWalletsInput
     */
    'order_direction'?: GetPaginatedOrgWalletsInputOrderDirectionEnum;
}

export const GetPaginatedOrgWalletsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedOrgWalletsInputOrderDirectionEnum = typeof GetPaginatedOrgWalletsInputOrderDirectionEnum[keyof typeof GetPaginatedOrgWalletsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedOrganizationsInput
 */
export interface GetPaginatedOrganizationsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedOrganizationsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedOrganizationsInput
     */
    'limit'?: number;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedOrganizationsInput
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedOrganizationsInput
     */
    'order_direction'?: GetPaginatedOrganizationsInputOrderDirectionEnum;
}

export const GetPaginatedOrganizationsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedOrganizationsInputOrderDirectionEnum = typeof GetPaginatedOrganizationsInputOrderDirectionEnum[keyof typeof GetPaginatedOrganizationsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedPlaformWalletsInput
 */
export interface GetPaginatedPlaformWalletsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedPlaformWalletsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedPlaformWalletsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedPlaformWalletsInput
     */
    'order_direction'?: GetPaginatedPlaformWalletsInputOrderDirectionEnum;
}

export const GetPaginatedPlaformWalletsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedPlaformWalletsInputOrderDirectionEnum = typeof GetPaginatedPlaformWalletsInputOrderDirectionEnum[keyof typeof GetPaginatedPlaformWalletsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedPlatformStripeAccountsInput
 */
export interface GetPaginatedPlatformStripeAccountsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedPlatformStripeAccountsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedPlatformStripeAccountsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedPlatformStripeAccountsInput
     */
    'order_direction'?: GetPaginatedPlatformStripeAccountsInputOrderDirectionEnum;
}

export const GetPaginatedPlatformStripeAccountsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedPlatformStripeAccountsInputOrderDirectionEnum = typeof GetPaginatedPlatformStripeAccountsInputOrderDirectionEnum[keyof typeof GetPaginatedPlatformStripeAccountsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedPublicPlatformStripeAccountsInput
 */
export interface GetPaginatedPublicPlatformStripeAccountsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedPublicPlatformStripeAccountsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedPublicPlatformStripeAccountsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedPublicPlatformStripeAccountsInput
     */
    'order_direction'?: GetPaginatedPublicPlatformStripeAccountsInputOrderDirectionEnum;
}

export const GetPaginatedPublicPlatformStripeAccountsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedPublicPlatformStripeAccountsInputOrderDirectionEnum = typeof GetPaginatedPublicPlatformStripeAccountsInputOrderDirectionEnum[keyof typeof GetPaginatedPublicPlatformStripeAccountsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedRolesInput
 */
export interface GetPaginatedRolesInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedRolesInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedRolesInput
     */
    'limit'?: number;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedRolesInput
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedRolesInput
     */
    'order_direction'?: GetPaginatedRolesInputOrderDirectionEnum;
}

export const GetPaginatedRolesInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedRolesInputOrderDirectionEnum = typeof GetPaginatedRolesInputOrderDirectionEnum[keyof typeof GetPaginatedRolesInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedTransactionsInput
 */
export interface GetPaginatedTransactionsInput {
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'billing_status'?: GetPaginatedTransactionsInputBillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedTransactionsInput
     */
    'has_failures'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'item_id'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPaginatedTransactionsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetPaginatedTransactionsInput
     */
    'network_ids'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'on_chain_status'?: GetPaginatedTransactionsInputOnChainStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'order_by'?: GetPaginatedTransactionsInputOrderByEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransactionsInput
     */
    'order_direction'?: GetPaginatedTransactionsInputOrderDirectionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaginatedTransactionsInput
     */
    'signers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaginatedTransactionsInput
     */
    'states'?: Array<GetPaginatedTransactionsInputStatesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedTransactionsInput
     */
    'testnet'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaginatedTransactionsInput
     */
    'tx_types'?: Array<GetPaginatedTransactionsInputTxTypesEnum>;
}

export const GetPaginatedTransactionsInputBillingStatusEnum = {
    New: 'NEW',
    Initiated: 'INITIATED',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED',
    Expired: 'EXPIRED',
    Failed: 'FAILED',
    NoCharge: 'NO_CHARGE'
} as const;

export type GetPaginatedTransactionsInputBillingStatusEnum = typeof GetPaginatedTransactionsInputBillingStatusEnum[keyof typeof GetPaginatedTransactionsInputBillingStatusEnum];
export const GetPaginatedTransactionsInputOnChainStatusEnum = {
    Failure: 'FAILURE',
    Success: 'SUCCESS'
} as const;

export type GetPaginatedTransactionsInputOnChainStatusEnum = typeof GetPaginatedTransactionsInputOnChainStatusEnum[keyof typeof GetPaginatedTransactionsInputOnChainStatusEnum];
export const GetPaginatedTransactionsInputOrderByEnum = {
    CreatedAt: 'CREATED_AT'
} as const;

export type GetPaginatedTransactionsInputOrderByEnum = typeof GetPaginatedTransactionsInputOrderByEnum[keyof typeof GetPaginatedTransactionsInputOrderByEnum];
export const GetPaginatedTransactionsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedTransactionsInputOrderDirectionEnum = typeof GetPaginatedTransactionsInputOrderDirectionEnum[keyof typeof GetPaginatedTransactionsInputOrderDirectionEnum];
export const GetPaginatedTransactionsInputStatesEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type GetPaginatedTransactionsInputStatesEnum = typeof GetPaginatedTransactionsInputStatesEnum[keyof typeof GetPaginatedTransactionsInputStatesEnum];
export const GetPaginatedTransactionsInputTxTypesEnum = {
    DeployTokenContract: 'DEPLOY_TOKEN_CONTRACT',
    DeployContractViaFactory: 'DEPLOY_CONTRACT_VIA_FACTORY',
    SetApprovalForAll: 'SET_APPROVAL_FOR_ALL',
    MintToken: 'MINT_TOKEN',
    AdminMintWithUri: 'ADMIN_MINT_WITH_URI',
    TransferToken: 'TRANSFER_TOKEN',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    FundAccount: 'FUND_ACCOUNT',
    WithdrawBalance: 'WITHDRAW_BALANCE',
    CreateOnchainEnglishAuction: 'CREATE_ONCHAIN_ENGLISH_AUCTION',
    SettleOnchainEnglishAuction: 'SETTLE_ONCHAIN_ENGLISH_AUCTION',
    CancelOnchainEnglishAuction: 'CANCEL_ONCHAIN_ENGLISH_AUCTION',
    AdminMint: 'ADMIN_MINT',
    AdminMintNoId: 'ADMIN_MINT_NO_ID',
    TransferOwnership: 'TRANSFER_OWNERSHIP',
    GrantRole: 'GRANT_ROLE',
    GrantMany: 'GRANT_MANY',
    InvokeFunction: 'INVOKE_FUNCTION',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetPerTokenRoyalty: 'SET_PER_TOKEN_ROYALTY',
    UnsetPerTokenRoyalty: 'UNSET_PER_TOKEN_ROYALTY',
    DistributorContract: 'DISTRIBUTOR_CONTRACT',
    SetBaseUri: 'SET_BASE_URI',
    TransferErc20: 'TRANSFER_ERC20',
    BulkAdminMint: 'BULK_ADMIN_MINT',
    BillingStatus: 'BILLING_STATUS',
    CreateTokenFixedPrice: 'CREATE_TOKEN_FIXED_PRICE',
    Disperse: 'DISPERSE'
} as const;

export type GetPaginatedTransactionsInputTxTypesEnum = typeof GetPaginatedTransactionsInputTxTypesEnum[keyof typeof GetPaginatedTransactionsInputTxTypesEnum];

/**
 * 
 * @export
 * @interface GetPaginatedTransferRequestsInput
 */
export interface GetPaginatedTransferRequestsInput {
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransferRequestsInput
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedTransferRequestsInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransferRequestsInput
     */
    'item_id'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPaginatedTransferRequestsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedTransferRequestsInput
     */
    'order_direction'?: GetPaginatedTransferRequestsInputOrderDirectionEnum;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetPaginatedTransferRequestsInput
     */
    'to_address'?: string | null;
}

export const GetPaginatedTransferRequestsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedTransferRequestsInputOrderDirectionEnum = typeof GetPaginatedTransferRequestsInputOrderDirectionEnum[keyof typeof GetPaginatedTransferRequestsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPaginatedUsersInput
 */
export interface GetPaginatedUsersInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPaginatedUsersInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPaginatedUsersInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedUsersInput
     */
    'order_direction'?: GetPaginatedUsersInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedUsersInput
     */
    'organization_id'?: string | null;
}

export const GetPaginatedUsersInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPaginatedUsersInputOrderDirectionEnum = typeof GetPaginatedUsersInputOrderDirectionEnum[keyof typeof GetPaginatedUsersInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPlatformWalletsPaginatedOutput
 */
export interface GetPlatformWalletsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof GetPlatformWalletsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPlatformWalletsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<GetPlatformWalletsPaginatedOutputResultsInner>}
     * @memberof GetPlatformWalletsPaginatedOutput
     */
    'results': Array<GetPlatformWalletsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetPlatformWalletsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface GetPlatformWalletsPaginatedOutputResultsInner
 */
export interface GetPlatformWalletsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof GetPlatformWalletsPaginatedOutputResultsInner
     */
    'address': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPlatformWalletsPaginatedOutputResultsInner
     */
    'custodial': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPlatformWalletsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetPlatformWalletsPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetPlatformWalletsPaginatedOutputResultsInner
     */
    'network_ids': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPlatformWalletsPaginatedOutputResultsInner
     */
    'restrict_networks': boolean;
}
/**
 * 
 * @export
 * @interface GetPublicOrganizationsPaginatedInput
 */
export interface GetPublicOrganizationsPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPublicOrganizationsPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof GetPublicOrganizationsPaginatedInput
     */
    'email': string;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetPublicOrganizationsPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPublicOrganizationsPaginatedInput
     */
    'order_direction'?: GetPublicOrganizationsPaginatedInputOrderDirectionEnum;
}

export const GetPublicOrganizationsPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPublicOrganizationsPaginatedInputOrderDirectionEnum = typeof GetPublicOrganizationsPaginatedInputOrderDirectionEnum[keyof typeof GetPublicOrganizationsPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPurchaseIntentInput
 */
export interface GetPurchaseIntentInput {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'cursor'?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'email'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'eth_address'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPurchaseIntentInput
     */
    'fulfillment_method'?: Array<GetPurchaseIntentInputFulfillmentMethodEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPurchaseIntentInput
     */
    'fulfillment_status'?: Array<GetPurchaseIntentInputFulfillmentStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'item_id'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPurchaseIntentInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'listing_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseIntentInput
     */
    'order_direction'?: GetPurchaseIntentInputOrderDirectionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPurchaseIntentInput
     */
    'payment_provider'?: Array<GetPurchaseIntentInputPaymentProviderEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPurchaseIntentInput
     */
    'status'?: Array<GetPurchaseIntentInputStatusEnum>;
}

export const GetPurchaseIntentInputFulfillmentMethodEnum = {
    Email: 'EMAIL',
    Transfer: 'TRANSFER',
    Voucher: 'VOUCHER'
} as const;

export type GetPurchaseIntentInputFulfillmentMethodEnum = typeof GetPurchaseIntentInputFulfillmentMethodEnum[keyof typeof GetPurchaseIntentInputFulfillmentMethodEnum];
export const GetPurchaseIntentInputFulfillmentStatusEnum = {
    Pending: 'PENDING',
    Assigned: 'ASSIGNED',
    Completed: 'COMPLETED',
    Exception: 'EXCEPTION'
} as const;

export type GetPurchaseIntentInputFulfillmentStatusEnum = typeof GetPurchaseIntentInputFulfillmentStatusEnum[keyof typeof GetPurchaseIntentInputFulfillmentStatusEnum];
export const GetPurchaseIntentInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPurchaseIntentInputOrderDirectionEnum = typeof GetPurchaseIntentInputOrderDirectionEnum[keyof typeof GetPurchaseIntentInputOrderDirectionEnum];
export const GetPurchaseIntentInputPaymentProviderEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type GetPurchaseIntentInputPaymentProviderEnum = typeof GetPurchaseIntentInputPaymentProviderEnum[keyof typeof GetPurchaseIntentInputPaymentProviderEnum];
export const GetPurchaseIntentInputStatusEnum = {
    Pending: 'PENDING',
    Unresolved: 'UNRESOLVED',
    Cancelled: 'CANCELLED',
    Confirmed: 'CONFIRMED',
    Expired: 'EXPIRED'
} as const;

export type GetPurchaseIntentInputStatusEnum = typeof GetPurchaseIntentInputStatusEnum[keyof typeof GetPurchaseIntentInputStatusEnum];

/**
 * 
 * @export
 * @interface GetPurchaseIntentItemsInput
 */
export interface GetPurchaseIntentItemsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetPurchaseIntentItemsInput
     */
    'cursor'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPurchaseIntentItemsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseIntentItemsInput
     */
    'order_direction'?: GetPurchaseIntentItemsInputOrderDirectionEnum;
}

export const GetPurchaseIntentItemsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPurchaseIntentItemsInputOrderDirectionEnum = typeof GetPurchaseIntentItemsInputOrderDirectionEnum[keyof typeof GetPurchaseIntentItemsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetSnapshotsHoldersCsvInput
 */
export interface GetSnapshotsHoldersCsvInput {
    /**
     * 
     * @type {string}
     * @memberof GetSnapshotsHoldersCsvInput
     */
    'output_type'?: GetSnapshotsHoldersCsvInputOutputTypeEnum;
}

export const GetSnapshotsHoldersCsvInputOutputTypeEnum = {
    Address: 'PER_ADDRESS',
    Token: 'PER_TOKEN'
} as const;

export type GetSnapshotsHoldersCsvInputOutputTypeEnum = typeof GetSnapshotsHoldersCsvInputOutputTypeEnum[keyof typeof GetSnapshotsHoldersCsvInputOutputTypeEnum];

/**
 * 
 * @export
 * @interface GetSnapshotsHoldersInput
 */
export interface GetSnapshotsHoldersInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetSnapshotsHoldersInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSnapshotsHoldersInput
     */
    'holder'?: Array<string> | null;
    /**
     * LargePaginationLimit
     * @type {number}
     * @memberof GetSnapshotsHoldersInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSnapshotsHoldersInput
     */
    'order_direction'?: GetSnapshotsHoldersInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSnapshotsHoldersInput
     */
    'output_type'?: GetSnapshotsHoldersInputOutputTypeEnum;
}

export const GetSnapshotsHoldersInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetSnapshotsHoldersInputOrderDirectionEnum = typeof GetSnapshotsHoldersInputOrderDirectionEnum[keyof typeof GetSnapshotsHoldersInputOrderDirectionEnum];
export const GetSnapshotsHoldersInputOutputTypeEnum = {
    Address: 'PER_ADDRESS',
    Token: 'PER_TOKEN'
} as const;

export type GetSnapshotsHoldersInputOutputTypeEnum = typeof GetSnapshotsHoldersInputOutputTypeEnum[keyof typeof GetSnapshotsHoldersInputOutputTypeEnum];

/**
 * 
 * @export
 * @interface GetSnapshotsInput
 */
export interface GetSnapshotsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetSnapshotsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetSnapshotsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSnapshotsInput
     */
    'order_direction'?: GetSnapshotsInputOrderDirectionEnum;
}

export const GetSnapshotsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetSnapshotsInputOrderDirectionEnum = typeof GetSnapshotsInputOrderDirectionEnum[keyof typeof GetSnapshotsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetWebhooksInput
 */
export interface GetWebhooksInput {
    /**
     * SafeString
     * @type {string}
     * @memberof GetWebhooksInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof GetWebhooksInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWebhooksInput
     */
    'order_direction'?: GetWebhooksInputOrderDirectionEnum;
}

export const GetWebhooksInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetWebhooksInputOrderDirectionEnum = typeof GetWebhooksInputOrderDirectionEnum[keyof typeof GetWebhooksInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface InputListingContext
 */
export interface InputListingContext {
    /**
     * 
     * @type {string}
     * @memberof InputListingContext
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputListingContext
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputListingContext
     */
    'currency': InputListingContextCurrencyEnum;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof InputListingContext
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputListingContext
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputListingContext
     */
    'item_ids'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof InputListingContext
     */
    'max_quantity_per_tx': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputListingContext
     */
    'payment_providers': Array<InputListingContextPaymentProvidersEnum>;
    /**
     * 
     * @type {InputListingContextPolicy}
     * @memberof InputListingContext
     */
    'policy': InputListingContextPolicy;
    /**
     * Uint256
     * @type {string}
     * @memberof InputListingContext
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof InputListingContext
     */
    'quantity_listed'?: number | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InputListingContext
     */
    'settlement_currency'?: { [key: string]: string; };
    /**
     * UtcDateTime
     * @type {string}
     * @memberof InputListingContext
     */
    'start_time'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputListingContext
     */
    'token_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<InputListingContextTokenRangesInner>}
     * @memberof InputListingContext
     */
    'token_ranges'?: Array<InputListingContextTokenRangesInner> | null;
}

export const InputListingContextCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type InputListingContextCurrencyEnum = typeof InputListingContextCurrencyEnum[keyof typeof InputListingContextCurrencyEnum];
export const InputListingContextPaymentProvidersEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type InputListingContextPaymentProvidersEnum = typeof InputListingContextPaymentProvidersEnum[keyof typeof InputListingContextPaymentProvidersEnum];
export const InputListingContextSettlementCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type InputListingContextSettlementCurrencyEnum = typeof InputListingContextSettlementCurrencyEnum[keyof typeof InputListingContextSettlementCurrencyEnum];

/**
 * 
 * @export
 * @interface InputListingContextPolicy
 */
export interface InputListingContextPolicy {
    /**
     * 
     * @type {Array<string>}
     * @memberof InputListingContextPolicy
     */
    'email_addresses'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof InputListingContextPolicy
     */
    'email_claim_duration'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputListingContextPolicy
     */
    'eth_addresses'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InputListingContextPolicy
     */
    'item_assignment_strategy'?: InputListingContextPolicyItemAssignmentStrategyEnum;
    /**
     * 
     * @type {number}
     * @memberof InputListingContextPolicy
     */
    'max_per_user'?: number | null;
    /**
     * 
     * @type {EditListingInputPolicyPaymentSessionDuration}
     * @memberof InputListingContextPolicy
     */
    'payment_session_duration'?: EditListingInputPolicyPaymentSessionDuration | null;
    /**
     * 
     * @type {string}
     * @memberof InputListingContextPolicy
     */
    'snapshot_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputListingContextPolicy
     */
    'tx_payer'?: InputListingContextPolicyTxPayerEnum;
    /**
     * 
     * @type {string}
     * @memberof InputListingContextPolicy
     */
    'type'?: InputListingContextPolicyTypeEnum;
}

export const InputListingContextPolicyItemAssignmentStrategyEnum = {
    Automatic: 'AUTOMATIC',
    Random: 'RANDOM'
} as const;

export type InputListingContextPolicyItemAssignmentStrategyEnum = typeof InputListingContextPolicyItemAssignmentStrategyEnum[keyof typeof InputListingContextPolicyItemAssignmentStrategyEnum];
export const InputListingContextPolicyTxPayerEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER'
} as const;

export type InputListingContextPolicyTxPayerEnum = typeof InputListingContextPolicyTxPayerEnum[keyof typeof InputListingContextPolicyTxPayerEnum];
export const InputListingContextPolicyTypeEnum = {
    AllowList: 'ALLOW_LIST',
    Fcfs: 'FCFS',
    LargeAllowList: 'LARGE_ALLOW_LIST'
} as const;

export type InputListingContextPolicyTypeEnum = typeof InputListingContextPolicyTypeEnum[keyof typeof InputListingContextPolicyTypeEnum];

/**
 * 
 * @export
 * @interface InputListingContextTokenRangesInner
 */
export interface InputListingContextTokenRangesInner {
    /**
     * Uint256
     * @type {string}
     * @memberof InputListingContextTokenRangesInner
     */
    'from_id': string;
    /**
     * Uint256
     * @type {string}
     * @memberof InputListingContextTokenRangesInner
     */
    'to_id': string;
}
/**
 * 
 * @export
 * @interface InputSnapshotContext
 */
export interface InputSnapshotContext {
    /**
     * 
     * @type {string}
     * @memberof InputSnapshotContext
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof InputSnapshotContext
     */
    'contract_address'?: string | null;
    /**
     * 
     * @type {InputSnapshotContextCriteria}
     * @memberof InputSnapshotContext
     */
    'criteria': InputSnapshotContextCriteria;
    /**
     * SafeString
     * @type {string}
     * @memberof InputSnapshotContext
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof InputSnapshotContext
     */
    'network_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InputSnapshotContext
     */
    'token_type'?: InputSnapshotContextTokenTypeEnum;
}

export const InputSnapshotContextTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type InputSnapshotContextTokenTypeEnum = typeof InputSnapshotContextTokenTypeEnum[keyof typeof InputSnapshotContextTokenTypeEnum];

/**
 * 
 * @export
 * @interface InputSnapshotContextCriteria
 */
export interface InputSnapshotContextCriteria {
    /**
     * Uint256
     * @type {string}
     * @memberof InputSnapshotContextCriteria
     */
    'block_number'?: string | null;
    /**
     * Uint256
     * @type {string}
     * @memberof InputSnapshotContextCriteria
     */
    'max_tokens'?: string | null;
    /**
     * Uint256
     * @type {string}
     * @memberof InputSnapshotContextCriteria
     */
    'min_tokens'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof InputSnapshotContextCriteria
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputSnapshotContextCriteria
     */
    'token_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<InputListingContextTokenRangesInner>}
     * @memberof InputSnapshotContextCriteria
     */
    'token_ranges'?: Array<InputListingContextTokenRangesInner> | null;
}
/**
 * 
 * @export
 * @interface InviteUserInput
 */
export interface InviteUserInput {
    /**
     * 
     * @type {string}
     * @memberof InviteUserInput
     */
    'application_type'?: InviteUserInputApplicationTypeEnum;
    /**
     * Email
     * @type {string}
     * @memberof InviteUserInput
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInput
     */
    'organization_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInput
     */
    'role_id': string;
}

export const InviteUserInputApplicationTypeEnum = {
    DevUi: 'DEV_UI',
    Portal: 'PORTAL',
    Crumb: 'CRUMB',
    Platform: 'PLATFORM'
} as const;

export type InviteUserInputApplicationTypeEnum = typeof InviteUserInputApplicationTypeEnum[keyof typeof InviteUserInputApplicationTypeEnum];

/**
 * 
 * @export
 * @interface ItemOutput
 */
export interface ItemOutput {
    /**
     * 
     * @type {object}
     * @memberof ItemOutput
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof ItemOutput
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof ItemOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ItemOutput
     */
    'item_type_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ItemOutput
     */
    'locked': boolean;
    /**
     * Uint256
     * @type {string}
     * @memberof ItemOutput
     */
    'max_supply'?: string | null;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerMedia}
     * @memberof ItemOutput
     */
    'media': BulkUpdateItemOutputResultsInnerMedia;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof ItemOutput
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
    /**
     * Uint256
     * @type {string}
     * @memberof ItemOutput
     */
    'token_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ItemOutput
     */
    'token_status': ItemOutputTokenStatusEnum;
}

export const ItemOutputTokenStatusEnum = {
    Minted: 'MINTED',
    NotMinted: 'NOT_MINTED'
} as const;

export type ItemOutputTokenStatusEnum = typeof ItemOutputTokenStatusEnum[keyof typeof ItemOutputTokenStatusEnum];

/**
 * 
 * @export
 * @interface ItemStatusOutput
 */
export interface ItemStatusOutput {
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemStatusOutput
     */
    'email_claim_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemStatusOutput
     */
    'listing_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemStatusOutput
     */
    'mint_request_ids'?: Array<string>;
    /**
     * 
     * @type {ItemStatusOutputReservations}
     * @memberof ItemStatusOutput
     */
    'reservations': ItemStatusOutputReservations;
    /**
     * 
     * @type {ItemStatusOutputSupply}
     * @memberof ItemStatusOutput
     */
    'supply': ItemStatusOutputSupply;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemStatusOutput
     */
    'transfer_request_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ItemStatusOutputReservations
 */
export interface ItemStatusOutputReservations {
    /**
     * 
     * @type {number}
     * @memberof ItemStatusOutputReservations
     */
    'mint': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStatusOutputReservations
     */
    'transfer': number;
}
/**
 * 
 * @export
 * @interface ItemStatusOutputSupply
 */
export interface ItemStatusOutputSupply {
    /**
     * Uint256
     * @type {string}
     * @memberof ItemStatusOutputSupply
     */
    'current': string;
    /**
     * Uint256
     * @type {string}
     * @memberof ItemStatusOutputSupply
     */
    'total': string;
}
/**
 * 
 * @export
 * @interface ItemTypeDomainModel
 */
export interface ItemTypeDomainModel {
    /**
     * 
     * @type {string}
     * @memberof ItemTypeDomainModel
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof ItemTypeDomainModel
     */
    'json_schema': object;
    /**
     * 
     * @type {string}
     * @memberof ItemTypeDomainModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemTypeDomainModel
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface ItemTypeMetaSchema
 */
export interface ItemTypeMetaSchema {
    /**
     * 
     * @type {object}
     * @memberof ItemTypeMetaSchema
     */
    'example_json_schema': object;
    /**
     * 
     * @type {object}
     * @memberof ItemTypeMetaSchema
     */
    'meta_json_schema': object;
}
/**
 * 
 * @export
 * @interface ItemTypesPaginatedResult
 */
export interface ItemTypesPaginatedResult {
    /**
     * 
     * @type {string}
     * @memberof ItemTypesPaginatedResult
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ItemTypesPaginatedResult
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<ItemTypesPaginatedResultResultsInner>}
     * @memberof ItemTypesPaginatedResult
     */
    'results': Array<ItemTypesPaginatedResultResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof ItemTypesPaginatedResult
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface ItemTypesPaginatedResultResultsInner
 */
export interface ItemTypesPaginatedResultResultsInner {
    /**
     * 
     * @type {string}
     * @memberof ItemTypesPaginatedResultResultsInner
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof ItemTypesPaginatedResultResultsInner
     */
    'json_schema': object;
    /**
     * 
     * @type {string}
     * @memberof ItemTypesPaginatedResultResultsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemTypesPaginatedResultResultsInner
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface ItemsPaginatedOutput
 */
export interface ItemsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof ItemsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<BulkUpdateItemOutputResultsInner>}
     * @memberof ItemsPaginatedOutput
     */
    'results': Array<BulkUpdateItemOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof ItemsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface ListingOutput
 */
export interface ListingOutput {
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'currency': ListingOutputCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListingOutput
     */
    'max_quantity_per_tx': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListingOutput
     */
    'payment_providers': Array<ListingOutputPaymentProvidersEnum>;
    /**
     * 
     * @type {object}
     * @memberof ListingOutput
     */
    'policy': object;
    /**
     * Uint256
     * @type {string}
     * @memberof ListingOutput
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof ListingOutput
     */
    'quantity_listed': number;
    /**
     * 
     * @type {number}
     * @memberof ListingOutput
     */
    'quantity_remaining': number;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'sale_type': ListingOutputSaleTypeEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListingOutput
     */
    'settlement_currency'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'start_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingOutput
     */
    'status': ListingOutputStatusEnum;
}

export const ListingOutputCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type ListingOutputCurrencyEnum = typeof ListingOutputCurrencyEnum[keyof typeof ListingOutputCurrencyEnum];
export const ListingOutputPaymentProvidersEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type ListingOutputPaymentProvidersEnum = typeof ListingOutputPaymentProvidersEnum[keyof typeof ListingOutputPaymentProvidersEnum];
export const ListingOutputSaleTypeEnum = {
    SetPrice: 'SET_PRICE',
    OnchainEnglishAuction: 'ONCHAIN_ENGLISH_AUCTION',
    FiatSetPrice: 'FIAT_SET_PRICE',
    BuyerMint: 'BUYER_MINT',
    FiatLazyMint: 'FIAT_LAZY_MINT',
    Default: 'DEFAULT'
} as const;

export type ListingOutputSaleTypeEnum = typeof ListingOutputSaleTypeEnum[keyof typeof ListingOutputSaleTypeEnum];
export const ListingOutputSettlementCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type ListingOutputSettlementCurrencyEnum = typeof ListingOutputSettlementCurrencyEnum[keyof typeof ListingOutputSettlementCurrencyEnum];
export const ListingOutputStatusEnum = {
    Active: 'ACTIVE',
    Cancelled: 'CANCELLED',
    Complete: 'COMPLETE',
    PendingTx: 'PENDING_TX'
} as const;

export type ListingOutputStatusEnum = typeof ListingOutputStatusEnum[keyof typeof ListingOutputStatusEnum];

/**
 * 
 * @export
 * @interface ListingsPaginatedOutput
 */
export interface ListingsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListingsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<ListingsPaginatedOutputResultsInner>}
     * @memberof ListingsPaginatedOutput
     */
    'results': Array<ListingsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListingsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface ListingsPaginatedOutputResultsInner
 */
export interface ListingsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'currency': ListingsPaginatedOutputResultsInnerCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'max_quantity_per_tx': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'payment_providers': Array<ListingsPaginatedOutputResultsInnerPaymentProvidersEnum>;
    /**
     * 
     * @type {object}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'policy': object;
    /**
     * Uint256
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'quantity_listed': number;
    /**
     * 
     * @type {number}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'quantity_remaining': number;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'sale_type': ListingsPaginatedOutputResultsInnerSaleTypeEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'settlement_currency'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'start_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingsPaginatedOutputResultsInner
     */
    'status': ListingsPaginatedOutputResultsInnerStatusEnum;
}

export const ListingsPaginatedOutputResultsInnerCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type ListingsPaginatedOutputResultsInnerCurrencyEnum = typeof ListingsPaginatedOutputResultsInnerCurrencyEnum[keyof typeof ListingsPaginatedOutputResultsInnerCurrencyEnum];
export const ListingsPaginatedOutputResultsInnerPaymentProvidersEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type ListingsPaginatedOutputResultsInnerPaymentProvidersEnum = typeof ListingsPaginatedOutputResultsInnerPaymentProvidersEnum[keyof typeof ListingsPaginatedOutputResultsInnerPaymentProvidersEnum];
export const ListingsPaginatedOutputResultsInnerSaleTypeEnum = {
    SetPrice: 'SET_PRICE',
    OnchainEnglishAuction: 'ONCHAIN_ENGLISH_AUCTION',
    FiatSetPrice: 'FIAT_SET_PRICE',
    BuyerMint: 'BUYER_MINT',
    FiatLazyMint: 'FIAT_LAZY_MINT',
    Default: 'DEFAULT'
} as const;

export type ListingsPaginatedOutputResultsInnerSaleTypeEnum = typeof ListingsPaginatedOutputResultsInnerSaleTypeEnum[keyof typeof ListingsPaginatedOutputResultsInnerSaleTypeEnum];
export const ListingsPaginatedOutputResultsInnerSettlementCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type ListingsPaginatedOutputResultsInnerSettlementCurrencyEnum = typeof ListingsPaginatedOutputResultsInnerSettlementCurrencyEnum[keyof typeof ListingsPaginatedOutputResultsInnerSettlementCurrencyEnum];
export const ListingsPaginatedOutputResultsInnerStatusEnum = {
    Active: 'ACTIVE',
    Cancelled: 'CANCELLED',
    Complete: 'COMPLETE',
    PendingTx: 'PENDING_TX'
} as const;

export type ListingsPaginatedOutputResultsInnerStatusEnum = typeof ListingsPaginatedOutputResultsInnerStatusEnum[keyof typeof ListingsPaginatedOutputResultsInnerStatusEnum];

/**
 * 
 * @export
 * @interface LockItemInput
 */
export interface LockItemInput {
    /**
     * 
     * @type {string}
     * @memberof LockItemInput
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LockItemInput
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LockItemInput
     */
    'item_ids'?: Array<string> | null;
    /**
     * Uint256
     * @type {string}
     * @memberof LockItemInput
     */
    'max_supply'?: string | null;
    /**
     * Uint256
     * @type {string}
     * @memberof LockItemInput
     */
    'token_id'?: string | null;
}
/**
 * 
 * @export
 * @interface LockItemOutputEnvelope
 */
export interface LockItemOutputEnvelope {
    /**
     * 
     * @type {Array<LockItemOutputEnvelopeLockOutputsInner>}
     * @memberof LockItemOutputEnvelope
     */
    'lock_outputs': Array<LockItemOutputEnvelopeLockOutputsInner>;
}
/**
 * 
 * @export
 * @interface LockItemOutputEnvelopeLockOutputsInner
 */
export interface LockItemOutputEnvelopeLockOutputsInner {
    /**
     * 
     * @type {string}
     * @memberof LockItemOutputEnvelopeLockOutputsInner
     */
    'item_id': string;
    /**
     * Uint256
     * @type {string}
     * @memberof LockItemOutputEnvelopeLockOutputsInner
     */
    'max_supply'?: string | null;
    /**
     * Uint256
     * @type {string}
     * @memberof LockItemOutputEnvelopeLockOutputsInner
     */
    'token_id': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'access_token': string;
    /**
     * 
     * @type {CurrentUserOutputUser}
     * @memberof LoginResponse
     */
    'user': CurrentUserOutputUser;
}
/**
 * 
 * @export
 * @interface MarketingCampaignOutput
 */
export interface MarketingCampaignOutput {
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignOutput
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignOutput
     */
    'email_from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignOutput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MarketingCampaignsPaginatedOutput
 */
export interface MarketingCampaignsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MarketingCampaignsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<MarketingCampaignsPaginatedOutputResultsInner>}
     * @memberof MarketingCampaignsPaginatedOutput
     */
    'results': Array<MarketingCampaignsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface MarketingCampaignsPaginatedOutputResultsInner
 */
export interface MarketingCampaignsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignsPaginatedOutputResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignsPaginatedOutputResultsInner
     */
    'email_from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignsPaginatedOutputResultsInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MintRequestInput
 */
export interface MintRequestInput {
    /**
     * 
     * @type {string}
     * @memberof MintRequestInput
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MintRequestInput
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MintRequestInput
     */
    'item_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MintRequestInput
     */
    'quantities'?: Array<string> | null;
    /**
     * Uint256
     * @type {string}
     * @memberof MintRequestInput
     */
    'quantity'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof MintRequestInput
     */
    'to_address'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MintRequestInput
     */
    'to_addresses'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MintRequestInput
     */
    'token_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<InputListingContextTokenRangesInner>}
     * @memberof MintRequestInput
     */
    'token_ranges'?: Array<InputListingContextTokenRangesInner> | null;
}
/**
 * 
 * @export
 * @interface MintRequestOutput
 */
export interface MintRequestOutput {
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutput
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutput
     */
    'item_id': string;
    /**
     * Uint256
     * @type {string}
     * @memberof MintRequestOutput
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutput
     */
    'state': MintRequestOutputStateEnum;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof MintRequestOutput
     */
    'to_address': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutput
     */
    'transaction_id': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutput
     */
    'tx_hash'?: string | null;
}

export const MintRequestOutputStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type MintRequestOutputStateEnum = typeof MintRequestOutputStateEnum[keyof typeof MintRequestOutputStateEnum];

/**
 * 
 * @export
 * @interface MintRequestOutputEnvelope
 */
export interface MintRequestOutputEnvelope {
    /**
     * 
     * @type {Array<MintRequestOutputEnvelopeMintRequestsInner>}
     * @memberof MintRequestOutputEnvelope
     */
    'mint_requests': Array<MintRequestOutputEnvelopeMintRequestsInner>;
}
/**
 * 
 * @export
 * @interface MintRequestOutputEnvelopeMintRequestsInner
 */
export interface MintRequestOutputEnvelopeMintRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'item_id': string;
    /**
     * Uint256
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'state': MintRequestOutputEnvelopeMintRequestsInnerStateEnum;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'to_address': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'transaction_id': string;
    /**
     * 
     * @type {string}
     * @memberof MintRequestOutputEnvelopeMintRequestsInner
     */
    'tx_hash'?: string | null;
}

export const MintRequestOutputEnvelopeMintRequestsInnerStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type MintRequestOutputEnvelopeMintRequestsInnerStateEnum = typeof MintRequestOutputEnvelopeMintRequestsInnerStateEnum[keyof typeof MintRequestOutputEnvelopeMintRequestsInnerStateEnum];

/**
 * 
 * @export
 * @interface MintRequestsPaginatedOutput
 */
export interface MintRequestsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof MintRequestsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MintRequestsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<MintRequestOutputEnvelopeMintRequestsInner>}
     * @memberof MintRequestsPaginatedOutput
     */
    'results': Array<MintRequestOutputEnvelopeMintRequestsInner>;
    /**
     * 
     * @type {number}
     * @memberof MintRequestsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {NetworkConfig}
     * @memberof Network
     */
    'config': NetworkConfig;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    'gas_policy_id'?: string | null;
    /**
     * Uint
     * @type {number}
     * @memberof Network
     */
    'id': number;
    /**
     * SafeString
     * @type {string}
     * @memberof Network
     */
    'name': string;
    /**
     * 
     * @type {Array<NetworkPaymentTokensInner>}
     * @memberof Network
     */
    'payment_tokens'?: Array<NetworkPaymentTokensInner> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    'testnet': boolean;
}
/**
 * 
 * @export
 * @interface NetworkBalancePaginatedOutput
 */
export interface NetworkBalancePaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof NetworkBalancePaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkBalancePaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<NetworkBalancePaginatedOutputResultsInner>}
     * @memberof NetworkBalancePaginatedOutput
     */
    'results': Array<NetworkBalancePaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof NetworkBalancePaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface NetworkBalancePaginatedOutputResultsInner
 */
export interface NetworkBalancePaginatedOutputResultsInner {
    /**
     * Uint256
     * @type {string}
     * @memberof NetworkBalancePaginatedOutputResultsInner
     */
    'balance': string;
    /**
     * 
     * @type {number}
     * @memberof NetworkBalancePaginatedOutputResultsInner
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface NetworkConfig
 */
export interface NetworkConfig {
    /**
     * 
     * @type {boolean}
     * @memberof NetworkConfig
     */
    'autoDeploy': boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof NetworkConfig
     */
    'autoFundAmount': string;
    /**
     * Uint
     * @type {number}
     * @memberof NetworkConfig
     */
    'confirmations': number;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkConfig
     */
    'eip155': boolean;
    /**
     * Uint
     * @type {number}
     * @memberof NetworkConfig
     */
    'fee_limit_gwei'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof NetworkConfig
     */
    'gasStrategy': object;
    /**
     * Uint
     * @type {number}
     * @memberof NetworkConfig
     */
    'maxConcurrentTxs': number;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkConfig
     */
    'poa': boolean;
    /**
     * Uint
     * @type {number}
     * @memberof NetworkConfig
     */
    'txResubmitBlockCount'?: number | null;
    /**
     * SafeString
     * @type {string}
     * @memberof NetworkConfig
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface NetworkPaymentToken
 */
export interface NetworkPaymentToken {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof NetworkPaymentToken
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof NetworkPaymentToken
     */
    'decimals': number;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkPaymentToken
     */
    'eth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkPaymentToken
     */
    'eur_stablecoin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NetworkPaymentToken
     */
    'network_id': number;
    /**
     * 
     * @type {string}
     * @memberof NetworkPaymentToken
     */
    'symbol': string;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkPaymentToken
     */
    'usd_stablecoin'?: boolean;
}
/**
 * 
 * @export
 * @interface NetworkPaymentTokensInner
 */
export interface NetworkPaymentTokensInner {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof NetworkPaymentTokensInner
     */
    'address': string;
    /**
     * Uint
     * @type {number}
     * @memberof NetworkPaymentTokensInner
     */
    'decimals': number;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkPaymentTokensInner
     */
    'eth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkPaymentTokensInner
     */
    'eur_stablecoin'?: boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof NetworkPaymentTokensInner
     */
    'symbol': string;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkPaymentTokensInner
     */
    'usd_stablecoin'?: boolean;
}
/**
 * 
 * @export
 * @interface NetworkPaymentTokensPaginatedInput
 */
export interface NetworkPaymentTokensPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof NetworkPaymentTokensPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof NetworkPaymentTokensPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof NetworkPaymentTokensPaginatedInput
     */
    'order_direction'?: NetworkPaymentTokensPaginatedInputOrderDirectionEnum;
}

export const NetworkPaymentTokensPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type NetworkPaymentTokensPaginatedInputOrderDirectionEnum = typeof NetworkPaymentTokensPaginatedInputOrderDirectionEnum[keyof typeof NetworkPaymentTokensPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface NetworksPaginatedOutput
 */
export interface NetworksPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof NetworksPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NetworksPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<NetworksPaginatedOutputResultsInner>}
     * @memberof NetworksPaginatedOutput
     */
    'results': Array<NetworksPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof NetworksPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface NetworksPaginatedOutputResultsInner
 */
export interface NetworksPaginatedOutputResultsInner {
    /**
     * 
     * @type {NetworkConfig}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'config': NetworkConfig;
    /**
     * 
     * @type {boolean}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'gas_policy_id'?: string | null;
    /**
     * Uint
     * @type {number}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'id': number;
    /**
     * SafeString
     * @type {string}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {Array<NetworkPaymentTokensInner>}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'payment_tokens'?: Array<NetworkPaymentTokensInner> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NetworksPaginatedOutputResultsInner
     */
    'testnet': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationEmailTemplateDomainModel
 */
export interface OrganizationEmailTemplateDomainModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailTemplateDomainModel
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailTemplateDomainModel
     */
    'email_from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailTemplateDomainModel
     */
    'html': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailTemplateDomainModel
     */
    'origin_type': OrganizationEmailTemplateDomainModelOriginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailTemplateDomainModel
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailTemplateDomainModel
     */
    'template_type': OrganizationEmailTemplateDomainModelTemplateTypeEnum;
}

export const OrganizationEmailTemplateDomainModelOriginTypeEnum = {
    PlatformDefault: 'PLATFORM_DEFAULT',
    OrganizationCustom: 'ORGANIZATION_CUSTOM'
} as const;

export type OrganizationEmailTemplateDomainModelOriginTypeEnum = typeof OrganizationEmailTemplateDomainModelOriginTypeEnum[keyof typeof OrganizationEmailTemplateDomainModelOriginTypeEnum];
export const OrganizationEmailTemplateDomainModelTemplateTypeEnum = {
    UserInvite: 'USER_INVITE',
    Welcome: 'WELCOME',
    InsufficientBalance: 'INSUFFICIENT_BALANCE',
    ForgotPasswordReset: 'FORGOT_PASSWORD_RESET',
    RedeemNft: 'REDEEM_NFT',
    ClaimPincode: 'CLAIM_PINCODE',
    ClaimedNft: 'CLAIMED_NFT',
    UserTest: 'USER_TEST',
    Custom: 'CUSTOM'
} as const;

export type OrganizationEmailTemplateDomainModelTemplateTypeEnum = typeof OrganizationEmailTemplateDomainModelTemplateTypeEnum[keyof typeof OrganizationEmailTemplateDomainModelTemplateTypeEnum];

/**
 * 
 * @export
 * @interface OrganizationInvitationOutput
 */
export interface OrganizationInvitationOutput {
    /**
     * UtcDateTime
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'email'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'organization_name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'role_id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'token': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationInvitationOutput
     */
    'verified': boolean;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof OrganizationInvitationOutput
     */
    'verified_at': string;
}
/**
 * 
 * @export
 * @interface OrganizationOutput
 */
export interface OrganizationOutput {
    /**
     * SafeString
     * @type {string}
     * @memberof OrganizationOutput
     */
    'auth0_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOutput
     */
    'billing_enabled': boolean;
    /**
     * 
     * @type {CurrentUserOutputOrganizationConfig}
     * @memberof OrganizationOutput
     */
    'config'?: CurrentUserOutputOrganizationConfig | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOutput
     */
    'default_payment_method'?: OrganizationOutputDefaultPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOutput
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOutput
     */
    'is_enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOutput
     */
    'mfa_required': boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof OrganizationOutput
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganizationOutput
     */
    'network_ids': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationOutput
     */
    'stripe_connect_types': Array<OrganizationOutputStripeConnectTypesEnum>;
}

export const OrganizationOutputDefaultPaymentMethodEnum = {
    Crypto: 'CRYPTO',
    Fiat: 'FIAT',
    Credit: 'CREDIT'
} as const;

export type OrganizationOutputDefaultPaymentMethodEnum = typeof OrganizationOutputDefaultPaymentMethodEnum[keyof typeof OrganizationOutputDefaultPaymentMethodEnum];
export const OrganizationOutputStripeConnectTypesEnum = {
    Standard: 'STANDARD',
    Express: 'EXPRESS',
    Custom: 'CUSTOM'
} as const;

export type OrganizationOutputStripeConnectTypesEnum = typeof OrganizationOutputStripeConnectTypesEnum[keyof typeof OrganizationOutputStripeConnectTypesEnum];

/**
 * 
 * @export
 * @interface OrganizationPayoutConfigurationOutput
 */
export interface OrganizationPayoutConfigurationOutput {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof OrganizationPayoutConfigurationOutput
     */
    'payout_address': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationPayoutConfigurationOutput
     */
    'platform_sale_fee': number;
}
/**
 * 
 * @export
 * @interface OrganizationWallet
 */
export interface OrganizationWallet {
    /**
     * 
     * @type {string}
     * @memberof OrganizationWallet
     */
    'address': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationWallet
     */
    'auto_fund': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWallet
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationWallet
     */
    'is_default': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWallet
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrganizationsPaginatedOutput
 */
export interface OrganizationsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<CurrentUserOutputOrganization>}
     * @memberof OrganizationsPaginatedOutput
     */
    'results': Array<CurrentUserOutputOrganization>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PaginatedPlatformStripeAccountsOutput
 */
export interface PaginatedPlatformStripeAccountsOutput {
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlatformStripeAccountsOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedPlatformStripeAccountsOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PaginatedPlatformStripeAccountsOutputResultsInner>}
     * @memberof PaginatedPlatformStripeAccountsOutput
     */
    'results': Array<PaginatedPlatformStripeAccountsOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPlatformStripeAccountsOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PaginatedPlatformStripeAccountsOutputResultsInner
 */
export interface PaginatedPlatformStripeAccountsOutputResultsInner {
    /**
     * 
     * @type {object}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'config'?: object | null;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'fee_percentage_bps'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'mode': PaginatedPlatformStripeAccountsOutputResultsInnerModeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'publishable_key': string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'session_timeout_minutes': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlatformStripeAccountsOutputResultsInner
     */
    'stripe_account_id': string;
}

export const PaginatedPlatformStripeAccountsOutputResultsInnerModeEnum = {
    Live: 'LIVE',
    Test: 'TEST'
} as const;

export type PaginatedPlatformStripeAccountsOutputResultsInnerModeEnum = typeof PaginatedPlatformStripeAccountsOutputResultsInnerModeEnum[keyof typeof PaginatedPlatformStripeAccountsOutputResultsInnerModeEnum];

/**
 * 
 * @export
 * @interface PaginatedPublicPlatformStripeAccountsOutput
 */
export interface PaginatedPublicPlatformStripeAccountsOutput {
    /**
     * 
     * @type {string}
     * @memberof PaginatedPublicPlatformStripeAccountsOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedPublicPlatformStripeAccountsOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PaginatedPublicPlatformStripeAccountsOutputResultsInner>}
     * @memberof PaginatedPublicPlatformStripeAccountsOutput
     */
    'results': Array<PaginatedPublicPlatformStripeAccountsOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPublicPlatformStripeAccountsOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PaginatedPublicPlatformStripeAccountsOutputResultsInner
 */
export interface PaginatedPublicPlatformStripeAccountsOutputResultsInner {
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedPublicPlatformStripeAccountsOutputResultsInner
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPublicPlatformStripeAccountsOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPublicPlatformStripeAccountsOutputResultsInner
     */
    'mode': PaginatedPublicPlatformStripeAccountsOutputResultsInnerModeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPublicPlatformStripeAccountsOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPublicPlatformStripeAccountsOutputResultsInner
     */
    'publishable_key': string;
}

export const PaginatedPublicPlatformStripeAccountsOutputResultsInnerModeEnum = {
    Live: 'LIVE',
    Test: 'TEST'
} as const;

export type PaginatedPublicPlatformStripeAccountsOutputResultsInnerModeEnum = typeof PaginatedPublicPlatformStripeAccountsOutputResultsInnerModeEnum[keyof typeof PaginatedPublicPlatformStripeAccountsOutputResultsInnerModeEnum];

/**
 * 
 * @export
 * @interface PinCollectionOutput
 */
export interface PinCollectionOutput {
    /**
     * 
     * @type {boolean}
     * @memberof PinCollectionOutput
     */
    'queued': boolean;
}
/**
 * 
 * @export
 * @interface PlatformStripeAccountInput
 */
export interface PlatformStripeAccountInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'account_webhook_endpoint_secret'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof PlatformStripeAccountInput
     */
    'config'?: object | null;
    /**
     * SafeString
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'connect_webhook_endpoint_secret'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlatformStripeAccountInput
     */
    'fee_percentage_bps'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'mode': PlatformStripeAccountInputModeEnum;
    /**
     * SafeString
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'name': string;
    /**
     * SafeString
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'publishable_key': string;
    /**
     * SafeString
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'secret_key': string;
    /**
     * 
     * @type {number}
     * @memberof PlatformStripeAccountInput
     */
    'session_timeout_minutes': number;
    /**
     * SafeString
     * @type {string}
     * @memberof PlatformStripeAccountInput
     */
    'stripe_account_id': string;
}

export const PlatformStripeAccountInputModeEnum = {
    Live: 'LIVE',
    Test: 'TEST'
} as const;

export type PlatformStripeAccountInputModeEnum = typeof PlatformStripeAccountInputModeEnum[keyof typeof PlatformStripeAccountInputModeEnum];

/**
 * 
 * @export
 * @interface PlatformStripeAccountOutput
 */
export interface PlatformStripeAccountOutput {
    /**
     * 
     * @type {object}
     * @memberof PlatformStripeAccountOutput
     */
    'config'?: object | null;
    /**
     * 
     * @type {number}
     * @memberof PlatformStripeAccountOutput
     */
    'fee_percentage_bps'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformStripeAccountOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformStripeAccountOutput
     */
    'mode': PlatformStripeAccountOutputModeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlatformStripeAccountOutput
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformStripeAccountOutput
     */
    'publishable_key': string;
    /**
     * 
     * @type {number}
     * @memberof PlatformStripeAccountOutput
     */
    'session_timeout_minutes': number;
    /**
     * 
     * @type {string}
     * @memberof PlatformStripeAccountOutput
     */
    'stripe_account_id': string;
}

export const PlatformStripeAccountOutputModeEnum = {
    Live: 'LIVE',
    Test: 'TEST'
} as const;

export type PlatformStripeAccountOutputModeEnum = typeof PlatformStripeAccountOutputModeEnum[keyof typeof PlatformStripeAccountOutputModeEnum];

/**
 * 
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedOutput
 */
export interface PublicNetworkPaymentTokensPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicNetworkPaymentTokensPaginatedOutputResultsInner>}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'results': Array<PublicNetworkPaymentTokensPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedOutputResultsInner
 */
export interface PublicNetworkPaymentTokensPaginatedOutputResultsInner {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'decimals': number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'eth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'eur_stablecoin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'symbol': string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'usd_stablecoin'?: boolean;
}
/**
 * 
 * @export
 * @interface PublicOrganizationsPaginatedOutput
 */
export interface PublicOrganizationsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicOrganizationsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicOrganizationsPaginatedOutputResultsInner>}
     * @memberof PublicOrganizationsPaginatedOutput
     */
    'results': Array<PublicOrganizationsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicOrganizationsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicOrganizationsPaginatedOutputResultsInner
 */
export interface PublicOrganizationsPaginatedOutputResultsInner {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicOrganizationsPaginatedOutputResultsInner
     */
    'auth0_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof PublicOrganizationsPaginatedOutputResultsInner
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof PublicOrganizationsPaginatedOutputResultsInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PurchaseIntentOutput
 */
export interface PurchaseIntentOutput {
    /**
     * UtcDateTime
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'eth_address'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'fulfillment_method': PurchaseIntentOutputFulfillmentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'fulfillment_status': PurchaseIntentOutputFulfillmentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PurchaseIntentOutput
     */
    'item_claim_ids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'listing_id': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'payment_provider': PurchaseIntentOutputPaymentProviderEnum;
    /**
     * 
     * @type {object}
     * @memberof PurchaseIntentOutput
     */
    'provider_price': object;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'status': PurchaseIntentOutputStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentOutput
     */
    'stripe_payment_intent_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PurchaseIntentOutput
     */
    'transaction_ids'?: Array<string> | null;
}

export const PurchaseIntentOutputFulfillmentMethodEnum = {
    Email: 'EMAIL',
    Transfer: 'TRANSFER',
    Voucher: 'VOUCHER'
} as const;

export type PurchaseIntentOutputFulfillmentMethodEnum = typeof PurchaseIntentOutputFulfillmentMethodEnum[keyof typeof PurchaseIntentOutputFulfillmentMethodEnum];
export const PurchaseIntentOutputFulfillmentStatusEnum = {
    Pending: 'PENDING',
    Assigned: 'ASSIGNED',
    Completed: 'COMPLETED',
    Exception: 'EXCEPTION'
} as const;

export type PurchaseIntentOutputFulfillmentStatusEnum = typeof PurchaseIntentOutputFulfillmentStatusEnum[keyof typeof PurchaseIntentOutputFulfillmentStatusEnum];
export const PurchaseIntentOutputPaymentProviderEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type PurchaseIntentOutputPaymentProviderEnum = typeof PurchaseIntentOutputPaymentProviderEnum[keyof typeof PurchaseIntentOutputPaymentProviderEnum];
export const PurchaseIntentOutputStatusEnum = {
    Pending: 'PENDING',
    Unresolved: 'UNRESOLVED',
    Cancelled: 'CANCELLED',
    Confirmed: 'CONFIRMED',
    Expired: 'EXPIRED'
} as const;

export type PurchaseIntentOutputStatusEnum = typeof PurchaseIntentOutputStatusEnum[keyof typeof PurchaseIntentOutputStatusEnum];

/**
 * 
 * @export
 * @interface PurchaseIntentPaginatedOutput
 */
export interface PurchaseIntentPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseIntentPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PurchaseIntentPaginatedOutputResultsInner>}
     * @memberof PurchaseIntentPaginatedOutput
     */
    'results': Array<PurchaseIntentPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PurchaseIntentPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PurchaseIntentPaginatedOutputResultsInner
 */
export interface PurchaseIntentPaginatedOutputResultsInner {
    /**
     * UtcDateTime
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'eth_address'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'fulfillment_method': PurchaseIntentPaginatedOutputResultsInnerFulfillmentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'fulfillment_status': PurchaseIntentPaginatedOutputResultsInnerFulfillmentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'item_claim_ids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'listing_id': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'payment_provider': PurchaseIntentPaginatedOutputResultsInnerPaymentProviderEnum;
    /**
     * 
     * @type {object}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'provider_price': object;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'status': PurchaseIntentPaginatedOutputResultsInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'stripe_payment_intent_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PurchaseIntentPaginatedOutputResultsInner
     */
    'transaction_ids'?: Array<string> | null;
}

export const PurchaseIntentPaginatedOutputResultsInnerFulfillmentMethodEnum = {
    Email: 'EMAIL',
    Transfer: 'TRANSFER',
    Voucher: 'VOUCHER'
} as const;

export type PurchaseIntentPaginatedOutputResultsInnerFulfillmentMethodEnum = typeof PurchaseIntentPaginatedOutputResultsInnerFulfillmentMethodEnum[keyof typeof PurchaseIntentPaginatedOutputResultsInnerFulfillmentMethodEnum];
export const PurchaseIntentPaginatedOutputResultsInnerFulfillmentStatusEnum = {
    Pending: 'PENDING',
    Assigned: 'ASSIGNED',
    Completed: 'COMPLETED',
    Exception: 'EXCEPTION'
} as const;

export type PurchaseIntentPaginatedOutputResultsInnerFulfillmentStatusEnum = typeof PurchaseIntentPaginatedOutputResultsInnerFulfillmentStatusEnum[keyof typeof PurchaseIntentPaginatedOutputResultsInnerFulfillmentStatusEnum];
export const PurchaseIntentPaginatedOutputResultsInnerPaymentProviderEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type PurchaseIntentPaginatedOutputResultsInnerPaymentProviderEnum = typeof PurchaseIntentPaginatedOutputResultsInnerPaymentProviderEnum[keyof typeof PurchaseIntentPaginatedOutputResultsInnerPaymentProviderEnum];
export const PurchaseIntentPaginatedOutputResultsInnerStatusEnum = {
    Pending: 'PENDING',
    Unresolved: 'UNRESOLVED',
    Cancelled: 'CANCELLED',
    Confirmed: 'CONFIRMED',
    Expired: 'EXPIRED'
} as const;

export type PurchaseIntentPaginatedOutputResultsInnerStatusEnum = typeof PurchaseIntentPaginatedOutputResultsInnerStatusEnum[keyof typeof PurchaseIntentPaginatedOutputResultsInnerStatusEnum];

/**
 * 
 * @export
 * @interface ResendEmailClaimInput
 */
export interface ResendEmailClaimInput {
    /**
     * Email
     * @type {string}
     * @memberof ResendEmailClaimInput
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface ResetPasswordUsingTokenInput
 */
export interface ResetPasswordUsingTokenInput {
    /**
     * SafeString
     * @type {string}
     * @memberof ResetPasswordUsingTokenInput
     */
    'password': string;
    /**
     * SafeString
     * @type {string}
     * @memberof ResetPasswordUsingTokenInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface RolesPaginatedOutput
 */
export interface RolesPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof RolesPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RolesPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<RolesPaginatedOutputResultsInner>}
     * @memberof RolesPaginatedOutput
     */
    'results': Array<RolesPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof RolesPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface RolesPaginatedOutputResultsInner
 */
export interface RolesPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof RolesPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RolesPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RolesPaginatedOutputResultsInner
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RolesPaginatedOutputResultsInner
     */
    'rank': number;
}
/**
 * 
 * @export
 * @interface SendTestEmailInput
 */
export interface SendTestEmailInput {
    /**
     * Email
     * @type {string}
     * @memberof SendTestEmailInput
     */
    'to': string;
}
/**
 * 
 * @export
 * @interface SnapshotHoldersPaginatedOutput
 */
export interface SnapshotHoldersPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof SnapshotHoldersPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotHoldersPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {object}
     * @memberof SnapshotHoldersPaginatedOutput
     */
    'results': object;
    /**
     * 
     * @type {number}
     * @memberof SnapshotHoldersPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface SnapshotOutput
 */
export interface SnapshotOutput {
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'contract_address': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'created_at': string;
    /**
     * 
     * @type {object}
     * @memberof SnapshotOutput
     */
    'criteria': object;
    /**
     * 
     * @type {number}
     * @memberof SnapshotOutput
     */
    'holder_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SnapshotOutput
     */
    'network_id': number;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'status': SnapshotOutputStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SnapshotOutput
     */
    'token_type': SnapshotOutputTokenTypeEnum;
}

export const SnapshotOutputStatusEnum = {
    Pending: 'PENDING',
    Error: 'ERROR',
    Completed: 'COMPLETED'
} as const;

export type SnapshotOutputStatusEnum = typeof SnapshotOutputStatusEnum[keyof typeof SnapshotOutputStatusEnum];
export const SnapshotOutputTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type SnapshotOutputTokenTypeEnum = typeof SnapshotOutputTokenTypeEnum[keyof typeof SnapshotOutputTokenTypeEnum];

/**
 * 
 * @export
 * @interface SnapshotsPaginatedOutput
 */
export interface SnapshotsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<SnapshotsPaginatedOutputResultsInner>}
     * @memberof SnapshotsPaginatedOutput
     */
    'results': Array<SnapshotsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof SnapshotsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface SnapshotsPaginatedOutputResultsInner
 */
export interface SnapshotsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'contract_address': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {object}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'criteria': object;
    /**
     * 
     * @type {number}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'holder_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'status': SnapshotsPaginatedOutputResultsInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SnapshotsPaginatedOutputResultsInner
     */
    'token_type': SnapshotsPaginatedOutputResultsInnerTokenTypeEnum;
}

export const SnapshotsPaginatedOutputResultsInnerStatusEnum = {
    Pending: 'PENDING',
    Error: 'ERROR',
    Completed: 'COMPLETED'
} as const;

export type SnapshotsPaginatedOutputResultsInnerStatusEnum = typeof SnapshotsPaginatedOutputResultsInnerStatusEnum[keyof typeof SnapshotsPaginatedOutputResultsInnerStatusEnum];
export const SnapshotsPaginatedOutputResultsInnerTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type SnapshotsPaginatedOutputResultsInnerTokenTypeEnum = typeof SnapshotsPaginatedOutputResultsInnerTokenTypeEnum[keyof typeof SnapshotsPaginatedOutputResultsInnerTokenTypeEnum];

/**
 * 
 * @export
 * @interface StripeCommandInput
 */
export interface StripeCommandInput {
    /**
     * 
     * @type {string}
     * @memberof StripeCommandInput
     */
    'command': StripeCommandInputCommandEnum;
}

export const StripeCommandInputCommandEnum = {
    Retry: 'RETRY'
} as const;

export type StripeCommandInputCommandEnum = typeof StripeCommandInputCommandEnum[keyof typeof StripeCommandInputCommandEnum];

/**
 * 
 * @export
 * @interface StripeConfigOutput
 */
export interface StripeConfigOutput {
    /**
     * 
     * @type {Array<string>}
     * @memberof StripeConfigOutput
     */
    'connect_types': Array<StripeConfigOutputConnectTypesEnum>;
    /**
     * 
     * @type {Array<PaginatedPublicPlatformStripeAccountsOutputResultsInner>}
     * @memberof StripeConfigOutput
     */
    'enabled_accounts': Array<PaginatedPublicPlatformStripeAccountsOutputResultsInner>;
}

export const StripeConfigOutputConnectTypesEnum = {
    Standard: 'STANDARD',
    Express: 'EXPRESS',
    Custom: 'CUSTOM'
} as const;

export type StripeConfigOutputConnectTypesEnum = typeof StripeConfigOutputConnectTypesEnum[keyof typeof StripeConfigOutputConnectTypesEnum];

/**
 * 
 * @export
 * @interface StripeIntegrationOutput
 */
export interface StripeIntegrationOutput {
    /**
     * 
     * @type {string}
     * @memberof StripeIntegrationOutput
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof StripeIntegrationOutput
     */
    'connect_type': StripeIntegrationOutputConnectTypeEnum;
    /**
     * 
     * @type {PaginatedPublicPlatformStripeAccountsOutputResultsInner}
     * @memberof StripeIntegrationOutput
     */
    'platform_stripe_account': PaginatedPublicPlatformStripeAccountsOutputResultsInner;
    /**
     * 
     * @type {boolean}
     * @memberof StripeIntegrationOutput
     */
    'setup_complete': boolean;
}

export const StripeIntegrationOutputConnectTypeEnum = {
    Standard: 'STANDARD',
    Express: 'EXPRESS',
    Custom: 'CUSTOM'
} as const;

export type StripeIntegrationOutputConnectTypeEnum = typeof StripeIntegrationOutputConnectTypeEnum[keyof typeof StripeIntegrationOutputConnectTypeEnum];

/**
 * 
 * @export
 * @interface StripePaymentsSetupInput
 */
export interface StripePaymentsSetupInput {
    /**
     * 
     * @type {string}
     * @memberof StripePaymentsSetupInput
     */
    'connect_type': StripePaymentsSetupInputConnectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentsSetupInput
     */
    'platform_stripe_account_id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof StripePaymentsSetupInput
     */
    'return_url'?: string | null;
}

export const StripePaymentsSetupInputConnectTypeEnum = {
    Standard: 'STANDARD',
    Express: 'EXPRESS',
    Custom: 'CUSTOM'
} as const;

export type StripePaymentsSetupInputConnectTypeEnum = typeof StripePaymentsSetupInputConnectTypeEnum[keyof typeof StripePaymentsSetupInputConnectTypeEnum];

/**
 * 
 * @export
 * @interface StripePaymentsSetupOutput
 */
export interface StripePaymentsSetupOutput {
    /**
     * 
     * @type {string}
     * @memberof StripePaymentsSetupOutput
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface TestWebhookInput
 */
export interface TestWebhookInput {
    /**
     * SafeString
     * @type {string}
     * @memberof TestWebhookInput
     */
    'secret': string;
    /**
     * SafeString
     * @type {string}
     * @memberof TestWebhookInput
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface TestWebhookOutput
 */
export interface TestWebhookOutput {
    /**
     * 
     * @type {number}
     * @memberof TestWebhookOutput
     */
    'response'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestWebhookOutput
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface TransactionOutput
 */
export interface TransactionOutput {
    /**
     * 
     * @type {TransactionOutputBillingDetails}
     * @memberof TransactionOutput
     */
    'billing_details'?: TransactionOutputBillingDetails | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'billing_status': TransactionOutputBillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'collection_id'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof TransactionOutput
     */
    'created_at': string;
    /**
     * 
     * @type {object}
     * @memberof TransactionOutput
     */
    'data': object;
    /**
     * Uint256
     * @type {string}
     * @memberof TransactionOutput
     */
    'effective_gas_price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'error_message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutput
     */
    'failure_count': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutput
     */
    'gas_used'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutput
     */
    'mined_block_number'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutput
     */
    'network_id': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutput
     */
    'nonce': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'on_chain_status': TransactionOutputOnChainStatusEnum;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof TransactionOutput
     */
    'on_chain_timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'signer': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'state': TransactionOutputStateEnum;
    /**
     * Uint256
     * @type {string}
     * @memberof TransactionOutput
     */
    'tx_fee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'tx_hash': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutput
     */
    'tx_type': TransactionOutputTxTypeEnum;
}

export const TransactionOutputBillingStatusEnum = {
    New: 'NEW',
    Initiated: 'INITIATED',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED',
    Expired: 'EXPIRED',
    Failed: 'FAILED',
    NoCharge: 'NO_CHARGE'
} as const;

export type TransactionOutputBillingStatusEnum = typeof TransactionOutputBillingStatusEnum[keyof typeof TransactionOutputBillingStatusEnum];
export const TransactionOutputOnChainStatusEnum = {
    Failure: 'FAILURE',
    Success: 'SUCCESS'
} as const;

export type TransactionOutputOnChainStatusEnum = typeof TransactionOutputOnChainStatusEnum[keyof typeof TransactionOutputOnChainStatusEnum];
export const TransactionOutputStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type TransactionOutputStateEnum = typeof TransactionOutputStateEnum[keyof typeof TransactionOutputStateEnum];
export const TransactionOutputTxTypeEnum = {
    DeployTokenContract: 'DEPLOY_TOKEN_CONTRACT',
    DeployContractViaFactory: 'DEPLOY_CONTRACT_VIA_FACTORY',
    SetApprovalForAll: 'SET_APPROVAL_FOR_ALL',
    MintToken: 'MINT_TOKEN',
    AdminMintWithUri: 'ADMIN_MINT_WITH_URI',
    TransferToken: 'TRANSFER_TOKEN',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    FundAccount: 'FUND_ACCOUNT',
    WithdrawBalance: 'WITHDRAW_BALANCE',
    CreateOnchainEnglishAuction: 'CREATE_ONCHAIN_ENGLISH_AUCTION',
    SettleOnchainEnglishAuction: 'SETTLE_ONCHAIN_ENGLISH_AUCTION',
    CancelOnchainEnglishAuction: 'CANCEL_ONCHAIN_ENGLISH_AUCTION',
    AdminMint: 'ADMIN_MINT',
    AdminMintNoId: 'ADMIN_MINT_NO_ID',
    TransferOwnership: 'TRANSFER_OWNERSHIP',
    GrantRole: 'GRANT_ROLE',
    GrantMany: 'GRANT_MANY',
    InvokeFunction: 'INVOKE_FUNCTION',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetPerTokenRoyalty: 'SET_PER_TOKEN_ROYALTY',
    UnsetPerTokenRoyalty: 'UNSET_PER_TOKEN_ROYALTY',
    DistributorContract: 'DISTRIBUTOR_CONTRACT',
    SetBaseUri: 'SET_BASE_URI',
    TransferErc20: 'TRANSFER_ERC20',
    BulkAdminMint: 'BULK_ADMIN_MINT',
    BillingStatus: 'BILLING_STATUS',
    CreateTokenFixedPrice: 'CREATE_TOKEN_FIXED_PRICE',
    Disperse: 'DISPERSE'
} as const;

export type TransactionOutputTxTypeEnum = typeof TransactionOutputTxTypeEnum[keyof typeof TransactionOutputTxTypeEnum];

/**
 * 
 * @export
 * @interface TransactionOutputBillingDetails
 */
export interface TransactionOutputBillingDetails {
    /**
     * 
     * @type {Array<TransactionOutputBillingDetailsChargesInner>}
     * @memberof TransactionOutputBillingDetails
     */
    'charges'?: Array<TransactionOutputBillingDetailsChargesInner> | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutputBillingDetails
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutputBillingDetails
     */
    'gas_estimate': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutputBillingDetails
     */
    'service_fee': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutputBillingDetails
     */
    'sub_total': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionOutputBillingDetails
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface TransactionOutputBillingDetailsChargesInner
 */
export interface TransactionOutputBillingDetailsChargesInner {
    /**
     * 
     * @type {number}
     * @memberof TransactionOutputBillingDetailsChargesInner
     */
    'amount_cents': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionOutputBillingDetailsChargesInner
     */
    'payment_method': string;
}
/**
 * 
 * @export
 * @interface TransactionsPaginatedOutput
 */
export interface TransactionsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<TransactionsPaginatedOutputResultsInner>}
     * @memberof TransactionsPaginatedOutput
     */
    'results': Array<TransactionsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof TransactionsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface TransactionsPaginatedOutputResultsInner
 */
export interface TransactionsPaginatedOutputResultsInner {
    /**
     * 
     * @type {TransactionOutputBillingDetails}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'billing_details'?: TransactionOutputBillingDetails | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'billing_status': TransactionsPaginatedOutputResultsInnerBillingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'collection_id'?: string | null;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {object}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'data': object;
    /**
     * Uint256
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'effective_gas_price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'error_message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'failure_count': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'gas_used'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'mined_block_number'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'nonce': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'on_chain_status': TransactionsPaginatedOutputResultsInnerOnChainStatusEnum;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'on_chain_timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'signer': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'state': TransactionsPaginatedOutputResultsInnerStateEnum;
    /**
     * Uint256
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'tx_fee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'tx_hash': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionsPaginatedOutputResultsInner
     */
    'tx_type': TransactionsPaginatedOutputResultsInnerTxTypeEnum;
}

export const TransactionsPaginatedOutputResultsInnerBillingStatusEnum = {
    New: 'NEW',
    Initiated: 'INITIATED',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED',
    Expired: 'EXPIRED',
    Failed: 'FAILED',
    NoCharge: 'NO_CHARGE'
} as const;

export type TransactionsPaginatedOutputResultsInnerBillingStatusEnum = typeof TransactionsPaginatedOutputResultsInnerBillingStatusEnum[keyof typeof TransactionsPaginatedOutputResultsInnerBillingStatusEnum];
export const TransactionsPaginatedOutputResultsInnerOnChainStatusEnum = {
    Failure: 'FAILURE',
    Success: 'SUCCESS'
} as const;

export type TransactionsPaginatedOutputResultsInnerOnChainStatusEnum = typeof TransactionsPaginatedOutputResultsInnerOnChainStatusEnum[keyof typeof TransactionsPaginatedOutputResultsInnerOnChainStatusEnum];
export const TransactionsPaginatedOutputResultsInnerStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type TransactionsPaginatedOutputResultsInnerStateEnum = typeof TransactionsPaginatedOutputResultsInnerStateEnum[keyof typeof TransactionsPaginatedOutputResultsInnerStateEnum];
export const TransactionsPaginatedOutputResultsInnerTxTypeEnum = {
    DeployTokenContract: 'DEPLOY_TOKEN_CONTRACT',
    DeployContractViaFactory: 'DEPLOY_CONTRACT_VIA_FACTORY',
    SetApprovalForAll: 'SET_APPROVAL_FOR_ALL',
    MintToken: 'MINT_TOKEN',
    AdminMintWithUri: 'ADMIN_MINT_WITH_URI',
    TransferToken: 'TRANSFER_TOKEN',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    FundAccount: 'FUND_ACCOUNT',
    WithdrawBalance: 'WITHDRAW_BALANCE',
    CreateOnchainEnglishAuction: 'CREATE_ONCHAIN_ENGLISH_AUCTION',
    SettleOnchainEnglishAuction: 'SETTLE_ONCHAIN_ENGLISH_AUCTION',
    CancelOnchainEnglishAuction: 'CANCEL_ONCHAIN_ENGLISH_AUCTION',
    AdminMint: 'ADMIN_MINT',
    AdminMintNoId: 'ADMIN_MINT_NO_ID',
    TransferOwnership: 'TRANSFER_OWNERSHIP',
    GrantRole: 'GRANT_ROLE',
    GrantMany: 'GRANT_MANY',
    InvokeFunction: 'INVOKE_FUNCTION',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetPerTokenRoyalty: 'SET_PER_TOKEN_ROYALTY',
    UnsetPerTokenRoyalty: 'UNSET_PER_TOKEN_ROYALTY',
    DistributorContract: 'DISTRIBUTOR_CONTRACT',
    SetBaseUri: 'SET_BASE_URI',
    TransferErc20: 'TRANSFER_ERC20',
    BulkAdminMint: 'BULK_ADMIN_MINT',
    BillingStatus: 'BILLING_STATUS',
    CreateTokenFixedPrice: 'CREATE_TOKEN_FIXED_PRICE',
    Disperse: 'DISPERSE'
} as const;

export type TransactionsPaginatedOutputResultsInnerTxTypeEnum = typeof TransactionsPaginatedOutputResultsInnerTxTypeEnum[keyof typeof TransactionsPaginatedOutputResultsInnerTxTypeEnum];

/**
 * 
 * @export
 * @interface TransferRequestInput
 */
export interface TransferRequestInput {
    /**
     * 
     * @type {string}
     * @memberof TransferRequestInput
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestInput
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransferRequestInput
     */
    'item_ids'?: Array<string> | null;
    /**
     * Uint256
     * @type {string}
     * @memberof TransferRequestInput
     */
    'quantity'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof TransferRequestInput
     */
    'to_address': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransferRequestInput
     */
    'token_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<InputListingContextTokenRangesInner>}
     * @memberof TransferRequestInput
     */
    'token_ranges'?: Array<InputListingContextTokenRangesInner> | null;
}
/**
 * 
 * @export
 * @interface TransferRequestOutput
 */
export interface TransferRequestOutput {
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'item_id': string;
    /**
     * Uint
     * @type {number}
     * @memberof TransferRequestOutput
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'state': TransferRequestOutputStateEnum;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'to_address': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'transaction_id': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutput
     */
    'tx_hash'?: string | null;
}

export const TransferRequestOutputStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type TransferRequestOutputStateEnum = typeof TransferRequestOutputStateEnum[keyof typeof TransferRequestOutputStateEnum];

/**
 * 
 * @export
 * @interface TransferRequestOutputEnvelope
 */
export interface TransferRequestOutputEnvelope {
    /**
     * 
     * @type {Array<TransferRequestOutputEnvelopeTransferRequestsInner>}
     * @memberof TransferRequestOutputEnvelope
     */
    'transfer_requests': Array<TransferRequestOutputEnvelopeTransferRequestsInner>;
}
/**
 * 
 * @export
 * @interface TransferRequestOutputEnvelopeTransferRequestsInner
 */
export interface TransferRequestOutputEnvelopeTransferRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'item_id': string;
    /**
     * Uint
     * @type {number}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'state': TransferRequestOutputEnvelopeTransferRequestsInnerStateEnum;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'to_address': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'transaction_id': string;
    /**
     * 
     * @type {string}
     * @memberof TransferRequestOutputEnvelopeTransferRequestsInner
     */
    'tx_hash'?: string | null;
}

export const TransferRequestOutputEnvelopeTransferRequestsInnerStateEnum = {
    Pending: 'PENDING',
    Submitted: 'SUBMITTED',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED',
    Queued: 'QUEUED'
} as const;

export type TransferRequestOutputEnvelopeTransferRequestsInnerStateEnum = typeof TransferRequestOutputEnvelopeTransferRequestsInnerStateEnum[keyof typeof TransferRequestOutputEnvelopeTransferRequestsInnerStateEnum];

/**
 * 
 * @export
 * @interface TransferRequestsPaginatedOutput
 */
export interface TransferRequestsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof TransferRequestsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransferRequestsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<TransferRequestOutputEnvelopeTransferRequestsInner>}
     * @memberof TransferRequestsPaginatedOutput
     */
    'results': Array<TransferRequestOutputEnvelopeTransferRequestsInner>;
    /**
     * 
     * @type {number}
     * @memberof TransferRequestsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface UpdateItemInput
 */
export interface UpdateItemInput {
    /**
     * 
     * @type {object}
     * @memberof UpdateItemInput
     */
    'attributes'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemInput
     */
    'item_type_id'?: string | null;
    /**
     * 
     * @type {BulkUpdateItemOutputResultsInnerRoyaltyInfo}
     * @memberof UpdateItemInput
     */
    'royalty_info'?: BulkUpdateItemOutputResultsInnerRoyaltyInfo | null;
}
/**
 * 
 * @export
 * @interface UploadOutput
 */
export interface UploadOutput {
    /**
     * 
     * @type {string}
     * @memberof UploadOutput
     */
    'ipfs_hash': string;
    /**
     * 
     * @type {string}
     * @memberof UploadOutput
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface UserOutput
 */
export interface UserOutput {
    /**
     * 
     * @type {boolean}
     * @memberof UserOutput
     */
    'active': boolean;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof UserOutput
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutput
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOutput
     */
    'last_seen'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutput
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOutput
     */
    'role_id': string;
}
/**
 * 
 * @export
 * @interface UsersPaginatedOutput
 */
export interface UsersPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof UsersPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UsersPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<CurrentUserOutputUser>}
     * @memberof UsersPaginatedOutput
     */
    'results': Array<CurrentUserOutputUser>;
    /**
     * 
     * @type {number}
     * @memberof UsersPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface VerifyTokenContractCapabilitiesInput
 */
export interface VerifyTokenContractCapabilitiesInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyTokenContractCapabilitiesInput
     */
    'abi': Array<string>;
    /**
     * HexString
     * @type {string}
     * @memberof VerifyTokenContractCapabilitiesInput
     */
    'bytecode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyTokenContractCapabilitiesInput
     */
    'constructor_args': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenContractCapabilitiesInput
     */
    'token_type'?: VerifyTokenContractCapabilitiesInputTokenTypeEnum;
}

export const VerifyTokenContractCapabilitiesInputTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type VerifyTokenContractCapabilitiesInputTokenTypeEnum = typeof VerifyTokenContractCapabilitiesInputTokenTypeEnum[keyof typeof VerifyTokenContractCapabilitiesInputTokenTypeEnum];

/**
 * 
 * @export
 * @interface VerifyWeb2Input
 */
export interface VerifyWeb2Input {
    /**
     * Email
     * @type {string}
     * @memberof VerifyWeb2Input
     */
    'email': string;
    /**
     * SafeString
     * @type {string}
     * @memberof VerifyWeb2Input
     */
    'password': string;
    /**
     * SafeString
     * @type {string}
     * @memberof VerifyWeb2Input
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface Web2LoginInput
 */
export interface Web2LoginInput {
    /**
     * Email
     * @type {string}
     * @memberof Web2LoginInput
     */
    'email': string;
    /**
     * SafeString
     * @type {string}
     * @memberof Web2LoginInput
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    /**
     * 
     * @type {boolean}
     * @memberof Webhook
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof Webhook
     */
    'name': string;
    /**
     * SafeString
     * @type {string}
     * @memberof Webhook
     */
    'secret': string;
    /**
     * SafeString
     * @type {string}
     * @memberof Webhook
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface WebhookInput
 */
export interface WebhookInput {
    /**
     * 
     * @type {boolean}
     * @memberof WebhookInput
     */
    'enabled': boolean;
    /**
     * SafeString
     * @type {string}
     * @memberof WebhookInput
     */
    'name': string;
    /**
     * SafeString
     * @type {string}
     * @memberof WebhookInput
     */
    'secret': string;
    /**
     * SafeString
     * @type {string}
     * @memberof WebhookInput
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface WebhooksPaginatedOutput
 */
export interface WebhooksPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof WebhooksPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebhooksPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<WebhooksPaginatedOutputResultsInner>}
     * @memberof WebhooksPaginatedOutput
     */
    'results': Array<WebhooksPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof WebhooksPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface WebhooksPaginatedOutputResultsInner
 */
export interface WebhooksPaginatedOutputResultsInner {
    /**
     * 
     * @type {boolean}
     * @memberof WebhooksPaginatedOutputResultsInner
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhooksPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof WebhooksPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * SafeString
     * @type {string}
     * @memberof WebhooksPaginatedOutputResultsInner
     */
    'secret': string;
    /**
     * SafeString
     * @type {string}
     * @memberof WebhooksPaginatedOutputResultsInner
     */
    'url': string;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete an API key
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysApiKeyIdDelete: async (apiKeyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('apiKeysApiKeyIdDelete', 'apiKeyId', apiKeyId)
            const localVarPath = `/api-keys/{api_key_id}`
                .replace(`{${"api_key_id"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get API key by id
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysApiKeyIdGet: async (apiKeyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('apiKeysApiKeyIdGet', 'apiKeyId', apiKeyId)
            const localVarPath = `/api-keys/{api_key_id}`
                .replace(`{${"api_key_id"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all API keys for the current user
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new API key
         * @param {CreateApiKeyInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysPost: async (body?: CreateApiKeyInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This verifies the ownership of an email by sending the token received, along with the password to set for the account.
         * @summary Verify user
         * @param {VerifyWeb2Input} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsEmailPost: async (body?: VerifyWeb2Input, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/credentials/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For a registered user, this sends a token to the email address which can be used to reset the password.  This endpoint is only applicable to users whose account currently contains a password, and cannot be used for web3 accounts.
         * @summary Forgot user password
         * @param {ForgotPasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsForgotPost: async (body?: ForgotPasswordInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/credentials/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change user password
         * @param {ChangePasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsPasswordPatch: async (body?: ChangePasswordInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This resets the user\'s password from a token received in an email.
         * @summary Reset user password
         * @param {ResetPasswordUsingTokenInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsResetPost: async (body?: ResetPasswordUsingTokenInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/credentials/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log in user
         * @param {Web2LoginInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPost: async (body?: Web2LoginInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete an API key
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeysApiKeyIdDelete(apiKeyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeysApiKeyIdDelete(apiKeyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get API key by id
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeysApiKeyIdGet(apiKeyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeysApiKeyIdGet(apiKeyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all API keys for the current user
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeysGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeysGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new API key
         * @param {CreateApiKeyInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeysPost(body?: CreateApiKeyInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyCreatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeysPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This verifies the ownership of an email by sending the token received, along with the password to set for the account.
         * @summary Verify user
         * @param {VerifyWeb2Input} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsEmailPost(body?: VerifyWeb2Input, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsEmailPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For a registered user, this sends a token to the email address which can be used to reset the password.  This endpoint is only applicable to users whose account currently contains a password, and cannot be used for web3 accounts.
         * @summary Forgot user password
         * @param {ForgotPasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsForgotPost(body?: ForgotPasswordInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsForgotPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change user password
         * @param {ChangePasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsPasswordPatch(body?: ChangePasswordInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsPasswordPatch(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This resets the user\'s password from a token received in an email.
         * @summary Reset user password
         * @param {ResetPasswordUsingTokenInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsResetPost(body?: ResetPasswordUsingTokenInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsResetPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log in user
         * @param {Web2LoginInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginPost(body?: Web2LoginInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete an API key
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysApiKeyIdDelete(apiKeyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeysApiKeyIdDelete(apiKeyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get API key by id
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysApiKeyIdGet(apiKeyId: string, options?: any): AxiosPromise<ApiKeyOutput> {
            return localVarFp.apiKeysApiKeyIdGet(apiKeyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all API keys for the current user
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<ApiKeyPaginatedOutput> {
            return localVarFp.apiKeysGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new API key
         * @param {CreateApiKeyInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeysPost(body?: CreateApiKeyInput, options?: any): AxiosPromise<ApiKeyCreatedOutput> {
            return localVarFp.apiKeysPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This verifies the ownership of an email by sending the token received, along with the password to set for the account.
         * @summary Verify user
         * @param {VerifyWeb2Input} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsEmailPost(body?: VerifyWeb2Input, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.credentialsEmailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * For a registered user, this sends a token to the email address which can be used to reset the password.  This endpoint is only applicable to users whose account currently contains a password, and cannot be used for web3 accounts.
         * @summary Forgot user password
         * @param {ForgotPasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsForgotPost(body?: ForgotPasswordInput, options?: any): AxiosPromise<void> {
            return localVarFp.credentialsForgotPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change user password
         * @param {ChangePasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsPasswordPatch(body?: ChangePasswordInput, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.credentialsPasswordPatch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This resets the user\'s password from a token received in an email.
         * @summary Reset user password
         * @param {ResetPasswordUsingTokenInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsResetPost(body?: ResetPasswordUsingTokenInput, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.credentialsResetPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log in user
         * @param {Web2LoginInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPost(body?: Web2LoginInput, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.loginPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.logoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGet(options?: any): AxiosPromise<CurrentUserOutput> {
            return localVarFp.meGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Delete an API key
     * @param {string} apiKeyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiKeysApiKeyIdDelete(apiKeyId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiKeysApiKeyIdDelete(apiKeyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get API key by id
     * @param {string} apiKeyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiKeysApiKeyIdGet(apiKeyId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiKeysApiKeyIdGet(apiKeyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all API keys for the current user
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiKeysGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiKeysGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new API key
     * @param {CreateApiKeyInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiKeysPost(body?: CreateApiKeyInput, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiKeysPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This verifies the ownership of an email by sending the token received, along with the password to set for the account.
     * @summary Verify user
     * @param {VerifyWeb2Input} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public credentialsEmailPost(body?: VerifyWeb2Input, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).credentialsEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For a registered user, this sends a token to the email address which can be used to reset the password.  This endpoint is only applicable to users whose account currently contains a password, and cannot be used for web3 accounts.
     * @summary Forgot user password
     * @param {ForgotPasswordInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public credentialsForgotPost(body?: ForgotPasswordInput, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).credentialsForgotPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change user password
     * @param {ChangePasswordInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public credentialsPasswordPatch(body?: ChangePasswordInput, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).credentialsPasswordPatch(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This resets the user\'s password from a token received in an email.
     * @summary Reset user password
     * @param {ResetPasswordUsingTokenInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public credentialsResetPost(body?: ResetPasswordUsingTokenInput, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).credentialsResetPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log in user
     * @param {Web2LoginInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public loginPost(body?: Web2LoginInput, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).loginPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logoutPost(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public meGet(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).meGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollectionApi - axios parameter creator
 * @export
 */
export const CollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdDelete: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdDelete', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets contract details by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdDeploymentRequestGet: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdDeploymentRequestGet', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/deployment-request`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit a request to deploy a contract for the collection.
         * @param {string} collectionId 
         * @param {DeployContractInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdDeploymentRequestPost: async (collectionId: string, body?: DeployContractInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdDeploymentRequestPost', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/deployment-request`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read from a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {CallContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdCallPost: async (collectionId: string, functionId: string, body?: CallContractFunctionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdCallPost', 'collectionId', collectionId)
            // verify required parameter 'functionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdCallPost', 'functionId', functionId)
            const localVarPath = `/collections/{collection_id}/functions/{function_id}/call`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)))
                .replace(`{${"function_id"}}`, encodeURIComponent(String(functionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Execute a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {ExecuteContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdExecutePost: async (collectionId: string, functionId: string, body?: ExecuteContractFunctionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdExecutePost', 'collectionId', collectionId)
            // verify required parameter 'functionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdExecutePost', 'functionId', functionId)
            const localVarPath = `/collections/{collection_id}/functions/{function_id}/execute`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)))
                .replace(`{${"function_id"}}`, encodeURIComponent(String(functionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdGet: async (collectionId: string, functionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdGet', 'collectionId', collectionId)
            // verify required parameter 'functionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdGet', 'functionId', functionId)
            const localVarPath = `/collections/{collection_id}/functions/{function_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)))
                .replace(`{${"function_id"}}`, encodeURIComponent(String(functionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable a collection contract function to be callable
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {EnableCollectionContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdPatch: async (collectionId: string, functionId: string, body?: EnableCollectionContractFunctionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdPatch', 'collectionId', collectionId)
            // verify required parameter 'functionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsFunctionIdPatch', 'functionId', functionId)
            const localVarPath = `/collections/{collection_id}/functions/{function_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)))
                .replace(`{${"function_id"}}`, encodeURIComponent(String(functionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract functions defined on a collection contract
         * @param {string} collectionId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsGet: async (collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdFunctionsGet', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/functions`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a collection by id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdGet: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdGet', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
         * @summary Request a metadata refresh for the given collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdMetadataDelete: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdMetadataDelete', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/metadata`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a partial update, only attributes present will be updated.
         * @summary Edit a collection
         * @param {string} collectionId 
         * @param {EditCollectionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdPatch: async (collectionId: string, body?: EditCollectionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdPatch', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pin all collection metadata items permanently to decentralized storage
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdPinPatch: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdPinPatch', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/pin`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all collections
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {string} [contractAddress] ChecksumAddress
         * @param {'ERC721' | 'ERC1155' | 'null'} [tokenType] 
         * @param {number} [networkId] 
         * @param {'NAME' | 'null'} [orderBy] 
         * @param {boolean} [testnet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, contractAddress?: string, tokenType?: 'ERC721' | 'ERC1155' | 'null', networkId?: number, orderBy?: 'NAME' | 'null', testnet?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (contractAddress !== undefined) {
                localVarQueryParameter['contract_address'] = contractAddress;
            }

            if (tokenType !== undefined) {
                localVarQueryParameter['token_type'] = tokenType;
            }

            if (networkId !== undefined) {
                localVarQueryParameter['network_id'] = networkId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (testnet !== undefined) {
                localVarQueryParameter['testnet'] = testnet;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new collection
         * @param {CreateCollectionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsPost: async (body?: CreateCollectionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdDelete(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdDelete(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets contract details by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdDeploymentRequestGet(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentRequestOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdDeploymentRequestGet(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit a request to deploy a contract for the collection.
         * @param {string} collectionId 
         * @param {DeployContractInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdDeploymentRequestPost(collectionId: string, body?: DeployContractInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentRequestOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdDeploymentRequestPost(collectionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read from a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {CallContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdFunctionsFunctionIdCallPost(collectionId: string, functionId: string, body?: CallContractFunctionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdFunctionsFunctionIdCallPost(collectionId, functionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Execute a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {ExecuteContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdFunctionsFunctionIdExecutePost(collectionId: string, functionId: string, body?: ExecuteContractFunctionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecuteContractFunctionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdFunctionsFunctionIdExecutePost(collectionId, functionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdFunctionsFunctionIdGet(collectionId: string, functionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCollectionContractFunctionByIdOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdFunctionsFunctionIdGet(collectionId, functionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable a collection contract function to be callable
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {EnableCollectionContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdFunctionsFunctionIdPatch(collectionId: string, functionId: string, body?: EnableCollectionContractFunctionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnableCollectionContractFunctionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdFunctionsFunctionIdPatch(collectionId, functionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract functions defined on a collection contract
         * @param {string} collectionId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdFunctionsGet(collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCollectionContractFunctionsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdFunctionsGet(collectionId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a collection by id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdGet(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdGet(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
         * @summary Request a metadata refresh for the given collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdMetadataDelete(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdMetadataDelete(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is a partial update, only attributes present will be updated.
         * @summary Edit a collection
         * @param {string} collectionId 
         * @param {EditCollectionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdPatch(collectionId: string, body?: EditCollectionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdPatch(collectionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pin all collection metadata items permanently to decentralized storage
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdPinPatch(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PinCollectionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdPinPatch(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all collections
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {string} [contractAddress] ChecksumAddress
         * @param {'ERC721' | 'ERC1155' | 'null'} [tokenType] 
         * @param {number} [networkId] 
         * @param {'NAME' | 'null'} [orderBy] 
         * @param {boolean} [testnet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, contractAddress?: string, tokenType?: 'ERC721' | 'ERC1155' | 'null', networkId?: number, orderBy?: 'NAME' | 'null', testnet?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsGet(orderDirection, cursor, limit, name, contractAddress, tokenType, networkId, orderBy, testnet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new collection
         * @param {CreateCollectionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsPost(body?: CreateCollectionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdDelete(collectionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.collectionsCollectionIdDelete(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets contract details by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdDeploymentRequestGet(collectionId: string, options?: any): AxiosPromise<DeploymentRequestOutput> {
            return localVarFp.collectionsCollectionIdDeploymentRequestGet(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit a request to deploy a contract for the collection.
         * @param {string} collectionId 
         * @param {DeployContractInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdDeploymentRequestPost(collectionId: string, body?: DeployContractInput, options?: any): AxiosPromise<DeploymentRequestOutput> {
            return localVarFp.collectionsCollectionIdDeploymentRequestPost(collectionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read from a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {CallContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdCallPost(collectionId: string, functionId: string, body?: CallContractFunctionInput, options?: any): AxiosPromise<object> {
            return localVarFp.collectionsCollectionIdFunctionsFunctionIdCallPost(collectionId, functionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Execute a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {ExecuteContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdExecutePost(collectionId: string, functionId: string, body?: ExecuteContractFunctionInput, options?: any): AxiosPromise<ExecuteContractFunctionOutput> {
            return localVarFp.collectionsCollectionIdFunctionsFunctionIdExecutePost(collectionId, functionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a contract function by collection and function id
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdGet(collectionId: string, functionId: string, options?: any): AxiosPromise<GetCollectionContractFunctionByIdOutput> {
            return localVarFp.collectionsCollectionIdFunctionsFunctionIdGet(collectionId, functionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable a collection contract function to be callable
         * @param {string} collectionId 
         * @param {string} functionId 
         * @param {EnableCollectionContractFunctionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsFunctionIdPatch(collectionId: string, functionId: string, body?: EnableCollectionContractFunctionInput, options?: any): AxiosPromise<EnableCollectionContractFunctionOutput> {
            return localVarFp.collectionsCollectionIdFunctionsFunctionIdPatch(collectionId, functionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract functions defined on a collection contract
         * @param {string} collectionId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdFunctionsGet(collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<GetCollectionContractFunctionsPaginatedOutput> {
            return localVarFp.collectionsCollectionIdFunctionsGet(collectionId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a collection by id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdGet(collectionId: string, options?: any): AxiosPromise<CollectionOutput> {
            return localVarFp.collectionsCollectionIdGet(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
         * @summary Request a metadata refresh for the given collection
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdMetadataDelete(collectionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.collectionsCollectionIdMetadataDelete(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a partial update, only attributes present will be updated.
         * @summary Edit a collection
         * @param {string} collectionId 
         * @param {EditCollectionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdPatch(collectionId: string, body?: EditCollectionInput, options?: any): AxiosPromise<CollectionOutput> {
            return localVarFp.collectionsCollectionIdPatch(collectionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pin all collection metadata items permanently to decentralized storage
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdPinPatch(collectionId: string, options?: any): AxiosPromise<PinCollectionOutput> {
            return localVarFp.collectionsCollectionIdPinPatch(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all collections
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {string} [contractAddress] ChecksumAddress
         * @param {'ERC721' | 'ERC1155' | 'null'} [tokenType] 
         * @param {number} [networkId] 
         * @param {'NAME' | 'null'} [orderBy] 
         * @param {boolean} [testnet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, contractAddress?: string, tokenType?: 'ERC721' | 'ERC1155' | 'null', networkId?: number, orderBy?: 'NAME' | 'null', testnet?: boolean, options?: any): AxiosPromise<CollectionsPaginatedOutput> {
            return localVarFp.collectionsGet(orderDirection, cursor, limit, name, contractAddress, tokenType, networkId, orderBy, testnet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new collection
         * @param {CreateCollectionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsPost(body?: CreateCollectionInput, options?: any): AxiosPromise<CollectionOutput> {
            return localVarFp.collectionsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI {
    /**
     * 
     * @summary Delete a collection
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdDelete(collectionId: string, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdDelete(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets contract details by collection id
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdDeploymentRequestGet(collectionId: string, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdDeploymentRequestGet(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit a request to deploy a contract for the collection.
     * @param {string} collectionId 
     * @param {DeployContractInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdDeploymentRequestPost(collectionId: string, body?: DeployContractInput, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdDeploymentRequestPost(collectionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read from a contract function by collection and function id
     * @param {string} collectionId 
     * @param {string} functionId 
     * @param {CallContractFunctionInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdFunctionsFunctionIdCallPost(collectionId: string, functionId: string, body?: CallContractFunctionInput, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdFunctionsFunctionIdCallPost(collectionId, functionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Execute a contract function by collection and function id
     * @param {string} collectionId 
     * @param {string} functionId 
     * @param {ExecuteContractFunctionInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdFunctionsFunctionIdExecutePost(collectionId: string, functionId: string, body?: ExecuteContractFunctionInput, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdFunctionsFunctionIdExecutePost(collectionId, functionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a contract function by collection and function id
     * @param {string} collectionId 
     * @param {string} functionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdFunctionsFunctionIdGet(collectionId: string, functionId: string, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdFunctionsFunctionIdGet(collectionId, functionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable a collection contract function to be callable
     * @param {string} collectionId 
     * @param {string} functionId 
     * @param {EnableCollectionContractFunctionInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdFunctionsFunctionIdPatch(collectionId: string, functionId: string, body?: EnableCollectionContractFunctionInput, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdFunctionsFunctionIdPatch(collectionId, functionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract functions defined on a collection contract
     * @param {string} collectionId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdFunctionsGet(collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdFunctionsGet(collectionId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a collection by id
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdGet(collectionId: string, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdGet(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
     * @summary Request a metadata refresh for the given collection
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdMetadataDelete(collectionId: string, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdMetadataDelete(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a partial update, only attributes present will be updated.
     * @summary Edit a collection
     * @param {string} collectionId 
     * @param {EditCollectionInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdPatch(collectionId: string, body?: EditCollectionInput, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdPatch(collectionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pin all collection metadata items permanently to decentralized storage
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsCollectionIdPinPatch(collectionId: string, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsCollectionIdPinPatch(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all collections
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {string} [name] SafeString
     * @param {string} [contractAddress] ChecksumAddress
     * @param {'ERC721' | 'ERC1155' | 'null'} [tokenType] 
     * @param {number} [networkId] 
     * @param {'NAME' | 'null'} [orderBy] 
     * @param {boolean} [testnet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, contractAddress?: string, tokenType?: 'ERC721' | 'ERC1155' | 'null', networkId?: number, orderBy?: 'NAME' | 'null', testnet?: boolean, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsGet(orderDirection, cursor, limit, name, contractAddress, tokenType, networkId, orderBy, testnet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new collection
     * @param {CreateCollectionInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionsPost(body?: CreateCollectionInput, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).collectionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The contract _must_ have one of the following capabilities: * `ERC721` * `ERC1155`  Otherwise, the contract will be rejected.  In order to be able to mint items through the platform, the contract _should_ have one of the following capabilities: * `ADMIN_MINT_SINGLE` (for ERC721) * `MINT_VOUCHER` (for ERC721) * `ADMIN_MINT_SINGLE_URI` (for ERC721) * `ADMIN_MINT_MULTIPLE_NO_ID` (for ERC721) * `ADMIN_MINT_MULTIPLE`  (for ERC1155)  Capabilities are determined by the contract\'s ABI. You can get the known capabilities ABIs from the public `capabilities` endpoint.
         * @summary Verify capabilities for a token contract
         * @param {VerifyTokenContractCapabilitiesInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCapabilitiesPost: async (body?: VerifyTokenContractCapabilitiesInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contract/capabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * The contract _must_ have one of the following capabilities: * `ERC721` * `ERC1155`  Otherwise, the contract will be rejected.  In order to be able to mint items through the platform, the contract _should_ have one of the following capabilities: * `ADMIN_MINT_SINGLE` (for ERC721) * `MINT_VOUCHER` (for ERC721) * `ADMIN_MINT_SINGLE_URI` (for ERC721) * `ADMIN_MINT_MULTIPLE_NO_ID` (for ERC721) * `ADMIN_MINT_MULTIPLE`  (for ERC1155)  Capabilities are determined by the contract\'s ABI. You can get the known capabilities ABIs from the public `capabilities` endpoint.
         * @summary Verify capabilities for a token contract
         * @param {VerifyTokenContractCapabilitiesInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCapabilitiesPost(body?: VerifyTokenContractCapabilitiesInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapabilitiesOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCapabilitiesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * The contract _must_ have one of the following capabilities: * `ERC721` * `ERC1155`  Otherwise, the contract will be rejected.  In order to be able to mint items through the platform, the contract _should_ have one of the following capabilities: * `ADMIN_MINT_SINGLE` (for ERC721) * `MINT_VOUCHER` (for ERC721) * `ADMIN_MINT_SINGLE_URI` (for ERC721) * `ADMIN_MINT_MULTIPLE_NO_ID` (for ERC721) * `ADMIN_MINT_MULTIPLE`  (for ERC1155)  Capabilities are determined by the contract\'s ABI. You can get the known capabilities ABIs from the public `capabilities` endpoint.
         * @summary Verify capabilities for a token contract
         * @param {VerifyTokenContractCapabilitiesInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCapabilitiesPost(body?: VerifyTokenContractCapabilitiesInput, options?: any): AxiosPromise<CapabilitiesOutput> {
            return localVarFp.contractCapabilitiesPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * The contract _must_ have one of the following capabilities: * `ERC721` * `ERC1155`  Otherwise, the contract will be rejected.  In order to be able to mint items through the platform, the contract _should_ have one of the following capabilities: * `ADMIN_MINT_SINGLE` (for ERC721) * `MINT_VOUCHER` (for ERC721) * `ADMIN_MINT_SINGLE_URI` (for ERC721) * `ADMIN_MINT_MULTIPLE_NO_ID` (for ERC721) * `ADMIN_MINT_MULTIPLE`  (for ERC1155)  Capabilities are determined by the contract\'s ABI. You can get the known capabilities ABIs from the public `capabilities` endpoint.
     * @summary Verify capabilities for a token contract
     * @param {VerifyTokenContractCapabilitiesInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCapabilitiesPost(body?: VerifyTokenContractCapabilitiesInput, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractCapabilitiesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This returns information about whether an organization is set up with auth0.
         * @summary Get user organizations by email
         * @param {string} email Email
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsOrganizationsGet: async (email: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('credentialsOrganizationsGet', 'email', email)
            const localVarPath = `/credentials/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost: async (platformStripeAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'platformStripeAccountId' is not null or undefined
            assertParamExists('paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost', 'platformStripeAccountId', platformStripeAccountId)
            const localVarPath = `/payment-integrations/stripe/{platform_stripe_account_id}/events/account`
                .replace(`{${"platform_stripe_account_id"}}`, encodeURIComponent(String(platformStripeAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost: async (platformStripeAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'platformStripeAccountId' is not null or undefined
            assertParamExists('paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost', 'platformStripeAccountId', platformStripeAccountId)
            const localVarPath = `/payment-integrations/stripe/{platform_stripe_account_id}/events/connect`
                .replace(`{${"platform_stripe_account_id"}}`, encodeURIComponent(String(platformStripeAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect to Stripe onboarding page
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeRefreshIntegrationIdGet: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('paymentIntegrationsStripeRefreshIntegrationIdGet', 'integrationId', integrationId)
            const localVarPath = `/payment-integrations/stripe/refresh/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect to Stripe onboarding page
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeResultIntegrationIdGet: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('paymentIntegrationsStripeResultIntegrationIdGet', 'integrationId', integrationId)
            const localVarPath = `/payment-integrations/stripe/result/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * This returns information about whether an organization is set up with auth0.
         * @summary Get user organizations by email
         * @param {string} email Email
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsOrganizationsGet(email: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicOrganizationsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsOrganizationsGet(email, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost(platformStripeAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost(platformStripeAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost(platformStripeAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost(platformStripeAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Redirect to Stripe onboarding page
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeRefreshIntegrationIdGet(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeRefreshIntegrationIdGet(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Redirect to Stripe onboarding page
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeResultIntegrationIdGet(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeResultIntegrationIdGet(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * This returns information about whether an organization is set up with auth0.
         * @summary Get user organizations by email
         * @param {string} email Email
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsOrganizationsGet(email: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PublicOrganizationsPaginatedOutput> {
            return localVarFp.credentialsOrganizationsGet(email, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost(platformStripeAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost(platformStripeAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost(platformStripeAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost(platformStripeAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redirect to Stripe onboarding page
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeRefreshIntegrationIdGet(integrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntegrationsStripeRefreshIntegrationIdGet(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redirect to Stripe onboarding page
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeResultIntegrationIdGet(integrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntegrationsStripeResultIntegrationIdGet(integrationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * This returns information about whether an organization is set up with auth0.
     * @summary Get user organizations by email
     * @param {string} email Email
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public credentialsOrganizationsGet(email: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).credentialsOrganizationsGet(email, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} platformStripeAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost(platformStripeAccountId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentIntegrationsStripePlatformStripeAccountIdEventsAccountPost(platformStripeAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} platformStripeAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost(platformStripeAccountId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentIntegrationsStripePlatformStripeAccountIdEventsConnectPost(platformStripeAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redirect to Stripe onboarding page
     * @param {string} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentIntegrationsStripeRefreshIntegrationIdGet(integrationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentIntegrationsStripeRefreshIntegrationIdGet(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redirect to Stripe onboarding page
     * @param {string} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentIntegrationsStripeResultIntegrationIdGet(integrationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentIntegrationsStripeResultIntegrationIdGet(integrationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailClaimApi - axios parameter creator
 * @export
 */
export const EmailClaimApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Revokes an email claim with the given id
         * @param {string} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimIdDelete: async (claimId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('emailClaimsClaimIdDelete', 'claimId', claimId)
            const localVarPath = `/email-claims/{claim_id}`
                .replace(`{${"claim_id"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get email claim by id
         * @param {string} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimIdGet: async (claimId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('emailClaimsClaimIdGet', 'claimId', claimId)
            const localVarPath = `/email-claims/{claim_id}`
                .replace(`{${"claim_id"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resends an email claim with the given id.
         * @param {string} claimId 
         * @param {ResendEmailClaimInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimIdResendPost: async (claimId: string, body?: ResendEmailClaimInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('emailClaimsClaimIdResendPost', 'claimId', claimId)
            const localVarPath = `/email-claims/{claim_id}/resend`
                .replace(`{${"claim_id"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all email claims
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<string>} [collectionIds] 
         * @param {Array<string>} [itemIds] 
         * @param {Array<string>} [tokenIds] 
         * @param {string} [email] Email
         * @param {boolean} [claimed] 
         * @param {boolean} [active] 
         * @param {Array<string>} [transactionId] 
         * @param {'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionIds?: Array<string>, itemIds?: Array<string>, tokenIds?: Array<string>, email?: string, claimed?: boolean, active?: boolean, transactionId?: Array<string>, orderBy?: 'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (collectionIds) {
                localVarQueryParameter['collection_ids'] = collectionIds;
            }

            if (itemIds) {
                localVarQueryParameter['item_ids'] = itemIds;
            }

            if (tokenIds) {
                localVarQueryParameter['token_ids'] = tokenIds;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (claimed !== undefined) {
                localVarQueryParameter['claimed'] = claimed;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (transactionId) {
                localVarQueryParameter['transaction_id'] = transactionId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new email claim
         * @param {CreateEmailClaimInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsPost: async (body?: CreateEmailClaimInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailClaimApi - functional programming interface
 * @export
 */
export const EmailClaimApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailClaimApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Revokes an email claim with the given id
         * @param {string} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsClaimIdDelete(claimId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsClaimIdDelete(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get email claim by id
         * @param {string} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsClaimIdGet(claimId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailClaim>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsClaimIdGet(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resends an email claim with the given id.
         * @param {string} claimId 
         * @param {ResendEmailClaimInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsClaimIdResendPost(claimId: string, body?: ResendEmailClaimInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailClaim>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsClaimIdResendPost(claimId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all email claims
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<string>} [collectionIds] 
         * @param {Array<string>} [itemIds] 
         * @param {Array<string>} [tokenIds] 
         * @param {string} [email] Email
         * @param {boolean} [claimed] 
         * @param {boolean} [active] 
         * @param {Array<string>} [transactionId] 
         * @param {'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionIds?: Array<string>, itemIds?: Array<string>, tokenIds?: Array<string>, email?: string, claimed?: boolean, active?: boolean, transactionId?: Array<string>, orderBy?: 'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailClaimsPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsGet(orderDirection, cursor, limit, collectionIds, itemIds, tokenIds, email, claimed, active, transactionId, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new email claim
         * @param {CreateEmailClaimInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsPost(body?: CreateEmailClaimInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailClaim>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailClaimApi - factory interface
 * @export
 */
export const EmailClaimApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailClaimApiFp(configuration)
    return {
        /**
         * 
         * @summary Revokes an email claim with the given id
         * @param {string} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimIdDelete(claimId: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailClaimsClaimIdDelete(claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get email claim by id
         * @param {string} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimIdGet(claimId: string, options?: any): AxiosPromise<EmailClaim> {
            return localVarFp.emailClaimsClaimIdGet(claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resends an email claim with the given id.
         * @param {string} claimId 
         * @param {ResendEmailClaimInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimIdResendPost(claimId: string, body?: ResendEmailClaimInput, options?: any): AxiosPromise<EmailClaim> {
            return localVarFp.emailClaimsClaimIdResendPost(claimId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all email claims
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<string>} [collectionIds] 
         * @param {Array<string>} [itemIds] 
         * @param {Array<string>} [tokenIds] 
         * @param {string} [email] Email
         * @param {boolean} [claimed] 
         * @param {boolean} [active] 
         * @param {Array<string>} [transactionId] 
         * @param {'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null'} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionIds?: Array<string>, itemIds?: Array<string>, tokenIds?: Array<string>, email?: string, claimed?: boolean, active?: boolean, transactionId?: Array<string>, orderBy?: 'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null', options?: any): AxiosPromise<EmailClaimsPaginatedResult> {
            return localVarFp.emailClaimsGet(orderDirection, cursor, limit, collectionIds, itemIds, tokenIds, email, claimed, active, transactionId, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new email claim
         * @param {CreateEmailClaimInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsPost(body?: CreateEmailClaimInput, options?: any): AxiosPromise<EmailClaim> {
            return localVarFp.emailClaimsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailClaimApi - object-oriented interface
 * @export
 * @class EmailClaimApi
 * @extends {BaseAPI}
 */
export class EmailClaimApi extends BaseAPI {
    /**
     * 
     * @summary Revokes an email claim with the given id
     * @param {string} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailClaimApi
     */
    public emailClaimsClaimIdDelete(claimId: string, options?: AxiosRequestConfig) {
        return EmailClaimApiFp(this.configuration).emailClaimsClaimIdDelete(claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get email claim by id
     * @param {string} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailClaimApi
     */
    public emailClaimsClaimIdGet(claimId: string, options?: AxiosRequestConfig) {
        return EmailClaimApiFp(this.configuration).emailClaimsClaimIdGet(claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resends an email claim with the given id.
     * @param {string} claimId 
     * @param {ResendEmailClaimInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailClaimApi
     */
    public emailClaimsClaimIdResendPost(claimId: string, body?: ResendEmailClaimInput, options?: AxiosRequestConfig) {
        return EmailClaimApiFp(this.configuration).emailClaimsClaimIdResendPost(claimId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all email claims
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {Array<string>} [collectionIds] 
     * @param {Array<string>} [itemIds] 
     * @param {Array<string>} [tokenIds] 
     * @param {string} [email] Email
     * @param {boolean} [claimed] 
     * @param {boolean} [active] 
     * @param {Array<string>} [transactionId] 
     * @param {'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null'} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailClaimApi
     */
    public emailClaimsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionIds?: Array<string>, itemIds?: Array<string>, tokenIds?: Array<string>, email?: string, claimed?: boolean, active?: boolean, transactionId?: Array<string>, orderBy?: 'CREATED_AT' | 'EXPIRATION' | 'CLAIMED_AT' | 'null', options?: AxiosRequestConfig) {
        return EmailClaimApiFp(this.configuration).emailClaimsGet(orderDirection, cursor, limit, collectionIds, itemIds, tokenIds, email, claimed, active, transactionId, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new email claim
     * @param {CreateEmailClaimInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailClaimApi
     */
    public emailClaimsPost(body?: CreateEmailClaimInput, options?: AxiosRequestConfig) {
        return EmailClaimApiFp(this.configuration).emailClaimsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailIntegrationApi - axios parameter creator
 * @export
 */
export const EmailIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all email integration configs for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [provider] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, provider?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an organization email config
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsIntegrationIdDelete: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('emailIntegrationsIntegrationIdDelete', 'integrationId', integrationId)
            const localVarPath = `/email-integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization email config by id
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsIntegrationIdGet: async (integrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('emailIntegrationsIntegrationIdGet', 'integrationId', integrationId)
            const localVarPath = `/email-integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit an organization email config
         * @param {string} integrationId 
         * @param {EmailIntegrationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsIntegrationIdPatch: async (integrationId: string, body?: EmailIntegrationInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('emailIntegrationsIntegrationIdPatch', 'integrationId', integrationId)
            const localVarPath = `/email-integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new organization email config
         * @param {EmailIntegrationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsPost: async (body?: EmailIntegrationInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates and sends a new test mail
         * @param {SendTestEmailInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsSendTestPost: async (body?: SendTestEmailInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-integrations/send-test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailIntegrationApi - functional programming interface
 * @export
 */
export const EmailIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all email integration configs for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [provider] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailIntegrationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, provider?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailIntegrationsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailIntegrationsGet(orderDirection, cursor, limit, provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an organization email config
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailIntegrationsIntegrationIdDelete(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailIntegrationsIntegrationIdDelete(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organization email config by id
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailIntegrationsIntegrationIdGet(integrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailIntegrationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailIntegrationsIntegrationIdGet(integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit an organization email config
         * @param {string} integrationId 
         * @param {EmailIntegrationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailIntegrationsIntegrationIdPatch(integrationId: string, body?: EmailIntegrationInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailIntegrationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailIntegrationsIntegrationIdPatch(integrationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new organization email config
         * @param {EmailIntegrationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailIntegrationsPost(body?: EmailIntegrationInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailIntegrationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailIntegrationsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates and sends a new test mail
         * @param {SendTestEmailInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailIntegrationsSendTestPost(body?: SendTestEmailInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailIntegrationsSendTestPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailIntegrationApi - factory interface
 * @export
 */
export const EmailIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all email integration configs for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [provider] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, provider?: string, options?: any): AxiosPromise<EmailIntegrationsPaginatedOutput> {
            return localVarFp.emailIntegrationsGet(orderDirection, cursor, limit, provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an organization email config
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsIntegrationIdDelete(integrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailIntegrationsIntegrationIdDelete(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization email config by id
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsIntegrationIdGet(integrationId: string, options?: any): AxiosPromise<EmailIntegrationOutput> {
            return localVarFp.emailIntegrationsIntegrationIdGet(integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit an organization email config
         * @param {string} integrationId 
         * @param {EmailIntegrationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsIntegrationIdPatch(integrationId: string, body?: EmailIntegrationInput, options?: any): AxiosPromise<EmailIntegrationOutput> {
            return localVarFp.emailIntegrationsIntegrationIdPatch(integrationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new organization email config
         * @param {EmailIntegrationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsPost(body?: EmailIntegrationInput, options?: any): AxiosPromise<EmailIntegrationOutput> {
            return localVarFp.emailIntegrationsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates and sends a new test mail
         * @param {SendTestEmailInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailIntegrationsSendTestPost(body?: SendTestEmailInput, options?: any): AxiosPromise<void> {
            return localVarFp.emailIntegrationsSendTestPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailIntegrationApi - object-oriented interface
 * @export
 * @class EmailIntegrationApi
 * @extends {BaseAPI}
 */
export class EmailIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Get all email integration configs for an organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {string} [provider] SafeString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailIntegrationApi
     */
    public emailIntegrationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, provider?: string, options?: AxiosRequestConfig) {
        return EmailIntegrationApiFp(this.configuration).emailIntegrationsGet(orderDirection, cursor, limit, provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an organization email config
     * @param {string} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailIntegrationApi
     */
    public emailIntegrationsIntegrationIdDelete(integrationId: string, options?: AxiosRequestConfig) {
        return EmailIntegrationApiFp(this.configuration).emailIntegrationsIntegrationIdDelete(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization email config by id
     * @param {string} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailIntegrationApi
     */
    public emailIntegrationsIntegrationIdGet(integrationId: string, options?: AxiosRequestConfig) {
        return EmailIntegrationApiFp(this.configuration).emailIntegrationsIntegrationIdGet(integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit an organization email config
     * @param {string} integrationId 
     * @param {EmailIntegrationInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailIntegrationApi
     */
    public emailIntegrationsIntegrationIdPatch(integrationId: string, body?: EmailIntegrationInput, options?: AxiosRequestConfig) {
        return EmailIntegrationApiFp(this.configuration).emailIntegrationsIntegrationIdPatch(integrationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new organization email config
     * @param {EmailIntegrationInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailIntegrationApi
     */
    public emailIntegrationsPost(body?: EmailIntegrationInput, options?: AxiosRequestConfig) {
        return EmailIntegrationApiFp(this.configuration).emailIntegrationsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates and sends a new test mail
     * @param {SendTestEmailInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailIntegrationApi
     */
    public emailIntegrationsSendTestPost(body?: SendTestEmailInput, options?: AxiosRequestConfig) {
        return EmailIntegrationApiFp(this.configuration).emailIntegrationsSendTestPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailTemplateApi - axios parameter creator
 * @export
 */
export const EmailTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all the customized email templates for the organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If a campaign id is provided, it searches and deletes the email template for the campaign. If no campaign id provided, it searches and deletes the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
         * @summary Delete a customized email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {GetCustomizedEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeDelete: async (templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: GetCustomizedEmailTemplateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypeDelete', 'templateType', templateType)
            const localVarPath = `/email-templates/{template_type}`
                .replace(`{${"template_type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If a campaign id is provided, it returns the customized email template of this type from the campaign. If no campaign id provided, it returns the customized email template of this type from the DEFAULT campaign. If no customized templates are found, it returns the platform default for this type.
         * @summary Get a customizable email template of the given type for the organization
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeGet: async (templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypeGet', 'templateType', templateType)
            const localVarPath = `/email-templates/{template_type}`
                .replace(`{${"template_type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If a campaign id is provided, it searches and updates the email template for the campaign. If no campaign id provided, it searches and updates the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
         * @summary Update a customized email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {EditOrganizationEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypePatch: async (templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: EditOrganizationEmailTemplateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypePatch', 'templateType', templateType)
            const localVarPath = `/email-templates/{template_type}`
                .replace(`{${"template_type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If a campaign id is provided, it creates a new email template of this type for the campaign. If no campaign id provided, it creates a new email template of this type for the DEFAULT campaign.
         * @summary Create a new email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {CreateOrganizationEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypePost: async (templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypePost', 'templateType', templateType)
            const localVarPath = `/email-templates/{template_type}`
                .replace(`{${"template_type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If a campaign id is provided, it sends a test email of this template type from the campaign. If no campaign id provided, it sends a test email of this template type from the DEFAULT campaign. If no customized templates are found, it uses the platform default for this type.
         * @summary Send a test mail of a given template type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {CreateOrganizationEmailTemplateTestMailInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeSendTestPost: async (templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateTestMailInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypeSendTestPost', 'templateType', templateType)
            const localVarPath = `/email-templates/{template_type}/send-test`
                .replace(`{${"template_type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Variables are derived from the platform default. Variables used in the templates should be wrapped in double curly brackets.
         * @summary Get all the variables for a specific template
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeVariablesGet: async (templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypeVariablesGet', 'templateType', templateType)
            const localVarPath = `/email-templates/{template_type}/variables`
                .replace(`{${"template_type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the customizable email template types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-templates/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplateApi - functional programming interface
 * @export
 */
export const EmailTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all the customized email templates for the organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplatesPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesGet(orderDirection, cursor, limit, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If a campaign id is provided, it searches and deletes the email template for the campaign. If no campaign id provided, it searches and deletes the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
         * @summary Delete a customized email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {GetCustomizedEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypeDelete(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: GetCustomizedEmailTemplateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypeDelete(templateType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If a campaign id is provided, it returns the customized email template of this type from the campaign. If no campaign id provided, it returns the customized email template of this type from the DEFAULT campaign. If no customized templates are found, it returns the platform default for this type.
         * @summary Get a customizable email template of the given type for the organization
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypeGet(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationEmailTemplateDomainModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypeGet(templateType, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If a campaign id is provided, it searches and updates the email template for the campaign. If no campaign id provided, it searches and updates the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
         * @summary Update a customized email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {EditOrganizationEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypePatch(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: EditOrganizationEmailTemplateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationEmailTemplateDomainModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypePatch(templateType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If a campaign id is provided, it creates a new email template of this type for the campaign. If no campaign id provided, it creates a new email template of this type for the DEFAULT campaign.
         * @summary Create a new email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {CreateOrganizationEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypePost(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationEmailTemplateDomainModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypePost(templateType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If a campaign id is provided, it sends a test email of this template type from the campaign. If no campaign id provided, it sends a test email of this template type from the DEFAULT campaign. If no customized templates are found, it uses the platform default for this type.
         * @summary Send a test mail of a given template type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {CreateOrganizationEmailTemplateTestMailInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypeSendTestPost(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateTestMailInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypeSendTestPost(templateType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Variables are derived from the platform default. Variables used in the templates should be wrapped in double curly brackets.
         * @summary Get all the variables for a specific template
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypeVariablesGet(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateVariablesPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypeVariablesGet(templateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the customizable email template types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateTypesPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplateApi - factory interface
 * @export
 */
export const EmailTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all the customized email templates for the organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, campaignId?: string, options?: any): AxiosPromise<EmailTemplatesPaginatedOutput> {
            return localVarFp.emailTemplatesGet(orderDirection, cursor, limit, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * If a campaign id is provided, it searches and deletes the email template for the campaign. If no campaign id provided, it searches and deletes the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
         * @summary Delete a customized email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {GetCustomizedEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeDelete(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: GetCustomizedEmailTemplateInput, options?: any): AxiosPromise<void> {
            return localVarFp.emailTemplatesTemplateTypeDelete(templateType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * If a campaign id is provided, it returns the customized email template of this type from the campaign. If no campaign id provided, it returns the customized email template of this type from the DEFAULT campaign. If no customized templates are found, it returns the platform default for this type.
         * @summary Get a customizable email template of the given type for the organization
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeGet(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', campaignId?: string, options?: any): AxiosPromise<OrganizationEmailTemplateDomainModel> {
            return localVarFp.emailTemplatesTemplateTypeGet(templateType, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * If a campaign id is provided, it searches and updates the email template for the campaign. If no campaign id provided, it searches and updates the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
         * @summary Update a customized email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {EditOrganizationEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypePatch(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: EditOrganizationEmailTemplateInput, options?: any): AxiosPromise<OrganizationEmailTemplateDomainModel> {
            return localVarFp.emailTemplatesTemplateTypePatch(templateType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * If a campaign id is provided, it creates a new email template of this type for the campaign. If no campaign id provided, it creates a new email template of this type for the DEFAULT campaign.
         * @summary Create a new email template of the given type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {CreateOrganizationEmailTemplateInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypePost(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateInput, options?: any): AxiosPromise<OrganizationEmailTemplateDomainModel> {
            return localVarFp.emailTemplatesTemplateTypePost(templateType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * If a campaign id is provided, it sends a test email of this template type from the campaign. If no campaign id provided, it sends a test email of this template type from the DEFAULT campaign. If no customized templates are found, it uses the platform default for this type.
         * @summary Send a test mail of a given template type
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {CreateOrganizationEmailTemplateTestMailInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeSendTestPost(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateTestMailInput, options?: any): AxiosPromise<void> {
            return localVarFp.emailTemplatesTemplateTypeSendTestPost(templateType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Variables are derived from the platform default. Variables used in the templates should be wrapped in double curly brackets.
         * @summary Get all the variables for a specific template
         * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeVariablesGet(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', options?: any): AxiosPromise<EmailTemplateVariablesPaginatedOutput> {
            return localVarFp.emailTemplatesTemplateTypeVariablesGet(templateType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the customizable email template types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTypesGet(options?: any): AxiosPromise<EmailTemplateTypesPaginatedOutput> {
            return localVarFp.emailTemplatesTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplateApi - object-oriented interface
 * @export
 * @class EmailTemplateApi
 * @extends {BaseAPI}
 */
export class EmailTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Get all the customized email templates for the organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, campaignId?: string, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesGet(orderDirection, cursor, limit, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If a campaign id is provided, it searches and deletes the email template for the campaign. If no campaign id provided, it searches and deletes the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
     * @summary Delete a customized email template of the given type
     * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
     * @param {GetCustomizedEmailTemplateInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTemplateTypeDelete(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: GetCustomizedEmailTemplateInput, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTemplateTypeDelete(templateType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If a campaign id is provided, it returns the customized email template of this type from the campaign. If no campaign id provided, it returns the customized email template of this type from the DEFAULT campaign. If no customized templates are found, it returns the platform default for this type.
     * @summary Get a customizable email template of the given type for the organization
     * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTemplateTypeGet(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', campaignId?: string, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTemplateTypeGet(templateType, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If a campaign id is provided, it searches and updates the email template for the campaign. If no campaign id provided, it searches and updates the email template for the DEFAULT campaign. If no customized email template of this type can be found, it raises an exception.
     * @summary Update a customized email template of the given type
     * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
     * @param {EditOrganizationEmailTemplateInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTemplateTypePatch(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: EditOrganizationEmailTemplateInput, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTemplateTypePatch(templateType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If a campaign id is provided, it creates a new email template of this type for the campaign. If no campaign id provided, it creates a new email template of this type for the DEFAULT campaign.
     * @summary Create a new email template of the given type
     * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
     * @param {CreateOrganizationEmailTemplateInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTemplateTypePost(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateInput, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTemplateTypePost(templateType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If a campaign id is provided, it sends a test email of this template type from the campaign. If no campaign id provided, it sends a test email of this template type from the DEFAULT campaign. If no customized templates are found, it uses the platform default for this type.
     * @summary Send a test mail of a given template type
     * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
     * @param {CreateOrganizationEmailTemplateTestMailInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTemplateTypeSendTestPost(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', body?: CreateOrganizationEmailTemplateTestMailInput, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTemplateTypeSendTestPost(templateType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Variables are derived from the platform default. Variables used in the templates should be wrapped in double curly brackets.
     * @summary Get all the variables for a specific template
     * @param {'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM'} templateType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTemplateTypeVariablesGet(templateType: 'USER_INVITE' | 'WELCOME' | 'INSUFFICIENT_BALANCE' | 'FORGOT_PASSWORD_RESET' | 'REDEEM_NFT' | 'CLAIM_PINCODE' | 'CLAIMED_NFT' | 'USER_TEST' | 'CUSTOM', options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTemplateTypeVariablesGet(templateType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the customizable email template types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public emailTemplatesTypesGet(options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).emailTemplatesTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Edit item metadata associated with an existing item
         * @param {EditItemsInBulkBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsBulkPatch: async (body?: EditItemsInBulkBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a bulk set of items associated with the given collection
         * @param {CreateItemsInBulkBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsBulkPost: async (body?: CreateItemsInBulkBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all items associated with this collection
         * @param {string} collectionId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<string>} [tokenId] 
         * @param {Array<string>} [attributes] 
         * @param {boolean} [locked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsGet: async (collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, tokenId?: Array<string>, attributes?: Array<string>, locked?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('itemsGet', 'collectionId', collectionId)
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (tokenId) {
                localVarQueryParameter['token_id'] = tokenId;
            }

            if (attributes) {
                localVarQueryParameter['attributes'] = attributes;
            }

            if (locked !== undefined) {
                localVarQueryParameter['locked'] = locked;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdDelete: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdDelete', 'itemId', itemId)
            const localVarPath = `/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdGet: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
         * @summary Request a metadata refresh
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdMetadataDelete: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdMetadataDelete', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/metadata`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a full update -- all attributes specified will overwrite ALL existing attributes and item-type specific attributes must be valid against the item type schema. (if any) Attributes cannot be updated once an item has been minted, unless the collection has editable_metadata=True.
         * @summary Update an item
         * @param {string} itemId 
         * @param {UpdateItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdPatch: async (itemId: string, body?: UpdateItemInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdPatch', 'itemId', itemId)
            const localVarPath = `/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an item\'s status by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdStatusGet: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdStatusGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/status`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * There are three ways to specify the items to lock. When specifying individual items, they should all be unlocked, else the request will fail.  1. `item_id` - Locks a single item  2. `item_ids` - Locks a list of items by their ids.  3. `collection_id` - Locks all remaining unlocked items in a collection.  For the `max_supply` parameter:   - (for `ERC721\'s`) it should always be 1. If not specified, then it will be coerced to 1.   - (for `ERC1155\'s`) it should be greater than 0. If not specified, then it will be coerced to the max value of a web3 uint128.  For the `token_id` parameter:   - This can only be done for a single item and if the token assignment strategy of the collection is set to `MANUAL`.   - If left out, the token assignment strategy of the collection should be set to either `AUTOMATIC` or `EXTERNAL`.  Once an item has been locked the following actions will be available:   - Listing   - Minting   - Transferring   - Sending via an email claim
         * @summary Request the lock of one or more items.
         * @param {LockItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsLockPost: async (body?: LockItemInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new item associated with the given collection
         * @param {CreateItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsPost: async (body?: CreateItemInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated item mint request details.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [toAddress] ChecksumAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintRequestsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mint-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (toAddress !== undefined) {
                localVarQueryParameter['to_address'] = toAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the item mint request details.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintRequestsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mintRequestsIdGet', 'id', id)
            const localVarPath = `/mint-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This submits a request to mint one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to mint:  1. `item_id` - Mint a single item  2. `item_ids` - Mint a list of items by their ids.  3. `collection_id` - Mint all locked and un-minted items in a collection.  Reserved items (email claims, listings) will not be considered when minting.  4. `collection_id` & `token_ids` - Mint a list of items by their token ids.  5. `collection_id` & `token_ranges` - Mint a list of items by token ranges.    All items must be locked and not currently minted, else the request will fail.    When minting, a `to_address` must be specified.  This is the address that will receive the newly minted items.  There is currently a limit of 100 items that can be minted at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to mint more than 100 items, you will need to make multiple requests.
         * @summary Request the mint of one or more items.
         * @param {MintRequestInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintRequestsPost: async (body?: MintRequestInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mint-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the item transfer request transaction details.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [toAddress] ChecksumAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferRequestsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transfer-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (toAddress !== undefined) {
                localVarQueryParameter['to_address'] = toAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the item transfer request transaction details.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferRequestsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('transferRequestsIdGet', 'id', id)
            const localVarPath = `/transfer-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This submits a request to transfer one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to transfer:  1. `item_id` - Transfers a single item  2. `item_ids` - Transfers a list of items by their ids.  3. `collection_id` - Transfers all locked and minted items in a collection.  Reserved items (email claims, listings) will not be considered when transferring.  4. `collection_id` & `token_ids` - Transfers a list of items by their token ids.  5. `collection_id` & `token_ranges` - Transfers a list of items by token ranges.    All items must be locked and minted, else the request will fail.    When transferring, a `to_address` must be specified.  This is the address that will receive the items.  There is currently a limit of 100 items that can be transferred at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to transfer more than 100 items, you will need to make multiple requests.
         * @summary Request the transfer of one or more items.
         * @param {TransferRequestInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferRequestsPost: async (body?: TransferRequestInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transfer-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Edit item metadata associated with an existing item
         * @param {EditItemsInBulkBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsBulkPatch(body?: EditItemsInBulkBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUpdateItemOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsBulkPatch(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a bulk set of items associated with the given collection
         * @param {CreateItemsInBulkBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsBulkPost(body?: CreateItemsInBulkBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBulkItemApiOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsBulkPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all items associated with this collection
         * @param {string} collectionId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<string>} [tokenId] 
         * @param {Array<string>} [attributes] 
         * @param {boolean} [locked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsGet(collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, tokenId?: Array<string>, attributes?: Array<string>, locked?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsGet(collectionId, orderDirection, cursor, limit, tokenId, attributes, locked, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdDelete(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdDelete(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdGet(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
         * @summary Request a metadata refresh
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdMetadataDelete(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdMetadataDelete(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is a full update -- all attributes specified will overwrite ALL existing attributes and item-type specific attributes must be valid against the item type schema. (if any) Attributes cannot be updated once an item has been minted, unless the collection has editable_metadata=True.
         * @summary Update an item
         * @param {string} itemId 
         * @param {UpdateItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdPatch(itemId: string, body?: UpdateItemInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdPatch(itemId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an item\'s status by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdStatusGet(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemStatusOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdStatusGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * There are three ways to specify the items to lock. When specifying individual items, they should all be unlocked, else the request will fail.  1. `item_id` - Locks a single item  2. `item_ids` - Locks a list of items by their ids.  3. `collection_id` - Locks all remaining unlocked items in a collection.  For the `max_supply` parameter:   - (for `ERC721\'s`) it should always be 1. If not specified, then it will be coerced to 1.   - (for `ERC1155\'s`) it should be greater than 0. If not specified, then it will be coerced to the max value of a web3 uint128.  For the `token_id` parameter:   - This can only be done for a single item and if the token assignment strategy of the collection is set to `MANUAL`.   - If left out, the token assignment strategy of the collection should be set to either `AUTOMATIC` or `EXTERNAL`.  Once an item has been locked the following actions will be available:   - Listing   - Minting   - Transferring   - Sending via an email claim
         * @summary Request the lock of one or more items.
         * @param {LockItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsLockPost(body?: LockItemInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockItemOutputEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsLockPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new item associated with the given collection
         * @param {CreateItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsPost(body?: CreateItemInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated item mint request details.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [toAddress] ChecksumAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mintRequestsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MintRequestsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mintRequestsGet(orderDirection, cursor, limit, itemId, collectionId, toAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the item mint request details.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mintRequestsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MintRequestOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mintRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This submits a request to mint one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to mint:  1. `item_id` - Mint a single item  2. `item_ids` - Mint a list of items by their ids.  3. `collection_id` - Mint all locked and un-minted items in a collection.  Reserved items (email claims, listings) will not be considered when minting.  4. `collection_id` & `token_ids` - Mint a list of items by their token ids.  5. `collection_id` & `token_ranges` - Mint a list of items by token ranges.    All items must be locked and not currently minted, else the request will fail.    When minting, a `to_address` must be specified.  This is the address that will receive the newly minted items.  There is currently a limit of 100 items that can be minted at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to mint more than 100 items, you will need to make multiple requests.
         * @summary Request the mint of one or more items.
         * @param {MintRequestInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mintRequestsPost(body?: MintRequestInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MintRequestOutputEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mintRequestsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the item transfer request transaction details.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [toAddress] ChecksumAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferRequestsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferRequestsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferRequestsGet(orderDirection, cursor, limit, itemId, collectionId, toAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the item transfer request transaction details.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferRequestsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferRequestOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This submits a request to transfer one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to transfer:  1. `item_id` - Transfers a single item  2. `item_ids` - Transfers a list of items by their ids.  3. `collection_id` - Transfers all locked and minted items in a collection.  Reserved items (email claims, listings) will not be considered when transferring.  4. `collection_id` & `token_ids` - Transfers a list of items by their token ids.  5. `collection_id` & `token_ranges` - Transfers a list of items by token ranges.    All items must be locked and minted, else the request will fail.    When transferring, a `to_address` must be specified.  This is the address that will receive the items.  There is currently a limit of 100 items that can be transferred at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to transfer more than 100 items, you will need to make multiple requests.
         * @summary Request the transfer of one or more items.
         * @param {TransferRequestInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferRequestsPost(body?: TransferRequestInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferRequestOutputEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferRequestsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Edit item metadata associated with an existing item
         * @param {EditItemsInBulkBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsBulkPatch(body?: EditItemsInBulkBody, options?: any): AxiosPromise<BulkUpdateItemOutput> {
            return localVarFp.itemsBulkPatch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a bulk set of items associated with the given collection
         * @param {CreateItemsInBulkBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsBulkPost(body?: CreateItemsInBulkBody, options?: any): AxiosPromise<CreateBulkItemApiOutput> {
            return localVarFp.itemsBulkPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all items associated with this collection
         * @param {string} collectionId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<string>} [tokenId] 
         * @param {Array<string>} [attributes] 
         * @param {boolean} [locked] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsGet(collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, tokenId?: Array<string>, attributes?: Array<string>, locked?: boolean, options?: any): AxiosPromise<ItemsPaginatedOutput> {
            return localVarFp.itemsGet(collectionId, orderDirection, cursor, limit, tokenId, attributes, locked, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdDelete(itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.itemsItemIdDelete(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdGet(itemId: string, options?: any): AxiosPromise<ItemOutput> {
            return localVarFp.itemsItemIdGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
         * @summary Request a metadata refresh
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdMetadataDelete(itemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.itemsItemIdMetadataDelete(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a full update -- all attributes specified will overwrite ALL existing attributes and item-type specific attributes must be valid against the item type schema. (if any) Attributes cannot be updated once an item has been minted, unless the collection has editable_metadata=True.
         * @summary Update an item
         * @param {string} itemId 
         * @param {UpdateItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdPatch(itemId: string, body?: UpdateItemInput, options?: any): AxiosPromise<ItemOutput> {
            return localVarFp.itemsItemIdPatch(itemId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an item\'s status by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdStatusGet(itemId: string, options?: any): AxiosPromise<ItemStatusOutput> {
            return localVarFp.itemsItemIdStatusGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * There are three ways to specify the items to lock. When specifying individual items, they should all be unlocked, else the request will fail.  1. `item_id` - Locks a single item  2. `item_ids` - Locks a list of items by their ids.  3. `collection_id` - Locks all remaining unlocked items in a collection.  For the `max_supply` parameter:   - (for `ERC721\'s`) it should always be 1. If not specified, then it will be coerced to 1.   - (for `ERC1155\'s`) it should be greater than 0. If not specified, then it will be coerced to the max value of a web3 uint128.  For the `token_id` parameter:   - This can only be done for a single item and if the token assignment strategy of the collection is set to `MANUAL`.   - If left out, the token assignment strategy of the collection should be set to either `AUTOMATIC` or `EXTERNAL`.  Once an item has been locked the following actions will be available:   - Listing   - Minting   - Transferring   - Sending via an email claim
         * @summary Request the lock of one or more items.
         * @param {LockItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsLockPost(body?: LockItemInput, options?: any): AxiosPromise<LockItemOutputEnvelope> {
            return localVarFp.itemsLockPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new item associated with the given collection
         * @param {CreateItemInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsPost(body?: CreateItemInput, options?: any): AxiosPromise<ItemOutput> {
            return localVarFp.itemsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated item mint request details.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [toAddress] ChecksumAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintRequestsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options?: any): AxiosPromise<MintRequestsPaginatedOutput> {
            return localVarFp.mintRequestsGet(orderDirection, cursor, limit, itemId, collectionId, toAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the item mint request details.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintRequestsIdGet(id: string, options?: any): AxiosPromise<MintRequestOutput> {
            return localVarFp.mintRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This submits a request to mint one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to mint:  1. `item_id` - Mint a single item  2. `item_ids` - Mint a list of items by their ids.  3. `collection_id` - Mint all locked and un-minted items in a collection.  Reserved items (email claims, listings) will not be considered when minting.  4. `collection_id` & `token_ids` - Mint a list of items by their token ids.  5. `collection_id` & `token_ranges` - Mint a list of items by token ranges.    All items must be locked and not currently minted, else the request will fail.    When minting, a `to_address` must be specified.  This is the address that will receive the newly minted items.  There is currently a limit of 100 items that can be minted at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to mint more than 100 items, you will need to make multiple requests.
         * @summary Request the mint of one or more items.
         * @param {MintRequestInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintRequestsPost(body?: MintRequestInput, options?: any): AxiosPromise<MintRequestOutputEnvelope> {
            return localVarFp.mintRequestsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the item transfer request transaction details.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [toAddress] ChecksumAddress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferRequestsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options?: any): AxiosPromise<TransferRequestsPaginatedOutput> {
            return localVarFp.transferRequestsGet(orderDirection, cursor, limit, itemId, collectionId, toAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the item transfer request transaction details.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferRequestsIdGet(id: string, options?: any): AxiosPromise<TransferRequestOutput> {
            return localVarFp.transferRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This submits a request to transfer one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to transfer:  1. `item_id` - Transfers a single item  2. `item_ids` - Transfers a list of items by their ids.  3. `collection_id` - Transfers all locked and minted items in a collection.  Reserved items (email claims, listings) will not be considered when transferring.  4. `collection_id` & `token_ids` - Transfers a list of items by their token ids.  5. `collection_id` & `token_ranges` - Transfers a list of items by token ranges.    All items must be locked and minted, else the request will fail.    When transferring, a `to_address` must be specified.  This is the address that will receive the items.  There is currently a limit of 100 items that can be transferred at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to transfer more than 100 items, you will need to make multiple requests.
         * @summary Request the transfer of one or more items.
         * @param {TransferRequestInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferRequestsPost(body?: TransferRequestInput, options?: any): AxiosPromise<TransferRequestOutputEnvelope> {
            return localVarFp.transferRequestsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * 
     * @summary Edit item metadata associated with an existing item
     * @param {EditItemsInBulkBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsBulkPatch(body?: EditItemsInBulkBody, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsBulkPatch(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a bulk set of items associated with the given collection
     * @param {CreateItemsInBulkBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsBulkPost(body?: CreateItemsInBulkBody, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsBulkPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all items associated with this collection
     * @param {string} collectionId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {Array<string>} [tokenId] 
     * @param {Array<string>} [attributes] 
     * @param {boolean} [locked] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsGet(collectionId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, tokenId?: Array<string>, attributes?: Array<string>, locked?: boolean, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsGet(collectionId, orderDirection, cursor, limit, tokenId, attributes, locked, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete item by id
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsItemIdDelete(itemId: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsItemIdDelete(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an item by id
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsItemIdGet(itemId: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsItemIdGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This makes no guarantees on if/when the request will be made. This is only applicable to items linked to external contracts.
     * @summary Request a metadata refresh
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsItemIdMetadataDelete(itemId: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsItemIdMetadataDelete(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a full update -- all attributes specified will overwrite ALL existing attributes and item-type specific attributes must be valid against the item type schema. (if any) Attributes cannot be updated once an item has been minted, unless the collection has editable_metadata=True.
     * @summary Update an item
     * @param {string} itemId 
     * @param {UpdateItemInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsItemIdPatch(itemId: string, body?: UpdateItemInput, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsItemIdPatch(itemId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an item\'s status by id
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsItemIdStatusGet(itemId: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsItemIdStatusGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * There are three ways to specify the items to lock. When specifying individual items, they should all be unlocked, else the request will fail.  1. `item_id` - Locks a single item  2. `item_ids` - Locks a list of items by their ids.  3. `collection_id` - Locks all remaining unlocked items in a collection.  For the `max_supply` parameter:   - (for `ERC721\'s`) it should always be 1. If not specified, then it will be coerced to 1.   - (for `ERC1155\'s`) it should be greater than 0. If not specified, then it will be coerced to the max value of a web3 uint128.  For the `token_id` parameter:   - This can only be done for a single item and if the token assignment strategy of the collection is set to `MANUAL`.   - If left out, the token assignment strategy of the collection should be set to either `AUTOMATIC` or `EXTERNAL`.  Once an item has been locked the following actions will be available:   - Listing   - Minting   - Transferring   - Sending via an email claim
     * @summary Request the lock of one or more items.
     * @param {LockItemInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsLockPost(body?: LockItemInput, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsLockPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new item associated with the given collection
     * @param {CreateItemInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemsPost(body?: CreateItemInput, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).itemsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated item mint request details.
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId] 
     * @param {string} [collectionId] 
     * @param {string} [toAddress] ChecksumAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public mintRequestsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).mintRequestsGet(orderDirection, cursor, limit, itemId, collectionId, toAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the item mint request details.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public mintRequestsIdGet(id: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).mintRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This submits a request to mint one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to mint:  1. `item_id` - Mint a single item  2. `item_ids` - Mint a list of items by their ids.  3. `collection_id` - Mint all locked and un-minted items in a collection.  Reserved items (email claims, listings) will not be considered when minting.  4. `collection_id` & `token_ids` - Mint a list of items by their token ids.  5. `collection_id` & `token_ranges` - Mint a list of items by token ranges.    All items must be locked and not currently minted, else the request will fail.    When minting, a `to_address` must be specified.  This is the address that will receive the newly minted items.  There is currently a limit of 100 items that can be minted at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to mint more than 100 items, you will need to make multiple requests.
     * @summary Request the mint of one or more items.
     * @param {MintRequestInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public mintRequestsPost(body?: MintRequestInput, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).mintRequestsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the item transfer request transaction details.
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId] 
     * @param {string} [collectionId] 
     * @param {string} [toAddress] ChecksumAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public transferRequestsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, toAddress?: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).transferRequestsGet(orderDirection, cursor, limit, itemId, collectionId, toAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the item transfer request transaction details.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public transferRequestsIdGet(id: string, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).transferRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This submits a request to transfer one or more items and returns the transaction details on the newly created requests.  There are five ways to specify the items to transfer:  1. `item_id` - Transfers a single item  2. `item_ids` - Transfers a list of items by their ids.  3. `collection_id` - Transfers all locked and minted items in a collection.  Reserved items (email claims, listings) will not be considered when transferring.  4. `collection_id` & `token_ids` - Transfers a list of items by their token ids.  5. `collection_id` & `token_ranges` - Transfers a list of items by token ranges.    All items must be locked and minted, else the request will fail.    When transferring, a `to_address` must be specified.  This is the address that will receive the items.  There is currently a limit of 100 items that can be transferred at once.  When passing a set of individual item identifiers, the request will fail if you attempt to submit more than 100 items.  When passing `collection_id`, only the first 100 will be considered.  If you need to transfer more than 100 items, you will need to make multiple requests.
     * @summary Request the transfer of one or more items.
     * @param {TransferRequestInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public transferRequestsPost(body?: TransferRequestInput, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).transferRequestsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemTypeApi - axios parameter creator
 * @export
 */
export const ItemTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Only organization or platform admins have access to this data. If no organization ID is supplied, platform admins will get all item types. If no organization ID is supplied, an org admin will get the item types of his organization.
         * @summary Gets all item types for the organization of a specific organization ID (if supplied), or the calling user.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Only following item types can be deleted: - Specific to your organization. - Not associated with an item and/or collection.
         * @summary Delete a specific item type.
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesItemTypeIdDelete: async (itemTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemTypeId' is not null or undefined
            assertParamExists('itemTypesItemTypeIdDelete', 'itemTypeId', itemTypeId)
            const localVarPath = `/item-types/{item_type_id}`
                .replace(`{${"item_type_id"}}`, encodeURIComponent(String(itemTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific item type for an organization.
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesItemTypeIdGet: async (itemTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemTypeId' is not null or undefined
            assertParamExists('itemTypesItemTypeIdGet', 'itemTypeId', itemTypeId)
            const localVarPath = `/item-types/{item_type_id}`
                .replace(`{${"item_type_id"}}`, encodeURIComponent(String(itemTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits an existing item type for an organization.
         * @param {string} itemTypeId 
         * @param {EditItemTypeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesItemTypeIdPatch: async (itemTypeId: string, body?: EditItemTypeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemTypeId' is not null or undefined
            assertParamExists('itemTypesItemTypeIdPatch', 'itemTypeId', itemTypeId)
            const localVarPath = `/item-types/{item_type_id}`
                .replace(`{${"item_type_id"}}`, encodeURIComponent(String(itemTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Only organization or platform admins have access to this data.
         * @summary Get the meta schema (+example) which is used to validate an item type schema.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesMetaSchemaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-types/meta-schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If not supplied, the organization of the requesting user is used.
         * @summary Create a new item type for an organization.
         * @param {CreateItemTypeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesPost: async (body?: CreateItemTypeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemTypeApi - functional programming interface
 * @export
 */
export const ItemTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Only organization or platform admins have access to this data. If no organization ID is supplied, platform admins will get all item types. If no organization ID is supplied, an org admin will get the item types of his organization.
         * @summary Gets all item types for the organization of a specific organization ID (if supplied), or the calling user.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemTypesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemTypesPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemTypesGet(orderDirection, cursor, limit, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Only following item types can be deleted: - Specific to your organization. - Not associated with an item and/or collection.
         * @summary Delete a specific item type.
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemTypesItemTypeIdDelete(itemTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemTypesItemTypeIdDelete(itemTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific item type for an organization.
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemTypesItemTypeIdGet(itemTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemTypeDomainModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemTypesItemTypeIdGet(itemTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edits an existing item type for an organization.
         * @param {string} itemTypeId 
         * @param {EditItemTypeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemTypesItemTypeIdPatch(itemTypeId: string, body?: EditItemTypeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemTypeDomainModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemTypesItemTypeIdPatch(itemTypeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Only organization or platform admins have access to this data.
         * @summary Get the meta schema (+example) which is used to validate an item type schema.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemTypesMetaSchemaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemTypeMetaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemTypesMetaSchemaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If not supplied, the organization of the requesting user is used.
         * @summary Create a new item type for an organization.
         * @param {CreateItemTypeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemTypesPost(body?: CreateItemTypeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemTypeDomainModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemTypesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemTypeApi - factory interface
 * @export
 */
export const ItemTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemTypeApiFp(configuration)
    return {
        /**
         * Only organization or platform admins have access to this data. If no organization ID is supplied, platform admins will get all item types. If no organization ID is supplied, an org admin will get the item types of his organization.
         * @summary Gets all item types for the organization of a specific organization ID (if supplied), or the calling user.
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options?: any): AxiosPromise<ItemTypesPaginatedResult> {
            return localVarFp.itemTypesGet(orderDirection, cursor, limit, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Only following item types can be deleted: - Specific to your organization. - Not associated with an item and/or collection.
         * @summary Delete a specific item type.
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesItemTypeIdDelete(itemTypeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.itemTypesItemTypeIdDelete(itemTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific item type for an organization.
         * @param {string} itemTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesItemTypeIdGet(itemTypeId: string, options?: any): AxiosPromise<ItemTypeDomainModel> {
            return localVarFp.itemTypesItemTypeIdGet(itemTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits an existing item type for an organization.
         * @param {string} itemTypeId 
         * @param {EditItemTypeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesItemTypeIdPatch(itemTypeId: string, body?: EditItemTypeInput, options?: any): AxiosPromise<ItemTypeDomainModel> {
            return localVarFp.itemTypesItemTypeIdPatch(itemTypeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Only organization or platform admins have access to this data.
         * @summary Get the meta schema (+example) which is used to validate an item type schema.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesMetaSchemaGet(options?: any): AxiosPromise<ItemTypeMetaSchema> {
            return localVarFp.itemTypesMetaSchemaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * If not supplied, the organization of the requesting user is used.
         * @summary Create a new item type for an organization.
         * @param {CreateItemTypeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemTypesPost(body?: CreateItemTypeInput, options?: any): AxiosPromise<ItemTypeDomainModel> {
            return localVarFp.itemTypesPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemTypeApi - object-oriented interface
 * @export
 * @class ItemTypeApi
 * @extends {BaseAPI}
 */
export class ItemTypeApi extends BaseAPI {
    /**
     * Only organization or platform admins have access to this data. If no organization ID is supplied, platform admins will get all item types. If no organization ID is supplied, an org admin will get the item types of his organization.
     * @summary Gets all item types for the organization of a specific organization ID (if supplied), or the calling user.
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTypeApi
     */
    public itemTypesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options?: AxiosRequestConfig) {
        return ItemTypeApiFp(this.configuration).itemTypesGet(orderDirection, cursor, limit, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Only following item types can be deleted: - Specific to your organization. - Not associated with an item and/or collection.
     * @summary Delete a specific item type.
     * @param {string} itemTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTypeApi
     */
    public itemTypesItemTypeIdDelete(itemTypeId: string, options?: AxiosRequestConfig) {
        return ItemTypeApiFp(this.configuration).itemTypesItemTypeIdDelete(itemTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific item type for an organization.
     * @param {string} itemTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTypeApi
     */
    public itemTypesItemTypeIdGet(itemTypeId: string, options?: AxiosRequestConfig) {
        return ItemTypeApiFp(this.configuration).itemTypesItemTypeIdGet(itemTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits an existing item type for an organization.
     * @param {string} itemTypeId 
     * @param {EditItemTypeInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTypeApi
     */
    public itemTypesItemTypeIdPatch(itemTypeId: string, body?: EditItemTypeInput, options?: AxiosRequestConfig) {
        return ItemTypeApiFp(this.configuration).itemTypesItemTypeIdPatch(itemTypeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Only organization or platform admins have access to this data.
     * @summary Get the meta schema (+example) which is used to validate an item type schema.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTypeApi
     */
    public itemTypesMetaSchemaGet(options?: AxiosRequestConfig) {
        return ItemTypeApiFp(this.configuration).itemTypesMetaSchemaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If not supplied, the organization of the requesting user is used.
     * @summary Create a new item type for an organization.
     * @param {CreateItemTypeInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTypeApi
     */
    public itemTypesPost(body?: CreateItemTypeInput, options?: AxiosRequestConfig) {
        return ItemTypeApiFp(this.configuration).itemTypesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ListingApi - axios parameter creator
 * @export
 */
export const ListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Listings
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null'} [status] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, status?: 'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null', campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/listings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Listing
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdDelete: async (listingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('listingsListingIdDelete', 'listingId', listingId)
            const localVarPath = `/listings/{listing_id}`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Listing by id
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdGet: async (listingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('listingsListingIdGet', 'listingId', listingId)
            const localVarPath = `/listings/{listing_id}`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Listing
         * @param {string} listingId 
         * @param {EditListingInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdPatch: async (listingId: string, body?: EditListingInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('listingsListingIdPatch', 'listingId', listingId)
            const localVarPath = `/listings/{listing_id}`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new listing
         * @param {InputListingContext} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsPost: async (body?: InputListingContext, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/listings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all purchase intents for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [listingId] 
         * @param {Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>} [status] 
         * @param {string} [email] Email
         * @param {string} [ethAddress] ChecksumAddress
         * @param {Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>} [fulfillmentStatus] 
         * @param {Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>} [fulfillmentMethod] 
         * @param {Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>} [paymentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, listingId?: string, status?: Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>, email?: string, ethAddress?: string, fulfillmentStatus?: Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>, fulfillmentMethod?: Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>, paymentProvider?: Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/purchase-intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (listingId !== undefined) {
                localVarQueryParameter['listing_id'] = listingId;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (ethAddress !== undefined) {
                localVarQueryParameter['eth_address'] = ethAddress;
            }

            if (fulfillmentStatus) {
                localVarQueryParameter['fulfillment_status'] = fulfillmentStatus;
            }

            if (fulfillmentMethod) {
                localVarQueryParameter['fulfillment_method'] = fulfillmentMethod;
            }

            if (paymentProvider) {
                localVarQueryParameter['payment_provider'] = paymentProvider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get purchase intent by id
         * @param {string} purchaseIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPurchaseIntentIdGet: async (purchaseIntentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseIntentId' is not null or undefined
            assertParamExists('purchaseIntentsPurchaseIntentIdGet', 'purchaseIntentId', purchaseIntentId)
            const localVarPath = `/purchase-intents/{purchase_intent_id}`
                .replace(`{${"purchase_intent_id"}}`, encodeURIComponent(String(purchaseIntentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get purchase intent by id.  Only returns items that have been assigned to the purchase intent and that have an associated token id.
         * @param {string} purchaseIntentId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPurchaseIntentIdItemsGet: async (purchaseIntentId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseIntentId' is not null or undefined
            assertParamExists('purchaseIntentsPurchaseIntentIdItemsGet', 'purchaseIntentId', purchaseIntentId)
            const localVarPath = `/purchase-intents/{purchase_intent_id}/items`
                .replace(`{${"purchase_intent_id"}}`, encodeURIComponent(String(purchaseIntentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListingApi - functional programming interface
 * @export
 */
export const ListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Listings
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null'} [status] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, status?: 'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null', campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsGet(orderDirection, cursor, limit, itemId, collectionId, status, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Listing
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsListingIdDelete(listingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsListingIdDelete(listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Listing by id
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsListingIdGet(listingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsListingIdGet(listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Listing
         * @param {string} listingId 
         * @param {EditListingInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsListingIdPatch(listingId: string, body?: EditListingInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsListingIdPatch(listingId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new listing
         * @param {InputListingContext} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsPost(body?: InputListingContext, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all purchase intents for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [listingId] 
         * @param {Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>} [status] 
         * @param {string} [email] Email
         * @param {string} [ethAddress] ChecksumAddress
         * @param {Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>} [fulfillmentStatus] 
         * @param {Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>} [fulfillmentMethod] 
         * @param {Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>} [paymentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseIntentsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, listingId?: string, status?: Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>, email?: string, ethAddress?: string, fulfillmentStatus?: Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>, fulfillmentMethod?: Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>, paymentProvider?: Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseIntentPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseIntentsGet(orderDirection, cursor, limit, itemId, collectionId, listingId, status, email, ethAddress, fulfillmentStatus, fulfillmentMethod, paymentProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get purchase intent by id
         * @param {string} purchaseIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseIntentsPurchaseIntentIdGet(purchaseIntentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseIntentOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseIntentsPurchaseIntentIdGet(purchaseIntentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get purchase intent by id.  Only returns items that have been assigned to the purchase intent and that have an associated token id.
         * @param {string} purchaseIntentId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseIntentsPurchaseIntentIdItemsGet(purchaseIntentId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseIntentsPurchaseIntentIdItemsGet(purchaseIntentId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListingApi - factory interface
 * @export
 */
export const ListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Listings
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null'} [status] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, status?: 'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null', campaignId?: string, options?: any): AxiosPromise<ListingsPaginatedOutput> {
            return localVarFp.listingsGet(orderDirection, cursor, limit, itemId, collectionId, status, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Listing
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdDelete(listingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.listingsListingIdDelete(listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Listing by id
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdGet(listingId: string, options?: any): AxiosPromise<ListingOutput> {
            return localVarFp.listingsListingIdGet(listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Listing
         * @param {string} listingId 
         * @param {EditListingInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdPatch(listingId: string, body?: EditListingInput, options?: any): AxiosPromise<ListingOutput> {
            return localVarFp.listingsListingIdPatch(listingId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new listing
         * @param {InputListingContext} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsPost(body?: InputListingContext, options?: any): AxiosPromise<ListingOutput> {
            return localVarFp.listingsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all purchase intents for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [listingId] 
         * @param {Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>} [status] 
         * @param {string} [email] Email
         * @param {string} [ethAddress] ChecksumAddress
         * @param {Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>} [fulfillmentStatus] 
         * @param {Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>} [fulfillmentMethod] 
         * @param {Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>} [paymentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, listingId?: string, status?: Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>, email?: string, ethAddress?: string, fulfillmentStatus?: Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>, fulfillmentMethod?: Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>, paymentProvider?: Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>, options?: any): AxiosPromise<PurchaseIntentPaginatedOutput> {
            return localVarFp.purchaseIntentsGet(orderDirection, cursor, limit, itemId, collectionId, listingId, status, email, ethAddress, fulfillmentStatus, fulfillmentMethod, paymentProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get purchase intent by id
         * @param {string} purchaseIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPurchaseIntentIdGet(purchaseIntentId: string, options?: any): AxiosPromise<PurchaseIntentOutput> {
            return localVarFp.purchaseIntentsPurchaseIntentIdGet(purchaseIntentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get purchase intent by id.  Only returns items that have been assigned to the purchase intent and that have an associated token id.
         * @param {string} purchaseIntentId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPurchaseIntentIdItemsGet(purchaseIntentId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<ItemsPaginatedOutput> {
            return localVarFp.purchaseIntentsPurchaseIntentIdItemsGet(purchaseIntentId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListingApi - object-oriented interface
 * @export
 * @class ListingApi
 * @extends {BaseAPI}
 */
export class ListingApi extends BaseAPI {
    /**
     * 
     * @summary Get Listings
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId] 
     * @param {string} [collectionId] 
     * @param {'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null'} [status] 
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public listingsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, status?: 'ACTIVE' | 'CANCELLED' | 'COMPLETE' | 'PENDING_TX' | 'null', campaignId?: string, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).listingsGet(orderDirection, cursor, limit, itemId, collectionId, status, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Listing
     * @param {string} listingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public listingsListingIdDelete(listingId: string, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).listingsListingIdDelete(listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Listing by id
     * @param {string} listingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public listingsListingIdGet(listingId: string, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).listingsListingIdGet(listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Listing
     * @param {string} listingId 
     * @param {EditListingInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public listingsListingIdPatch(listingId: string, body?: EditListingInput, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).listingsListingIdPatch(listingId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new listing
     * @param {InputListingContext} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public listingsPost(body?: InputListingContext, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).listingsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all purchase intents for an organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId] 
     * @param {string} [collectionId] 
     * @param {string} [listingId] 
     * @param {Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>} [status] 
     * @param {string} [email] Email
     * @param {string} [ethAddress] ChecksumAddress
     * @param {Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>} [fulfillmentStatus] 
     * @param {Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>} [fulfillmentMethod] 
     * @param {Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>} [paymentProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public purchaseIntentsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, listingId?: string, status?: Array<'PENDING' | 'UNRESOLVED' | 'CANCELLED' | 'CONFIRMED' | 'EXPIRED'>, email?: string, ethAddress?: string, fulfillmentStatus?: Array<'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION'>, fulfillmentMethod?: Array<'EMAIL' | 'TRANSFER' | 'VOUCHER'>, paymentProvider?: Array<'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM' | 'ORGANIZATION' | 'BETA_FREE_MINT'>, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).purchaseIntentsGet(orderDirection, cursor, limit, itemId, collectionId, listingId, status, email, ethAddress, fulfillmentStatus, fulfillmentMethod, paymentProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get purchase intent by id
     * @param {string} purchaseIntentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public purchaseIntentsPurchaseIntentIdGet(purchaseIntentId: string, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).purchaseIntentsPurchaseIntentIdGet(purchaseIntentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get purchase intent by id.  Only returns items that have been assigned to the purchase intent and that have an associated token id.
     * @param {string} purchaseIntentId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingApi
     */
    public purchaseIntentsPurchaseIntentIdItemsGet(purchaseIntentId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return ListingApiFp(this.configuration).purchaseIntentsPurchaseIntentIdItemsGet(purchaseIntentId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketingCampaignApi - axios parameter creator
 * @export
 */
export const MarketingCampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete marketing campaign with the id
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsCampaignIdDelete: async (campaignId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('marketingCampaignsCampaignIdDelete', 'campaignId', campaignId)
            const localVarPath = `/marketing-campaigns/{campaign_id}`
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get marketing campaign by id
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsCampaignIdGet: async (campaignId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('marketingCampaignsCampaignIdGet', 'campaignId', campaignId)
            const localVarPath = `/marketing-campaigns/{campaign_id}`
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit marketing campaign with the id
         * @param {string} campaignId 
         * @param {EditMarketingCampaignInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsCampaignIdPatch: async (campaignId: string, body?: EditMarketingCampaignInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('marketingCampaignsCampaignIdPatch', 'campaignId', campaignId)
            const localVarPath = `/marketing-campaigns/{campaign_id}`
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all marketing campaigns
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/marketing-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new marketing campaign
         * @param {CreateMarketingCampaignInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsPost: async (body?: CreateMarketingCampaignInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/marketing-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingCampaignApi - functional programming interface
 * @export
 */
export const MarketingCampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketingCampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete marketing campaign with the id
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingCampaignsCampaignIdDelete(campaignId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingCampaignsCampaignIdDelete(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get marketing campaign by id
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingCampaignsCampaignIdGet(campaignId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingCampaignsCampaignIdGet(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit marketing campaign with the id
         * @param {string} campaignId 
         * @param {EditMarketingCampaignInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingCampaignsCampaignIdPatch(campaignId: string, body?: EditMarketingCampaignInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingCampaignsCampaignIdPatch(campaignId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all marketing campaigns
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingCampaignsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingCampaignsGet(orderDirection, cursor, limit, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new marketing campaign
         * @param {CreateMarketingCampaignInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketingCampaignsPost(body?: CreateMarketingCampaignInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketingCampaignsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketingCampaignApi - factory interface
 * @export
 */
export const MarketingCampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketingCampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete marketing campaign with the id
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsCampaignIdDelete(campaignId: string, options?: any): AxiosPromise<void> {
            return localVarFp.marketingCampaignsCampaignIdDelete(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get marketing campaign by id
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsCampaignIdGet(campaignId: string, options?: any): AxiosPromise<MarketingCampaignOutput> {
            return localVarFp.marketingCampaignsCampaignIdGet(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit marketing campaign with the id
         * @param {string} campaignId 
         * @param {EditMarketingCampaignInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsCampaignIdPatch(campaignId: string, body?: EditMarketingCampaignInput, options?: any): AxiosPromise<MarketingCampaignOutput> {
            return localVarFp.marketingCampaignsCampaignIdPatch(campaignId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all marketing campaigns
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: any): AxiosPromise<MarketingCampaignsPaginatedOutput> {
            return localVarFp.marketingCampaignsGet(orderDirection, cursor, limit, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new marketing campaign
         * @param {CreateMarketingCampaignInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketingCampaignsPost(body?: CreateMarketingCampaignInput, options?: any): AxiosPromise<MarketingCampaignOutput> {
            return localVarFp.marketingCampaignsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketingCampaignApi - object-oriented interface
 * @export
 * @class MarketingCampaignApi
 * @extends {BaseAPI}
 */
export class MarketingCampaignApi extends BaseAPI {
    /**
     * 
     * @summary Delete marketing campaign with the id
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public marketingCampaignsCampaignIdDelete(campaignId: string, options?: AxiosRequestConfig) {
        return MarketingCampaignApiFp(this.configuration).marketingCampaignsCampaignIdDelete(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get marketing campaign by id
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public marketingCampaignsCampaignIdGet(campaignId: string, options?: AxiosRequestConfig) {
        return MarketingCampaignApiFp(this.configuration).marketingCampaignsCampaignIdGet(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit marketing campaign with the id
     * @param {string} campaignId 
     * @param {EditMarketingCampaignInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public marketingCampaignsCampaignIdPatch(campaignId: string, body?: EditMarketingCampaignInput, options?: AxiosRequestConfig) {
        return MarketingCampaignApiFp(this.configuration).marketingCampaignsCampaignIdPatch(campaignId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all marketing campaigns
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [name] SafeString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public marketingCampaignsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: AxiosRequestConfig) {
        return MarketingCampaignApiFp(this.configuration).marketingCampaignsGet(orderDirection, cursor, limit, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new marketing campaign
     * @param {CreateMarketingCampaignInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public marketingCampaignsPost(body?: CreateMarketingCampaignInput, options?: AxiosRequestConfig) {
        return MarketingCampaignApiFp(this.configuration).marketingCampaignsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NetworkApi - axios parameter creator
 * @export
 */
export const NetworkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the available networks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/networks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific network
         * @param {number} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdGet: async (networkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('networksNetworkIdGet', 'networkId', networkId)
            const localVarPath = `/networks/{network_id}`
                .replace(`{${"network_id"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a specific network
         * @param {number} networkId 
         * @param {EditNetworkInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPatch: async (networkId: number, body?: EditNetworkInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('networksNetworkIdPatch', 'networkId', networkId)
            const localVarPath = `/networks/{network_id}`
                .replace(`{${"network_id"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new network
         * @param {Network} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksPost: async (body?: Network, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/networks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NetworkApi - functional programming interface
 * @export
 */
export const NetworkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NetworkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the available networks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworksPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific network
         * @param {number} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksNetworkIdGet(networkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Network>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksNetworkIdGet(networkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a specific network
         * @param {number} networkId 
         * @param {EditNetworkInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksNetworkIdPatch(networkId: number, body?: EditNetworkInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Network>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksNetworkIdPatch(networkId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new network
         * @param {Network} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksPost(body?: Network, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Network>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NetworkApi - factory interface
 * @export
 */
export const NetworkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NetworkApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the available networks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<NetworksPaginatedOutput> {
            return localVarFp.networksGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific network
         * @param {number} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdGet(networkId: number, options?: any): AxiosPromise<Network> {
            return localVarFp.networksNetworkIdGet(networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a specific network
         * @param {number} networkId 
         * @param {EditNetworkInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPatch(networkId: number, body?: EditNetworkInput, options?: any): AxiosPromise<Network> {
            return localVarFp.networksNetworkIdPatch(networkId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new network
         * @param {Network} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksPost(body?: Network, options?: any): AxiosPromise<Network> {
            return localVarFp.networksPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NetworkApi - object-oriented interface
 * @export
 * @class NetworkApi
 * @extends {BaseAPI}
 */
export class NetworkApi extends BaseAPI {
    /**
     * 
     * @summary Get the available networks
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkApi
     */
    public networksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return NetworkApiFp(this.configuration).networksGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific network
     * @param {number} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkApi
     */
    public networksNetworkIdGet(networkId: number, options?: AxiosRequestConfig) {
        return NetworkApiFp(this.configuration).networksNetworkIdGet(networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a specific network
     * @param {number} networkId 
     * @param {EditNetworkInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkApi
     */
    public networksNetworkIdPatch(networkId: number, body?: EditNetworkInput, options?: AxiosRequestConfig) {
        return NetworkApiFp(this.configuration).networksNetworkIdPatch(networkId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new network
     * @param {Network} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkApi
     */
    public networksPost(body?: Network, options?: AxiosRequestConfig) {
        return NetworkApiFp(this.configuration).networksPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all organization invitations
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsInvitationIdDelete: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('invitationsInvitationIdDelete', 'invitationId', invitationId)
            const localVarPath = `/invitations/{invitation_id}`
                .replace(`{${"invitation_id"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend an invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsInvitationIdResendPost: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('invitationsInvitationIdResendPost', 'invitationId', invitationId)
            const localVarPath = `/invitations/{invitation_id}/resend`
                .replace(`{${"invitation_id"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the matching invitation
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsInvitationTokenGet: async (invitationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationToken' is not null or undefined
            assertParamExists('invitationsInvitationTokenGet', 'invitationToken', invitationToken)
            const localVarPath = `/invitations/{invitation_token}`
                .replace(`{${"invitation_token"}}`, encodeURIComponent(String(invitationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify an invitation
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsVerifyInvitationTokenPost: async (invitationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationToken' is not null or undefined
            assertParamExists('invitationsVerifyInvitationTokenPost', 'invitationToken', invitationToken)
            const localVarPath = `/invitations/verify/{invitation_token}`
                .replace(`{${"invitation_token"}}`, encodeURIComponent(String(invitationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will be a list of 1 for everyone except platform admins.
         * @summary Get all organizations the current user has access to
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user must have access to the desired org.
         * @summary Get organization by id
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdGet', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Most fields are only editable by a platform admin.
         * @summary Edit an organization
         * @param {string} organizationId 
         * @param {EditOrganizationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPatch: async (organizationId: string, body?: EditOrganizationInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdPatch', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user must have access to the desired organization.
         * @summary Get the organization payout configuration.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPayoutConfigurationGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdPayoutConfigurationGet', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/payout-configuration`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit an organization payout configuration
         * @param {string} organizationId 
         * @param {EditOrganizationPayoutConfigurationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPayoutConfigurationPatch: async (organizationId: string, body?: EditOrganizationPayoutConfigurationInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdPayoutConfigurationPatch', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/payout-configuration`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new organization
         * @param {CreateOrganizationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsPost: async (body?: CreateOrganizationInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all organization invitations
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgInvitationsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsInvitationIdDelete(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsInvitationIdDelete(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend an invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsInvitationIdResendPost(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInvitationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsInvitationIdResendPost(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the matching invitation
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsInvitationTokenGet(invitationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInvitationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsInvitationTokenGet(invitationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify an invitation
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsVerifyInvitationTokenPost(invitationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInvitationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsVerifyInvitationTokenPost(invitationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will be a list of 1 for everyone except platform admins.
         * @summary Get all organizations the current user has access to
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGet(orderDirection, cursor, limit, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The user must have access to the desired org.
         * @summary Get organization by id
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Most fields are only editable by a platform admin.
         * @summary Edit an organization
         * @param {string} organizationId 
         * @param {EditOrganizationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdPatch(organizationId: string, body?: EditOrganizationInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdPatch(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The user must have access to the desired organization.
         * @summary Get the organization payout configuration.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdPayoutConfigurationGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationPayoutConfigurationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdPayoutConfigurationGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit an organization payout configuration
         * @param {string} organizationId 
         * @param {EditOrganizationPayoutConfigurationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdPayoutConfigurationPatch(organizationId: string, body?: EditOrganizationPayoutConfigurationInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationPayoutConfigurationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdPayoutConfigurationPatch(organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new organization
         * @param {CreateOrganizationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsPost(body?: CreateOrganizationInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all organization invitations
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<GetOrgInvitationsPaginatedOutput> {
            return localVarFp.invitationsGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsInvitationIdDelete(invitationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.invitationsInvitationIdDelete(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend an invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsInvitationIdResendPost(invitationId: string, options?: any): AxiosPromise<OrganizationInvitationOutput> {
            return localVarFp.invitationsInvitationIdResendPost(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the matching invitation
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsInvitationTokenGet(invitationToken: string, options?: any): AxiosPromise<OrganizationInvitationOutput> {
            return localVarFp.invitationsInvitationTokenGet(invitationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify an invitation
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsVerifyInvitationTokenPost(invitationToken: string, options?: any): AxiosPromise<OrganizationInvitationOutput> {
            return localVarFp.invitationsVerifyInvitationTokenPost(invitationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * This will be a list of 1 for everyone except platform admins.
         * @summary Get all organizations the current user has access to
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: any): AxiosPromise<OrganizationsPaginatedOutput> {
            return localVarFp.organizationsGet(orderDirection, cursor, limit, name, options).then((request) => request(axios, basePath));
        },
        /**
         * The user must have access to the desired org.
         * @summary Get organization by id
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdGet(organizationId: string, options?: any): AxiosPromise<OrganizationOutput> {
            return localVarFp.organizationsOrganizationIdGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Most fields are only editable by a platform admin.
         * @summary Edit an organization
         * @param {string} organizationId 
         * @param {EditOrganizationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPatch(organizationId: string, body?: EditOrganizationInput, options?: any): AxiosPromise<OrganizationOutput> {
            return localVarFp.organizationsOrganizationIdPatch(organizationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * The user must have access to the desired organization.
         * @summary Get the organization payout configuration.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPayoutConfigurationGet(organizationId: string, options?: any): AxiosPromise<OrganizationPayoutConfigurationOutput> {
            return localVarFp.organizationsOrganizationIdPayoutConfigurationGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit an organization payout configuration
         * @param {string} organizationId 
         * @param {EditOrganizationPayoutConfigurationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPayoutConfigurationPatch(organizationId: string, body?: EditOrganizationPayoutConfigurationInput, options?: any): AxiosPromise<OrganizationPayoutConfigurationOutput> {
            return localVarFp.organizationsOrganizationIdPayoutConfigurationPatch(organizationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new organization
         * @param {CreateOrganizationInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsPost(body?: CreateOrganizationInput, options?: any): AxiosPromise<OrganizationOutput> {
            return localVarFp.organizationsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary Get all organization invitations
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public invitationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).invitationsGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing invitation
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public invitationsInvitationIdDelete(invitationId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).invitationsInvitationIdDelete(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend an invitation
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public invitationsInvitationIdResendPost(invitationId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).invitationsInvitationIdResendPost(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the matching invitation
     * @param {string} invitationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public invitationsInvitationTokenGet(invitationToken: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).invitationsInvitationTokenGet(invitationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify an invitation
     * @param {string} invitationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public invitationsVerifyInvitationTokenPost(invitationToken: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).invitationsVerifyInvitationTokenPost(invitationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will be a list of 1 for everyone except platform admins.
     * @summary Get all organizations the current user has access to
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {string} [name] SafeString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationsGet(orderDirection, cursor, limit, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user must have access to the desired org.
     * @summary Get organization by id
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationsOrganizationIdGet(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationsOrganizationIdGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Most fields are only editable by a platform admin.
     * @summary Edit an organization
     * @param {string} organizationId 
     * @param {EditOrganizationInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationsOrganizationIdPatch(organizationId: string, body?: EditOrganizationInput, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationsOrganizationIdPatch(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user must have access to the desired organization.
     * @summary Get the organization payout configuration.
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationsOrganizationIdPayoutConfigurationGet(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationsOrganizationIdPayoutConfigurationGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit an organization payout configuration
     * @param {string} organizationId 
     * @param {EditOrganizationPayoutConfigurationInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationsOrganizationIdPayoutConfigurationPatch(organizationId: string, body?: EditOrganizationPayoutConfigurationInput, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationsOrganizationIdPayoutConfigurationPatch(organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new organization
     * @param {CreateOrganizationInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationsPost(body?: CreateOrganizationInput, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentIntegrationApi - axios parameter creator
 * @export
 */
export const PaymentIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get platform Stripe accounts by org
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPaymentIntegrationsStripeGet: async (organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdPaymentIntegrationsStripeGet', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/payment-integrations/stripe`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all platform Stripe accounts
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a platform Stripe account by id
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete: async (platformStripeAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'platformStripeAccountId' is not null or undefined
            assertParamExists('paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete', 'platformStripeAccountId', platformStripeAccountId)
            const localVarPath = `/payment-integrations/stripe/accounts/{platform_stripe_account_id}`
                .replace(`{${"platform_stripe_account_id"}}`, encodeURIComponent(String(platformStripeAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get platform Stripe account by id
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet: async (platformStripeAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'platformStripeAccountId' is not null or undefined
            assertParamExists('paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet', 'platformStripeAccountId', platformStripeAccountId)
            const localVarPath = `/payment-integrations/stripe/accounts/{platform_stripe_account_id}`
                .replace(`{${"platform_stripe_account_id"}}`, encodeURIComponent(String(platformStripeAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update platform Stripe account
         * @param {string} platformStripeAccountId 
         * @param {EditPlatformStripeAccountInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch: async (platformStripeAccountId: string, body?: EditPlatformStripeAccountInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'platformStripeAccountId' is not null or undefined
            assertParamExists('paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch', 'platformStripeAccountId', platformStripeAccountId)
            const localVarPath = `/payment-integrations/stripe/accounts/{platform_stripe_account_id}`
                .replace(`{${"platform_stripe_account_id"}}`, encodeURIComponent(String(platformStripeAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a platform Stripe account
         * @param {PlatformStripeAccountInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPost: async (body?: PlatformStripeAccountInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Stripe integration command
         * @param {StripeCommandInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeCommandPost: async (body?: StripeCommandInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe/command`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stripe configuration for the current user organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disconnect Stripe account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stripe integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set up Stripe account
         * @param {StripePaymentsSetupInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripePost: async (body?: StripePaymentsSetupInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-integrations/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentIntegrationApi - functional programming interface
 * @export
 */
export const PaymentIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get platform Stripe accounts by org
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdPaymentIntegrationsStripeGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPublicPlatformStripeAccountsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdPaymentIntegrationsStripeGet(organizationId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all platform Stripe accounts
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeAccountsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPlatformStripeAccountsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeAccountsGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a platform Stripe account by id
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete(platformStripeAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete(platformStripeAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get platform Stripe account by id
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet(platformStripeAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformStripeAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet(platformStripeAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update platform Stripe account
         * @param {string} platformStripeAccountId 
         * @param {EditPlatformStripeAccountInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch(platformStripeAccountId: string, body?: EditPlatformStripeAccountInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformStripeAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch(platformStripeAccountId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a platform Stripe account
         * @param {PlatformStripeAccountInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeAccountsPost(body?: PlatformStripeAccountInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformStripeAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeAccountsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a Stripe integration command
         * @param {StripeCommandInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeCommandPost(body?: StripeCommandInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeIntegrationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeCommandPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stripe configuration for the current user organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeConfigOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeConfigGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disconnect Stripe account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stripe integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeIntegrationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set up Stripe account
         * @param {StripePaymentsSetupInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntegrationsStripePost(body?: StripePaymentsSetupInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripePaymentsSetupOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntegrationsStripePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentIntegrationApi - factory interface
 * @export
 */
export const PaymentIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get platform Stripe accounts by org
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdPaymentIntegrationsStripeGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PaginatedPublicPlatformStripeAccountsOutput> {
            return localVarFp.organizationsOrganizationIdPaymentIntegrationsStripeGet(organizationId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all platform Stripe accounts
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PaginatedPlatformStripeAccountsOutput> {
            return localVarFp.paymentIntegrationsStripeAccountsGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a platform Stripe account by id
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete(platformStripeAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete(platformStripeAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get platform Stripe account by id
         * @param {string} platformStripeAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet(platformStripeAccountId: string, options?: any): AxiosPromise<PlatformStripeAccountOutput> {
            return localVarFp.paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet(platformStripeAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update platform Stripe account
         * @param {string} platformStripeAccountId 
         * @param {EditPlatformStripeAccountInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch(platformStripeAccountId: string, body?: EditPlatformStripeAccountInput, options?: any): AxiosPromise<PlatformStripeAccountOutput> {
            return localVarFp.paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch(platformStripeAccountId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a platform Stripe account
         * @param {PlatformStripeAccountInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeAccountsPost(body?: PlatformStripeAccountInput, options?: any): AxiosPromise<PlatformStripeAccountOutput> {
            return localVarFp.paymentIntegrationsStripeAccountsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Stripe integration command
         * @param {StripeCommandInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeCommandPost(body?: StripeCommandInput, options?: any): AxiosPromise<StripeIntegrationOutput> {
            return localVarFp.paymentIntegrationsStripeCommandPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stripe configuration for the current user organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeConfigGet(options?: any): AxiosPromise<StripeConfigOutput> {
            return localVarFp.paymentIntegrationsStripeConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disconnect Stripe account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeDelete(options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntegrationsStripeDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stripe integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripeGet(options?: any): AxiosPromise<StripeIntegrationOutput> {
            return localVarFp.paymentIntegrationsStripeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set up Stripe account
         * @param {StripePaymentsSetupInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntegrationsStripePost(body?: StripePaymentsSetupInput, options?: any): AxiosPromise<StripePaymentsSetupOutput> {
            return localVarFp.paymentIntegrationsStripePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentIntegrationApi - object-oriented interface
 * @export
 * @class PaymentIntegrationApi
 * @extends {BaseAPI}
 */
export class PaymentIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Get platform Stripe accounts by org
     * @param {string} organizationId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public organizationsOrganizationIdPaymentIntegrationsStripeGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).organizationsOrganizationIdPaymentIntegrationsStripeGet(organizationId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all platform Stripe accounts
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeAccountsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeAccountsGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a platform Stripe account by id
     * @param {string} platformStripeAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete(platformStripeAccountId: string, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeAccountsPlatformStripeAccountIdDelete(platformStripeAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get platform Stripe account by id
     * @param {string} platformStripeAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet(platformStripeAccountId: string, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeAccountsPlatformStripeAccountIdGet(platformStripeAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update platform Stripe account
     * @param {string} platformStripeAccountId 
     * @param {EditPlatformStripeAccountInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch(platformStripeAccountId: string, body?: EditPlatformStripeAccountInput, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeAccountsPlatformStripeAccountIdPatch(platformStripeAccountId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a platform Stripe account
     * @param {PlatformStripeAccountInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeAccountsPost(body?: PlatformStripeAccountInput, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeAccountsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Stripe integration command
     * @param {StripeCommandInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeCommandPost(body?: StripeCommandInput, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeCommandPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stripe configuration for the current user organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeConfigGet(options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disconnect Stripe account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeDelete(options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stripe integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripeGet(options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set up Stripe account
     * @param {StripePaymentsSetupInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentIntegrationApi
     */
    public paymentIntegrationsStripePost(body?: StripePaymentsSetupInput, options?: AxiosRequestConfig) {
        return PaymentIntegrationApiFp(this.configuration).paymentIntegrationsStripePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentTokensApi - axios parameter creator
 * @export
 */
export const PaymentTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete payment token from specified network
         * @param {number} networkId 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPaymentTokensAddressDelete: async (networkId: number, address: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('networksNetworkIdPaymentTokensAddressDelete', 'networkId', networkId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('networksNetworkIdPaymentTokensAddressDelete', 'address', address)
            const localVarPath = `/networks/{network_id}/payment-tokens/{address}`
                .replace(`{${"network_id"}}`, encodeURIComponent(String(networkId)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all tokens of specified network id
         * @param {number} networkId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPaymentTokensGet: async (networkId: number, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('networksNetworkIdPaymentTokensGet', 'networkId', networkId)
            const localVarPath = `/networks/{network_id}/payment-tokens`
                .replace(`{${"network_id"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create payment token for specified network
         * @param {number} networkId 
         * @param {AddPaymentTokenInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPaymentTokensPost: async (networkId: number, body?: AddPaymentTokenInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('networksNetworkIdPaymentTokensPost', 'networkId', networkId)
            const localVarPath = `/networks/{network_id}/payment-tokens`
                .replace(`{${"network_id"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentTokensApi - functional programming interface
 * @export
 */
export const PaymentTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete payment token from specified network
         * @param {number} networkId 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksNetworkIdPaymentTokensAddressDelete(networkId: number, address: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksNetworkIdPaymentTokensAddressDelete(networkId, address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all tokens of specified network id
         * @param {number} networkId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksNetworkIdPaymentTokensGet(networkId: number, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicNetworkPaymentTokensPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksNetworkIdPaymentTokensGet(networkId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create payment token for specified network
         * @param {number} networkId 
         * @param {AddPaymentTokenInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksNetworkIdPaymentTokensPost(networkId: number, body?: AddPaymentTokenInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkPaymentToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksNetworkIdPaymentTokensPost(networkId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentTokensApi - factory interface
 * @export
 */
export const PaymentTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentTokensApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete payment token from specified network
         * @param {number} networkId 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPaymentTokensAddressDelete(networkId: number, address: string, options?: any): AxiosPromise<void> {
            return localVarFp.networksNetworkIdPaymentTokensAddressDelete(networkId, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all tokens of specified network id
         * @param {number} networkId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPaymentTokensGet(networkId: number, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PublicNetworkPaymentTokensPaginatedOutput> {
            return localVarFp.networksNetworkIdPaymentTokensGet(networkId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create payment token for specified network
         * @param {number} networkId 
         * @param {AddPaymentTokenInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksNetworkIdPaymentTokensPost(networkId: number, body?: AddPaymentTokenInput, options?: any): AxiosPromise<NetworkPaymentToken> {
            return localVarFp.networksNetworkIdPaymentTokensPost(networkId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentTokensApi - object-oriented interface
 * @export
 * @class PaymentTokensApi
 * @extends {BaseAPI}
 */
export class PaymentTokensApi extends BaseAPI {
    /**
     * 
     * @summary Delete payment token from specified network
     * @param {number} networkId 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentTokensApi
     */
    public networksNetworkIdPaymentTokensAddressDelete(networkId: number, address: string, options?: AxiosRequestConfig) {
        return PaymentTokensApiFp(this.configuration).networksNetworkIdPaymentTokensAddressDelete(networkId, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all tokens of specified network id
     * @param {number} networkId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentTokensApi
     */
    public networksNetworkIdPaymentTokensGet(networkId: number, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return PaymentTokensApiFp(this.configuration).networksNetworkIdPaymentTokensGet(networkId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create payment token for specified network
     * @param {number} networkId 
     * @param {AddPaymentTokenInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentTokensApi
     */
    public networksNetworkIdPaymentTokensPost(networkId: number, body?: AddPaymentTokenInput, options?: AxiosRequestConfig) {
        return PaymentTokensApiFp(this.configuration).networksNetworkIdPaymentTokensPost(networkId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get roles available for the current organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get roles available for the current organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGet(orderDirection, cursor, limit, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @summary Get roles available for the current organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [name] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: any): AxiosPromise<RolesPaginatedOutput> {
            return localVarFp.rolesGet(orderDirection, cursor, limit, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @summary Get roles available for the current organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {string} [name] SafeString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public rolesGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, name?: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).rolesGet(orderDirection, cursor, limit, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SnapshotApi - axios parameter creator
 * @export
 */
export const SnapshotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Snapshots
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snapshots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new Snapshot
         * @param {InputSnapshotContext} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsPost: async (body?: InputSnapshotContext, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snapshots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete snapshot
         * @param {string} snapshotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdDelete: async (snapshotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotId' is not null or undefined
            assertParamExists('snapshotsSnapshotIdDelete', 'snapshotId', snapshotId)
            const localVarPath = `/snapshots/{snapshot_id}`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Snapshot by id
         * @param {string} snapshotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdGet: async (snapshotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotId' is not null or undefined
            assertParamExists('snapshotsSnapshotIdGet', 'snapshotId', snapshotId)
            const localVarPath = `/snapshots/{snapshot_id}`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Snapshot holders csv
         * @param {string} snapshotId 
         * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdHoldersCsvGet: async (snapshotId: string, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotId' is not null or undefined
            assertParamExists('snapshotsSnapshotIdHoldersCsvGet', 'snapshotId', snapshotId)
            const localVarPath = `/snapshots/{snapshot_id}/holders/csv`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (outputType !== undefined) {
                localVarQueryParameter['output_type'] = outputType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Snapshot holders
         * @param {string} snapshotId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] LargePaginationLimit
         * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
         * @param {Array<string>} [holder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdHoldersGet: async (snapshotId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', holder?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotId' is not null or undefined
            assertParamExists('snapshotsSnapshotIdHoldersGet', 'snapshotId', snapshotId)
            const localVarPath = `/snapshots/{snapshot_id}/holders`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (outputType !== undefined) {
                localVarQueryParameter['output_type'] = outputType;
            }

            if (holder) {
                localVarQueryParameter['holder'] = holder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Snapshot by id
         * @param {string} snapshotId 
         * @param {EditSnapshotInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdPatch: async (snapshotId: string, body?: EditSnapshotInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snapshotId' is not null or undefined
            assertParamExists('snapshotsSnapshotIdPatch', 'snapshotId', snapshotId)
            const localVarPath = `/snapshots/{snapshot_id}`
                .replace(`{${"snapshot_id"}}`, encodeURIComponent(String(snapshotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SnapshotApi - functional programming interface
 * @export
 */
export const SnapshotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SnapshotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Snapshots
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new Snapshot
         * @param {InputSnapshotContext} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsPost(body?: InputSnapshotContext, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete snapshot
         * @param {string} snapshotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsSnapshotIdDelete(snapshotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsSnapshotIdDelete(snapshotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Snapshot by id
         * @param {string} snapshotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsSnapshotIdGet(snapshotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsSnapshotIdGet(snapshotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Snapshot holders csv
         * @param {string} snapshotId 
         * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsSnapshotIdHoldersCsvGet(snapshotId: string, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsSnapshotIdHoldersCsvGet(snapshotId, outputType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Snapshot holders
         * @param {string} snapshotId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] LargePaginationLimit
         * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
         * @param {Array<string>} [holder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsSnapshotIdHoldersGet(snapshotId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', holder?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotHoldersPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsSnapshotIdHoldersGet(snapshotId, orderDirection, cursor, limit, outputType, holder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Snapshot by id
         * @param {string} snapshotId 
         * @param {EditSnapshotInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async snapshotsSnapshotIdPatch(snapshotId: string, body?: EditSnapshotInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.snapshotsSnapshotIdPatch(snapshotId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SnapshotApi - factory interface
 * @export
 */
export const SnapshotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SnapshotApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Snapshots
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<SnapshotsPaginatedOutput> {
            return localVarFp.snapshotsGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new Snapshot
         * @param {InputSnapshotContext} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsPost(body?: InputSnapshotContext, options?: any): AxiosPromise<SnapshotOutput> {
            return localVarFp.snapshotsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete snapshot
         * @param {string} snapshotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdDelete(snapshotId: string, options?: any): AxiosPromise<void> {
            return localVarFp.snapshotsSnapshotIdDelete(snapshotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Snapshot by id
         * @param {string} snapshotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdGet(snapshotId: string, options?: any): AxiosPromise<SnapshotOutput> {
            return localVarFp.snapshotsSnapshotIdGet(snapshotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Snapshot holders csv
         * @param {string} snapshotId 
         * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdHoldersCsvGet(snapshotId: string, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', options?: any): AxiosPromise<void> {
            return localVarFp.snapshotsSnapshotIdHoldersCsvGet(snapshotId, outputType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Snapshot holders
         * @param {string} snapshotId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] LargePaginationLimit
         * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
         * @param {Array<string>} [holder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdHoldersGet(snapshotId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', holder?: Array<string>, options?: any): AxiosPromise<SnapshotHoldersPaginatedOutput> {
            return localVarFp.snapshotsSnapshotIdHoldersGet(snapshotId, orderDirection, cursor, limit, outputType, holder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Snapshot by id
         * @param {string} snapshotId 
         * @param {EditSnapshotInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        snapshotsSnapshotIdPatch(snapshotId: string, body?: EditSnapshotInput, options?: any): AxiosPromise<SnapshotOutput> {
            return localVarFp.snapshotsSnapshotIdPatch(snapshotId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SnapshotApi - object-oriented interface
 * @export
 * @class SnapshotApi
 * @extends {BaseAPI}
 */
export class SnapshotApi extends BaseAPI {
    /**
     * 
     * @summary Get Snapshots
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new Snapshot
     * @param {InputSnapshotContext} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsPost(body?: InputSnapshotContext, options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete snapshot
     * @param {string} snapshotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsSnapshotIdDelete(snapshotId: string, options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsSnapshotIdDelete(snapshotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Snapshot by id
     * @param {string} snapshotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsSnapshotIdGet(snapshotId: string, options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsSnapshotIdGet(snapshotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Snapshot holders csv
     * @param {string} snapshotId 
     * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsSnapshotIdHoldersCsvGet(snapshotId: string, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsSnapshotIdHoldersCsvGet(snapshotId, outputType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Snapshot holders
     * @param {string} snapshotId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] LargePaginationLimit
     * @param {'PER_ADDRESS' | 'PER_TOKEN'} [outputType] 
     * @param {Array<string>} [holder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsSnapshotIdHoldersGet(snapshotId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, outputType?: 'PER_ADDRESS' | 'PER_TOKEN', holder?: Array<string>, options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsSnapshotIdHoldersGet(snapshotId, orderDirection, cursor, limit, outputType, holder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Snapshot by id
     * @param {string} snapshotId 
     * @param {EditSnapshotInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnapshotApi
     */
    public snapshotsSnapshotIdPatch(snapshotId: string, body?: EditSnapshotInput, options?: AxiosRequestConfig) {
        return SnapshotApiFp(this.configuration).snapshotsSnapshotIdPatch(snapshotId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Uploads a JSON content or a file to our storage service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Uploads a JSON content or a file to our storage service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Uploads a JSON content or a file to our storage service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPost(options?: any): AxiosPromise<UploadOutput> {
            return localVarFp.uploadPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @summary Uploads a JSON content or a file to our storage service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public uploadPost(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).uploadPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all transactions for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<number>} [networkIds] 
         * @param {Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>} [txTypes] 
         * @param {Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>} [states] 
         * @param {Array<string>} [signers] 
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {'CREATED_AT' | 'null'} [orderBy] 
         * @param {'FAILURE' | 'SUCCESS' | 'null'} [onChainStatus] 
         * @param {boolean} [hasFailures] 
         * @param {'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null'} [billingStatus] 
         * @param {boolean} [testnet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, networkIds?: Array<number>, txTypes?: Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>, states?: Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>, signers?: Array<string>, itemId?: string, collectionId?: string, orderBy?: 'CREATED_AT' | 'null', onChainStatus?: 'FAILURE' | 'SUCCESS' | 'null', hasFailures?: boolean, billingStatus?: 'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null', testnet?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (networkIds) {
                localVarQueryParameter['network_ids'] = networkIds;
            }

            if (txTypes) {
                localVarQueryParameter['tx_types'] = txTypes;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            if (signers) {
                localVarQueryParameter['signers'] = signers;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (onChainStatus !== undefined) {
                localVarQueryParameter['on_chain_status'] = onChainStatus;
            }

            if (hasFailures !== undefined) {
                localVarQueryParameter['has_failures'] = hasFailures;
            }

            if (billingStatus !== undefined) {
                localVarQueryParameter['billing_status'] = billingStatus;
            }

            if (testnet !== undefined) {
                localVarQueryParameter['testnet'] = testnet;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsTransactionIdGet: async (transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transactionsTransactionIdGet', 'transactionId', transactionId)
            const localVarPath = `/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a specific transaction
         * @param {string} transactionId 
         * @param {EditTransactionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsTransactionIdPatch: async (transactionId: string, body?: EditTransactionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transactionsTransactionIdPatch', 'transactionId', transactionId)
            const localVarPath = `/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all transactions for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<number>} [networkIds] 
         * @param {Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>} [txTypes] 
         * @param {Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>} [states] 
         * @param {Array<string>} [signers] 
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {'CREATED_AT' | 'null'} [orderBy] 
         * @param {'FAILURE' | 'SUCCESS' | 'null'} [onChainStatus] 
         * @param {boolean} [hasFailures] 
         * @param {'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null'} [billingStatus] 
         * @param {boolean} [testnet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, networkIds?: Array<number>, txTypes?: Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>, states?: Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>, signers?: Array<string>, itemId?: string, collectionId?: string, orderBy?: 'CREATED_AT' | 'null', onChainStatus?: 'FAILURE' | 'SUCCESS' | 'null', hasFailures?: boolean, billingStatus?: 'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null', testnet?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsGet(orderDirection, cursor, limit, networkIds, txTypes, states, signers, itemId, collectionId, orderBy, onChainStatus, hasFailures, billingStatus, testnet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsTransactionIdGet(transactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsTransactionIdGet(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a specific transaction
         * @param {string} transactionId 
         * @param {EditTransactionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsTransactionIdPatch(transactionId: string, body?: EditTransactionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsTransactionIdPatch(transactionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all transactions for an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {Array<number>} [networkIds] 
         * @param {Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>} [txTypes] 
         * @param {Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>} [states] 
         * @param {Array<string>} [signers] 
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {'CREATED_AT' | 'null'} [orderBy] 
         * @param {'FAILURE' | 'SUCCESS' | 'null'} [onChainStatus] 
         * @param {boolean} [hasFailures] 
         * @param {'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null'} [billingStatus] 
         * @param {boolean} [testnet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, networkIds?: Array<number>, txTypes?: Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>, states?: Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>, signers?: Array<string>, itemId?: string, collectionId?: string, orderBy?: 'CREATED_AT' | 'null', onChainStatus?: 'FAILURE' | 'SUCCESS' | 'null', hasFailures?: boolean, billingStatus?: 'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null', testnet?: boolean, options?: any): AxiosPromise<TransactionsPaginatedOutput> {
            return localVarFp.transactionsGet(orderDirection, cursor, limit, networkIds, txTypes, states, signers, itemId, collectionId, orderBy, onChainStatus, hasFailures, billingStatus, testnet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific transaction
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsTransactionIdGet(transactionId: string, options?: any): AxiosPromise<TransactionOutput> {
            return localVarFp.transactionsTransactionIdGet(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a specific transaction
         * @param {string} transactionId 
         * @param {EditTransactionInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsTransactionIdPatch(transactionId: string, body?: EditTransactionInput, options?: any): AxiosPromise<TransactionOutput> {
            return localVarFp.transactionsTransactionIdPatch(transactionId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @summary Get all transactions for an organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {Array<number>} [networkIds] 
     * @param {Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>} [txTypes] 
     * @param {Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>} [states] 
     * @param {Array<string>} [signers] 
     * @param {string} [itemId] 
     * @param {string} [collectionId] 
     * @param {'CREATED_AT' | 'null'} [orderBy] 
     * @param {'FAILURE' | 'SUCCESS' | 'null'} [onChainStatus] 
     * @param {boolean} [hasFailures] 
     * @param {'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null'} [billingStatus] 
     * @param {boolean} [testnet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, networkIds?: Array<number>, txTypes?: Array<'DEPLOY_TOKEN_CONTRACT' | 'DEPLOY_CONTRACT_VIA_FACTORY' | 'SET_APPROVAL_FOR_ALL' | 'MINT_TOKEN' | 'ADMIN_MINT_WITH_URI' | 'TRANSFER_TOKEN' | 'CREATE_FOR_ADMIN_MINT' | 'FUND_ACCOUNT' | 'WITHDRAW_BALANCE' | 'CREATE_ONCHAIN_ENGLISH_AUCTION' | 'SETTLE_ONCHAIN_ENGLISH_AUCTION' | 'CANCEL_ONCHAIN_ENGLISH_AUCTION' | 'ADMIN_MINT' | 'ADMIN_MINT_NO_ID' | 'TRANSFER_OWNERSHIP' | 'GRANT_ROLE' | 'GRANT_MANY' | 'INVOKE_FUNCTION' | 'SET_CONTRACT_ROYALTY' | 'SET_PER_TOKEN_ROYALTY' | 'UNSET_PER_TOKEN_ROYALTY' | 'DISTRIBUTOR_CONTRACT' | 'SET_BASE_URI' | 'TRANSFER_ERC20' | 'BULK_ADMIN_MINT' | 'BILLING_STATUS' | 'CREATE_TOKEN_FIXED_PRICE' | 'DISPERSE'>, states?: Array<'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED'>, signers?: Array<string>, itemId?: string, collectionId?: string, orderBy?: 'CREATED_AT' | 'null', onChainStatus?: 'FAILURE' | 'SUCCESS' | 'null', hasFailures?: boolean, billingStatus?: 'NEW' | 'INITIATED' | 'COMPLETED' | 'CANCELED' | 'EXPIRED' | 'FAILED' | 'NO_CHARGE' | 'null', testnet?: boolean, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsGet(orderDirection, cursor, limit, networkIds, txTypes, states, signers, itemId, collectionId, orderBy, onChainStatus, hasFailures, billingStatus, testnet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific transaction
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsTransactionIdGet(transactionId: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsTransactionIdGet(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a specific transaction
     * @param {string} transactionId 
     * @param {EditTransactionInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public transactionsTransactionIdPatch(transactionId: string, body?: EditTransactionInput, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).transactionsTransactionIdPatch(transactionId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get users for the current organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite a new user
         * @param {InviteUserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersInvitePost: async (body?: InviteUserInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdDelete', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user by id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdGet', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a user
         * @param {string} userId 
         * @param {EditUserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdPatch: async (userId: string, body?: EditUserInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdPatch', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get users for the current organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(orderDirection, cursor, limit, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite a new user
         * @param {InviteUserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersInvitePost(body?: InviteUserInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersInvitePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user by id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a user
         * @param {string} userId 
         * @param {EditUserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdPatch(userId: string, body?: EditUserInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdPatch(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get users for the current organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options?: any): AxiosPromise<UsersPaginatedOutput> {
            return localVarFp.usersGet(orderDirection, cursor, limit, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite a new user
         * @param {InviteUserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersInvitePost(body?: InviteUserInput, options?: any): AxiosPromise<void> {
            return localVarFp.usersInvitePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user by id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdGet(userId: string, options?: any): AxiosPromise<UserOutput> {
            return localVarFp.usersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a user
         * @param {string} userId 
         * @param {EditUserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdPatch(userId: string, body?: EditUserInput, options?: any): AxiosPromise<UserOutput> {
            return localVarFp.usersUserIdPatch(userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get users for the current organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersGet(orderDirection, cursor, limit, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite a new user
     * @param {InviteUserInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersInvitePost(body?: InviteUserInput, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersInvitePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user by id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersUserIdDelete(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user by id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a user
     * @param {string} userId 
     * @param {EditUserInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersUserIdPatch(userId: string, body?: EditUserInput, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersUserIdPatch(userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get organization wallets
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWalletsGet: async (organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWalletsGet', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organization_id}/wallets`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the organization wallet balance per network
         * @param {string} organizationId 
         * @param {string} organizationWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet: async (organizationId: string, organizationWalletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet', 'organizationId', organizationId)
            // verify required parameter 'organizationWalletId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet', 'organizationWalletId', organizationWalletId)
            const localVarPath = `/organizations/{organization_id}/wallets/{organization_wallet_id}/balance`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organization_wallet_id"}}`, encodeURIComponent(String(organizationWalletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an organization wallet
         * @param {string} organizationId 
         * @param {string} organizationWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWalletsOrganizationWalletIdGet: async (organizationId: string, organizationWalletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWalletsOrganizationWalletIdGet', 'organizationId', organizationId)
            // verify required parameter 'organizationWalletId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWalletsOrganizationWalletIdGet', 'organizationWalletId', organizationWalletId)
            const localVarPath = `/organizations/{organization_id}/wallets/{organization_wallet_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organization_wallet_id"}}`, encodeURIComponent(String(organizationWalletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get platform wallets
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformWalletsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/platform-wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get organization wallets
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdWalletsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgWalletsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdWalletsGet(organizationId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the organization wallet balance per network
         * @param {string} organizationId 
         * @param {string} organizationWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet(organizationId: string, organizationWalletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkBalancePaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet(organizationId, organizationWalletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an organization wallet
         * @param {string} organizationId 
         * @param {string} organizationWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdWalletsOrganizationWalletIdGet(organizationId: string, organizationWalletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdWalletsOrganizationWalletIdGet(organizationId, organizationWalletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get platform wallets
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformWalletsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlatformWalletsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformWalletsGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * 
         * @summary Get organization wallets
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWalletsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<GetOrgWalletsPaginatedOutput> {
            return localVarFp.organizationsOrganizationIdWalletsGet(organizationId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the organization wallet balance per network
         * @param {string} organizationId 
         * @param {string} organizationWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet(organizationId: string, organizationWalletId: string, options?: any): AxiosPromise<NetworkBalancePaginatedOutput> {
            return localVarFp.organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet(organizationId, organizationWalletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an organization wallet
         * @param {string} organizationId 
         * @param {string} organizationWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWalletsOrganizationWalletIdGet(organizationId: string, organizationWalletId: string, options?: any): AxiosPromise<OrganizationWallet> {
            return localVarFp.organizationsOrganizationIdWalletsOrganizationWalletIdGet(organizationId, organizationWalletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get platform wallets
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformWalletsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<GetPlatformWalletsPaginatedOutput> {
            return localVarFp.platformWalletsGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * 
     * @summary Get organization wallets
     * @param {string} organizationId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public organizationsOrganizationIdWalletsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).organizationsOrganizationIdWalletsGet(organizationId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the organization wallet balance per network
     * @param {string} organizationId 
     * @param {string} organizationWalletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet(organizationId: string, organizationWalletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).organizationsOrganizationIdWalletsOrganizationWalletIdBalanceGet(organizationId, organizationWalletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an organization wallet
     * @param {string} organizationId 
     * @param {string} organizationWalletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public organizationsOrganizationIdWalletsOrganizationWalletIdGet(organizationId: string, organizationWalletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).organizationsOrganizationIdWalletsOrganizationWalletIdGet(organizationId, organizationWalletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get platform wallets
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public platformWalletsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).platformWalletsGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhookApi - axios parameter creator
 * @export
 */
export const WebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all webhooks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create webhook
         * @param {WebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksPost: async (body?: WebhookInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a sample webhook to the given url
         * @param {TestWebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksTestPost: async (body?: TestWebhookInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksWebhookIdDelete: async (webhookId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('webhooksWebhookIdDelete', 'webhookId', webhookId)
            const localVarPath = `/webhooks/{webhook_id}`
                .replace(`{${"webhook_id"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit webhook
         * @param {string} webhookId 
         * @param {EditWebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksWebhookIdPatch: async (webhookId: string, body?: EditWebhookInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('webhooksWebhookIdPatch', 'webhookId', webhookId)
            const localVarPath = `/webhooks/{webhook_id}`
                .replace(`{${"webhook_id"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            await setApiKeyToObject(localVarHeaderParameter, "Phosphor-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookApi - functional programming interface
 * @export
 */
export const WebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all webhooks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhooksPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create webhook
         * @param {WebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksPost(body?: WebhookInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a sample webhook to the given url
         * @param {TestWebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksTestPost(body?: TestWebhookInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestWebhookOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksTestPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksWebhookIdDelete(webhookId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksWebhookIdDelete(webhookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit webhook
         * @param {string} webhookId 
         * @param {EditWebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksWebhookIdPatch(webhookId: string, body?: EditWebhookInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksWebhookIdPatch(webhookId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhookApi - factory interface
 * @export
 */
export const WebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all webhooks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<WebhooksPaginatedOutput> {
            return localVarFp.webhooksGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create webhook
         * @param {WebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksPost(body?: WebhookInput, options?: any): AxiosPromise<Webhook> {
            return localVarFp.webhooksPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a sample webhook to the given url
         * @param {TestWebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksTestPost(body?: TestWebhookInput, options?: any): AxiosPromise<TestWebhookOutput> {
            return localVarFp.webhooksTestPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksWebhookIdDelete(webhookId: string, options?: any): AxiosPromise<void> {
            return localVarFp.webhooksWebhookIdDelete(webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit webhook
         * @param {string} webhookId 
         * @param {EditWebhookInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksWebhookIdPatch(webhookId: string, body?: EditWebhookInput, options?: any): AxiosPromise<Webhook> {
            return localVarFp.webhooksWebhookIdPatch(webhookId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
export class WebhookApi extends BaseAPI {
    /**
     * 
     * @summary Get all webhooks
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public webhooksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return WebhookApiFp(this.configuration).webhooksGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create webhook
     * @param {WebhookInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public webhooksPost(body?: WebhookInput, options?: AxiosRequestConfig) {
        return WebhookApiFp(this.configuration).webhooksPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a sample webhook to the given url
     * @param {TestWebhookInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public webhooksTestPost(body?: TestWebhookInput, options?: AxiosRequestConfig) {
        return WebhookApiFp(this.configuration).webhooksTestPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete webhook
     * @param {string} webhookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public webhooksWebhookIdDelete(webhookId: string, options?: AxiosRequestConfig) {
        return WebhookApiFp(this.configuration).webhooksWebhookIdDelete(webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit webhook
     * @param {string} webhookId 
     * @param {EditWebhookInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public webhooksWebhookIdPatch(webhookId: string, body?: EditWebhookInput, options?: AxiosRequestConfig) {
        return WebhookApiFp(this.configuration).webhooksWebhookIdPatch(webhookId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


