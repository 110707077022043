import { MAIN_ROUTES, AppRoute } from '@/routes';
import { find } from 'lodash';
import { generatePath } from 'react-router-dom';

/**
 * Gets the exact route path for a context that can be related to a collection or an item.
 * Depending on the parent association, a different route path will be returned.
 */
export function getColOrItemHierarchyRoutePath(ctxt: {
  id: string;
  idPropName?: string;
  detailsRouteName: string;
  collectionId?: string;
  collectionDetailsRouteName?: string;
  itemId?: string;
  itemDetailsRouteName?: string;
}): string | undefined {
  if (!ctxt?.id || !ctxt?.idPropName || !ctxt.detailsRouteName) return undefined;

  const {
    collectionId,
    itemId,
    detailsRouteName,
    collectionDetailsRouteName,
    itemDetailsRouteName,
    id,
    idPropName,
  } = ctxt;

  let routePath = undefined;

  console.log('>>>>>', collectionId, itemId);

  if (collectionId && itemId) {
    const detailsRoute = find(
      MAIN_ROUTES,
      route => route.name === itemDetailsRouteName
    ) as AppRoute;
    routePath = generatePath(detailsRoute.path as string, {
      [idPropName]: id,
      collectionId,
      itemId,
    });
  } else if (collectionId) {
    const detailsRoute = find(
      MAIN_ROUTES,
      route => route.name === collectionDetailsRouteName
    ) as AppRoute;
    routePath = generatePath(detailsRoute.path as string, {
      [idPropName]: id,
      collectionId,
    });
  } else {
    const detailsRoute = find(MAIN_ROUTES, route => route.name === detailsRouteName) as AppRoute;
    routePath = generatePath(detailsRoute.path as string, {
      [idPropName]: id,
      collectionId,
      itemId,
    });
  }

  return routePath;
}
