import {
  CollectionOutputDeploymentTokenTypeEnum,
  CollectionsPaginatedOutputResultsInner,
  CollectionsPaginatedOutput,
  CollectionOutput,
  CreateCollectionInput,
  CreateCollectionInputdeploymentRequestTypeEnum,
  CollectionOutputDeploymentTokenIdAssignmentStrategyEnum,
  CollectionOutputRevealStrategyEnum,
} from '@/api-client';
import { useQuery, UseQueryResult, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { adminApi, HOT_REFETCH_INTERVAL_MILLISECONDS, publicApi } from '../api';
import {
  COLLECTIONS_QUERY_KEY,
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  DEFAULT_CONTRACT_VARIANT,
} from '../constants';
import { getApiErrorMessage } from '../helpers';
import { testModeState } from '@/utils/states/testModeState';


export const useCollection = (collectionId?: string) => {
  return useQuery({
    queryKey: [COLLECTIONS_QUERY_KEY, collectionId],
    queryFn: () =>
      adminApi.collection.collectionsCollectionIdGet(collectionId as string).then(res => res.data),
    enabled: Boolean(collectionId),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });
};

export interface CollectionIdInput {
  collectionId: string;
}

export const useCollections = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: {
    id?: string;
    name?: string;
    contractAddress?: string;
    networkId?: number;
    tokenType?: CollectionOutputDeploymentTokenTypeEnum;
  }
) => {
  const [output, setOutput] = useState<any>({} as any);
  const { active: testnet } = useSnapshot(testModeState);
  
  const getCollections = useQuery({
    queryKey: [
      COLLECTIONS_QUERY_KEY,
      pageSize,
      cursor,
      sort?.fieldName,
      sort?.direction,
      filter?.name,
      filter?.contractAddress,
      filter?.networkId,
      filter?.tokenType,
      filter?.id,
      testnet,
    ],
    queryFn: () =>
      adminApi.collection
        .collectionsGet(
          sort?.direction ?? API_SORT_DIRECTION_ASC,
          cursor,
          pageSize,
          filter?.name,
          filter?.contractAddress,
          filter?.tokenType,
          filter?.networkId,
          sort?.fieldName?.toUpperCase() as any,
          testnet,
        )
        .then(res => res.data),
    // Don't fire if we're filtering by id.
    enabled: !filter?.id,
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  const getCollection: UseQueryResult<CollectionOutput, unknown> = useQuery({
    queryKey: [COLLECTIONS_QUERY_KEY, filter?.id],
    queryFn: () =>
      adminApi.collection.collectionsCollectionIdGet(filter?.id as string).then(res => res.data),
    enabled: Boolean(filter?.id),
  });

  useEffect(() => {
    if (getCollection.data) {
      setOutput({
        ...getCollection,
        data: {
          cursor: null,
          results: [getCollection.data as CollectionsPaginatedOutputResultsInner],
          total_results: getCollection.data.id ? 1 : 0,
          has_more: false,
        },
      });
    } else if (getCollections.data) {
      setOutput(getCollections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCollection?.data, getCollections?.data]);

  return output as UseQueryResult<CollectionsPaginatedOutput, unknown>;
};

export const useCreateCollection = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: CreateCollectionInput) => adminApi.collection.collectionsPost(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COLLECTIONS_QUERY_KEY] });
      enqueueSnackbar('Successfully created collection. Contract deployment is queued.', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating collection', { variant: 'error' });
    },
  });

  return mutation;
};

export const useCollectionStats = (collection_id?: string) => {
  return useQuery({
    queryKey: [COLLECTIONS_QUERY_KEY, collection_id, 'stats'],
    queryFn: () =>
      publicApi.collection
        .collectionsCollectionIdStatsGet(collection_id as string)
        .then(res => res.data),
    enabled: Boolean(collection_id),
  });
};

export const useDisableEditableMetadata = (
  includeSnackbarFeedback = true,
  invalidateQueries = true
) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (input: CollectionIdInput) =>
      adminApi.collection.collectionsCollectionIdPatch(input.collectionId, {
        editable_metadata: false,
      }),
    onSuccess: () => {
      if (invalidateQueries) {
        queryClient.invalidateQueries({ queryKey: [COLLECTIONS_QUERY_KEY] });
      }

      // Show feedback if necessary.
      if (includeSnackbarFeedback) {
        enqueueSnackbar('The collection has been successfully updated.', { variant: 'success' });
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error updating editable metadata setting';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};

export interface EditCollectionPreviewMetadataInput {
  preview_metadata: object;
}

export const useEditCollectionPreviewMetadata = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: ({
      collectionId,
      body,
    }: {
      collectionId: string;
      body: EditCollectionPreviewMetadataInput;
    }) => adminApi.collection.collectionsCollectionIdPatch(collectionId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COLLECTIONS_QUERY_KEY] });
      enqueueSnackbar('Successfully updated collection preview metadata.', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error updating collection metadata', { variant: 'error' });
    },
  });

  return mutation;
};

export const useTriggerReveal = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (input: CollectionIdInput) =>
      adminApi.collection.collectionsCollectionIdPatch(input.collectionId, {
        reveal_hidden: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COLLECTIONS_QUERY_KEY] });
      enqueueSnackbar('Successfully revealed actual artwork and metadata for minted items.', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error trigger the reveal', { variant: 'error' });
    },
  });

  return mutation;
};

export const initialCollectionFormState = {
  name: '',
  networkId: '',
  contractType: DEFAULT_CONTRACT_VARIANT.FLEXIBLE_ERC721,
  deploymentType: CreateCollectionInputdeploymentRequestTypeEnum.Platform,
  contractSymbol: '',
  byocABI: '',
  byocByteCode: '',
  byocConstructorArgs: '',
  tokenIdAssignmentStrategy: CollectionOutputDeploymentTokenIdAssignmentStrategyEnum.Automatic,
  revealStrategy: CollectionOutputRevealStrategyEnum.Instant,
  previewMetadata: {},
  editableMetadata: false,
};

export type CollectionSessionState = {
  name?: any;
  networkId?: any;
  contractType?: any;
  deploymentType?: any;
  contractSymbol?: any;
  byocABI?: any;
  byocByteCode?: any;
  byocConstructorArgs?: any;
  tokenIdAssignmentStrategy?: any;
  revealStrategy?: any;
  previewMetadata?: any;
  editableMetadata?: any;
};

// TODO: Candidate to replace with Valtio.
export const useCollectionSessionState = () => {
  const FormStorageKey = 'CNFT_Create_Collection_State';

  const setValues = (values: object) => {
    if (!values) return;
    window.localStorage.setItem(FormStorageKey, JSON.stringify(values));
  };

  // set single field session value directly
  const setFieldValue = (field: string, value: any) => {
    if (!field && !value) return;
    const useValues = getState();
    if (!useValues) return;
    useValues[field] = value;
    window.localStorage.setItem(FormStorageKey, JSON.stringify(useValues));
    return;
  };

  const clearState = () => {
    return window.localStorage.removeItem(FormStorageKey);
  };

  const getState = (includeInit = false) => {
    let data;
    try {
      data = JSON.parse(window.localStorage.getItem(FormStorageKey) ?? '');
    } catch {
      /* empty */
    }
    return includeInit && !data ? initialCollectionFormState : data;
  };

  const data = getState(true);
  const hasPreviewMetadata: boolean = data?.previewMetadata && !!data.previewMetadata?.title;

  return {
    hasPreviewMetadata,
    data,
    clearState,
    getState,
    setFieldValue,
    setValues,
  };
};

export const useCollectionPinBaseUri = (
  includeSnackbarFeedback = true,
  invalidateQueries = true
) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: ({ collectionId }: { collectionId: string }) =>
      adminApi.collection.collectionsCollectionIdPinPatch(collectionId),
    onSuccess: () => {
      if (invalidateQueries) {
        queryClient.invalidateQueries({ queryKey: [COLLECTIONS_QUERY_KEY] });
      }

      // Show feedback if necessary.
      if (includeSnackbarFeedback) {
        enqueueSnackbar('Collection has been successfully queued for IPFS storage.', {
          variant: 'success',
        });
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error updating base URI to IPFS.';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};
