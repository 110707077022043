import { useFlags } from 'launchdarkly-react-client-sdk';

const useFeatureFlags = () => {
  const flags = useFlags();

  const isFeatureEnabled = (name: string, defaultValue = false) => {
    return flags[name] ?? defaultValue;
  };

  return {
    // Don't use admin-web prefix, use cnft-platform-ui.
    // We want to move away from the dev-ui name as it is too narrow in scope.
    exampleFeature: isFeatureEnabled('cnft-platform-ui.example-feature'),
    pinBaseUriToIpfs: isFeatureEnabled('cnft-platform-ui.collection-pin-base-uri-to-ipfs'),
    marketingCampaigns: isFeatureEnabled('api.v3.marketing-campaigns'),
  };
};

export default useFeatureFlags;
