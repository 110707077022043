import { CollectionOutputDeploymentTokenTypeEnum, EmailIntegrationInputProviderEnum, ListingOutputStatusEnum } from '@/api-client';

export const ME_QUERY_KEY = 'me';
export const ORG_QUERY_KEY = 'organization';
export const ORG_PAYOUT_CONFIG_KEY = 'organization-payout-config';

export const STRIPE_PAYMENT_ACCOUNTS_QUERY_KEY = 'stripe-payment-accounts';
export const STRIPE_INTEGRATION_QUERY_KEY = 'stripe-integration';

export const COINBASE_COMMERCE_INTEGRATION_QUERY_KEY = 'coinbase-commerce-integration';

export const COLLECTIONS_QUERY_KEY = 'collections';
export const COLLECTION_CONTRACT_DEPLOYING = 'DEPLOYING';
export const COLLECTION_CONTRACT_DEPLOYED = 'DEPLOYED';

export const ITEMS_QUERY_KEY = 'items';

export const LISTINGS_QUERY_KEY = 'listings';

export const PURCHASE_INTENTS_QUERY_KEY = 'purchase-intents';

export const PUBLIC_CAPABILITIES_QUERY_KEY = 'public-capabilities';

export const ITEM_TYPES_QUERY_KEY = 'item-types';
export const ITEM_TYPE_META_SCHEMA_QUERY_KEY = 'item-type-meta-schema';

export const USERS_QUERY_KEY = 'users';
export const USER_QUERY_KEY = 'user';

export const INVITES_QUERY_KEY = 'invites';
export const INVITE_QUERY_KEY = 'invite';

export const ORG_BALANCE_QUERY_KEY = 'org-balance';

export const TRANSACTIONS_QUERY_KEY = 'transactions';

export const ROLES_QUERY_KEY = 'roles';

export const WEBHOOKS_QUERY_KEY = 'webhooks';

export const API_KEYS_QUERY_KEY = 'api-keys';

export const ORG_WALLETS_QUERY_KEY = 'wallets';
export const ORG_WALLET_BALANCE_QUERY_KEY = 'wallet-balance';

export const EMAIL_CLAIMS_QUERY_KEY = 'email-claims';

export const EMAIL_INTEGRATION_QUERY_KEY = 'email-integration'

export const EMAIL_TEMPLATE_TYPES_QUERY_KEY = 'email-template-types';
export const EMAIL_TEMPLATES_QUERY_KEY = 'email-templates';
export const EMAIL_TEMPLATE_VARIABLES_QUERY_KEY = 'email-template-variables';

export const MARKETING_CAMPAIGNS_QUERY_KEY = 'marketing-campaigns';
export const PUBLIC_NETWORKS_QUERY_KEY = 'public-networks';
export const NETWORK_IDS = [12345, 1, 5, 137, 59144, 59140, 11297108109, 11155111];

export const API_SORT_DIRECTION_ASC = 'ASC';
export const API_SORT_DIRECTION_DESC = 'DESC';

export const TOKEN_TYPE_721_MAX_SUPPLY = '1';

export const UUID_REGEX =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
export const NO_DECIMAL_NUMBER_REGEX = /^[0-9]+$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const HTTP_URL_REGEX = /^http(s)?:\/\//;
export const META_BACKGROUND_COLOR_REGEX = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/; // HEX color without # prefix

export enum ROUTE_NAME {
  HOME = 'Home',

  SETTINGS = 'Settings',

  USERS = "Users",
  USER_DETAILS = "User Details",
  USER_INVITED_DETAILS = "User Invited",

  USER_PROFILE_DETAILS = 'User Profile',

  COLLECTIONS_OVERVIEW = 'Collections',
  COLLECTIONS_CREATE = 'Collections - Create',
  COLLECTION_DETAILS = 'Collection Details',
  COLLECTIONS_REVEAL_STRATEGY = 'Reveal Strategy',
  COLLECTIONS_PREVIEW_CREATE = 'Reveal Preview Metadata',
  COLLECTIONS_PREVIEW_EDIT = 'Edit Preview Metadata',

  ITEM_DETAILS = 'Item Details',
  CREATE_ITEM = 'Create Item',
  EDIT_ITEM = 'Edit Item',

  EMAIL_CLAIM_DETAILS = 'Email Claim Details',
  COLLECTION_EMAIL_CLAIM_DETAILS = 'Collection Email Claim Details',
  ITEM_EMAIL_CLAIM_DETAILS = 'Item Email Claim Details',

  TRANSACTIONS_OVERVIEW = 'Transactions',
  TRANSACTION_DETAILS = 'Transaction Details',
  COLLECTION_TRANSACTION_DETAILS = 'Collection Transaction Details',
  ITEM_TRANSACTION_DETAILS = 'Item Transaction Details',
  LISTINGS_OVERVIEW = 'Listings',
  LISTING_DETAILS = 'Listing Details',
  COLLECTION_LISTING_DETAILS = 'Collection Listing Details',
  ITEM_LISTING_DETAILS = 'Item Listing Details',
  CREATE_COLLECTION_LISTING = 'Create Collection Listing',

  PURCHASE_INTENT_DETAILS = 'Purchase Intent Details',
  COLLECTION_PURCHASE_INTENT_DETAILS = 'Collection Purchase Intent Details',
  ITEM_PURCHASE_INTENT_DETAILS = 'Item Purchase Intent Details',

  WALLETS_OVERVIEW = 'Wallets',

  EMAIL_CAMPAIGNS = 'Email Campaigns',
  EMAIL_CAMPAIGN_DETAILS = 'Email Campaign Details',
  EMAIL_CAMPAIGN_CREATE = 'Email Campaign Create',
  EMAIL_CAMPAIGN_TEMPLATE = 'Email Campaign Template Details',

  ITEM_TYPES_OVERVIEW = 'Item Types',

  BILLING_OVERVIEW = 'Billing & Balances',
  BILLING_TRANSACTION_DETAILS = 'Charges Transaction Details',
}

export enum EMAIL_TEMPLATES_TYPE {
  PlatformDefault = 'PLATFORM_DEFAULT',
  OrganizationCustom = 'ORGANIZATION_CUSTOM',
}

export enum ENTITY_PAGE_VISIT_TYPE {
  COLLECTION = 'Collection',
  ITEM = 'Item',
  TRANSACTION = 'Transaction',
  WALLET = 'Wallet',
  SETTINGS = 'Settings',
  EMAIL_CLAIM = 'Email Claim',
  LISTING = 'Listing',
  PURCHASE_INTENT = 'Purchase Intent',
  // Fill in more when needed.
}

export enum CNFT_DOCS_PAGE_TYPE {
  INTRODUCTION = 'Introduction',
  PLATFORM_FEATURES = 'Features',
  API_REFERENCE = 'API Reference',
  SUPPORT = 'SUPPORT',
}

export const CNFT_DOCS_PAGE_LINKS = [
  { type: CNFT_DOCS_PAGE_TYPE.INTRODUCTION, link: 'https://docs.consensys-nft.com/introduction' },
  {
    type: CNFT_DOCS_PAGE_TYPE.PLATFORM_FEATURES,
    link: 'https://docs.consensys-nft.com/category/platform-features',
  },
  {
    type: CNFT_DOCS_PAGE_TYPE.API_REFERENCE,
    link: 'https://docs.consensys-nft.com/latest-admin-api',
  },
  {
    type: CNFT_DOCS_PAGE_TYPE.SUPPORT,
    link: 'https://docs.consensys-nft.com/support',
  },
];

export const OPENSEA_METADATA_STANDARD_INFO_URL = 'https://docs.opensea.io/docs/metadata-standards';

export const SHORT_UUID_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE = 5;
export const SHORT_ADDRESS_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE = 4;

export const API_KEY_HEADER = 'CNFT-API-KEY';

export enum DEFAULT_CONTRACT_VARIANT {
  STANDARD_ERC721 = 'StandardERC721',
  STANDARD_ERC1155 = 'StandardERC1155',
  FLEXIBLE_ERC721 = 'FlexibleERC721',
  FLEXIBLE_ERC1155 = 'FlexibleERC1155',
  SIGNATURE_ERC721 = 'SignatureERC721',
  FROZEN_ERC721 = 'FrozenERC721',
  FROZEN_ERC1155 = 'FrozenERC1155',
}

export const CONTRACT_VARIANT_TOKEN_TYPE_MAP = {
  [DEFAULT_CONTRACT_VARIANT.STANDARD_ERC721]: CollectionOutputDeploymentTokenTypeEnum.Erc721,
  [DEFAULT_CONTRACT_VARIANT.STANDARD_ERC1155]: CollectionOutputDeploymentTokenTypeEnum.Erc1155,
  [DEFAULT_CONTRACT_VARIANT.FLEXIBLE_ERC721]: CollectionOutputDeploymentTokenTypeEnum.Erc721,
  [DEFAULT_CONTRACT_VARIANT.FLEXIBLE_ERC1155]: CollectionOutputDeploymentTokenTypeEnum.Erc1155,
  [DEFAULT_CONTRACT_VARIANT.SIGNATURE_ERC721]: CollectionOutputDeploymentTokenTypeEnum.Erc721,
  [DEFAULT_CONTRACT_VARIANT.FROZEN_ERC721]: CollectionOutputDeploymentTokenTypeEnum.Erc721,
  [DEFAULT_CONTRACT_VARIANT.FROZEN_ERC1155]: CollectionOutputDeploymentTokenTypeEnum.Erc1155,
};

export const DEFAULT_PLATFORM_CONTRACT_VARIANTS = Object.values(DEFAULT_CONTRACT_VARIANT);

export const DEPRECATED_CONTRACT_VARIANTS = [
  DEFAULT_CONTRACT_VARIANT.STANDARD_ERC721,
  DEFAULT_CONTRACT_VARIANT.STANDARD_ERC1155,
];

export const EDITABLE_METADATA_CONTRACT_VARIANTS = [
  DEFAULT_CONTRACT_VARIANT.FLEXIBLE_ERC721,
  DEFAULT_CONTRACT_VARIANT.FLEXIBLE_ERC1155,
  DEFAULT_CONTRACT_VARIANT.SIGNATURE_ERC721,
];

export const UNLIMITED_MAX_SUPPLY = 'UNLIMITED';

export const UPLOAD_FILE_MAX_SIZE_MB = 100;

export const CSV_MIME_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/csv',
  'text/x-csv',
  'application/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
];
export const CSV_MIME_TYPES_ACCEPT = CSV_MIME_TYPES.reduce(
  (acc: any, curr: any) => ((acc[curr] = ['.csv']), acc),
  {}
);

export const IMAGE_MIME_TYPES = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
];
export const IMAGE_MIME_TYPES_ACCEPT = IMAGE_MIME_TYPES.reduce(
  (acc: any, curr: any) => ((acc[curr] = ['.jpeg']), acc),
  {}
);

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/webm',
  'video/x-m4v',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/mpeg',
  'video/x-msvideo',
];
export const VIDEO_MIME_TYPES_ACCEPT = VIDEO_MIME_TYPES.reduce(
  (acc: any, curr: any) => ((acc[curr] = ['.mp4']), acc),
  {}
);

export const AUDIO_MIME_TYPES = ['audio/mpeg', 'audio/mp4a-latm', 'audio/ogg'];
export const AUDIO_MIME_TYPES_ACCEPT = AUDIO_MIME_TYPES.reduce(
  (acc: any, curr: any) => ((acc[curr] = ['.mp4']), acc),
  {}
);

export const TEXT_PLAIN_MIME_TYPES = ['text/plain'];
export const TEXT_PLAIN_MIME_TYPES_ACCEPT = TEXT_PLAIN_MIME_TYPES.reduce(
  (acc: any, curr: any) => ((acc[curr] = ['.txt']), acc),
  {}
);

export const JSON_MIME_TYPES = ['application/json', 'text/json'];
export const JSON_MIME_TYPES_ACCEPT = JSON_MIME_TYPES.reduce(
  (acc: any, curr: any) => ((acc[curr] = ['.csv']), acc),
  {}
);

export enum COMMON_ITEM_ATTRIBUTE {
  TITLE = 'title',
  DESCRIPTION = 'description',
  IMAGE_URL = 'image_url',
}

export const COMMON_ITEM_ATTRIBUTES: string[] = Object.values(COMMON_ITEM_ATTRIBUTE);

export enum META_ATTRIBUTE {
  META_ANIMATION_URL = 'meta_animation_url',
  META_EXTERNAL_URL = 'meta_external_url',
  META_METADATA_STYLE = 'meta_metadata_style',
  META_BACKGROUND_COLOR = 'meta_background_color',
  META_YOUTUBE_URL = 'meta_youtube_url',
}

export const META_ATTRIBUTES: string[] = Object.values(META_ATTRIBUTE);

export const COLLECTION_PREVIEW_EXCLUDE_ATTRIBUTES: string[] = [
  'id',
  'title',
  'description',
  'media',
];

export enum META_ATTRIBUTE_OUTPUT_STYLE {
  OPENSEA = 'OPENSEA',
  ENJIN = 'ENJIN',
}

export const DUMMY_SELECT_OPTION_NAME = '<< NONE >>';

export enum STRIPE_COMMAND {
  RETRY = 'RETRY',
}

export enum LISTING_POLICY_TYPE {
  FCFS = 'FCFS',
  ALLOW_LIST = 'ALLOW_LIST',
}

export const LISTING_STATUS_COLOR_MAP = {
  [ListingOutputStatusEnum.Complete]: 'colors.success',
  [ListingOutputStatusEnum.Active]: 'colors.secondary',
  [ListingOutputStatusEnum.Cancelled]: 'colors.warning',
  [ListingOutputStatusEnum.PendingTx]: 'colors.secondary',
};

export enum LISTING_BUYER_TYPE {
  EMAIL = 'Email Address',
  ETH_ADDRESS = 'Ethereum Address',
}

export const EARLIEST_JS_DATE = new Date(-8640000000000000);

export enum LISTING_SCOPE_TYPE {
  COLLECTION = 'Collection',
  ITEM = 'Item',
}

export const DELAYED_REVEAL_PREVIEW_PLACEHOLDER = {
  title: 'Delayed Reveal',
  description: 'Sample placeholder for an upcoming collection drop! Empowered by Phosphor.',
  placeholder: 'Yes',
};

export const IPFS_PROTOCOL = 'ipfs:';

export enum EMAIL_PROVIDERS {
  MAILGUN = 'Mailgun',
  AMAZON_SES = 'Amazon SES',
  CUSTOM_SMTP = 'SMTP Provider',
}

export const EMAIL_PROVIDER_MAP = {
  [EMAIL_PROVIDERS.AMAZON_SES]: EmailIntegrationInputProviderEnum.AmazonSes,
  [EMAIL_PROVIDERS.MAILGUN]: EmailIntegrationInputProviderEnum.Mailgun,
  [EMAIL_PROVIDERS.CUSTOM_SMTP]: EmailIntegrationInputProviderEnum.CustomSmtp,
}

export const MAILGUN_SUPPORTED_REGIONS = ['US', 'EU'];

export const AMAZON_SES_SUPPORTED_REGIONS = [
  'us-east-2', 'us-east-1', 'us-west-1', 'us-west-2', 
  'af-south-1', 'ap-east-1', 'ap-southeast-3', 'ap-south-1',
  'ap-northeast-3', 'ap-northeast-2', 'ap-southeast-1', 'ap-southeast-2', 
  'ap-northeast-1', 'ca-central-1', 'eu-central-1', 'eu-west-1', 
  'eu-west-2', 'eu-south-1', 'eu-west-3', 'eu-north-1', 'me-south-1', 
  'sa-east-1', 'us-gov-east-1', 'us-gov-west-1',
]

export const UNVERIFIED_EMAIL_ERROR = {
  status: 451,
  code : 'UNVERIFIED_EMAIL',
}
